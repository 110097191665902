import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function Complete() {
  const navigate = useNavigate();

  return (
    <>
      <Box textAlign="center" my={13} px={4}>
        <Typography variant="h5">パスワード再設定完了</Typography>
        <Typography color="secondary" mt={3} mb={10}>
          パスワード再設定が完了しました。
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate('/login')}
        >
          ログイン画面へ
        </Button>
      </Box>
    </>
  );
}
