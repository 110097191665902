import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { NumericTextField } from '@app/components/Shared/Inputs/NumericTextField';
import { ProductPriceStatus, ProductSalesStatus } from '@app/types/catalog';
import { RANGE_TYPE_OPTIONS } from '@app/utils/constants';

const PRICE_STATUS_OPTIONS = [
  { label: '未定', value: ProductPriceStatus.PENDING },
  { label: '予定・確定', value: ProductPriceStatus.PLAN },
];

export function PriceFormSection(): ReactElement {
  const { control, setValue, clearErrors } = useFormContext();
  const priceStatus = useWatch({ control, name: 'priceStatus' });
  const salesStatus = useWatch({ control, name: 'salesStatus' });
  const salesHouseCount = useWatch({ control, name: 'salesHouseCount' });
  const [isPriceStatusPending, setPriceStatusPending] = useState(false);

  // 概要の販売状態選択が分譲であるか
  const isSalesStatusCond = useMemo(() => {
    return (
      salesStatus === ProductSalesStatus.NEW_BUILDING_CONDO ||
      salesStatus === ProductSalesStatus.USED_CONDO
    );
  }, [salesStatus]);
  useEffect(() => {
    if (isSalesStatusCond && priceStatus) {
      setValue('priceStatus', ProductPriceStatus.PLAN);
    }
  }, [isSalesStatusCond, priceStatus, setValue]);

  // 建物の販売戸数を監視
  useEffect(() => {
    if (Number(salesHouseCount) < 10 || !isSalesStatusCond) {
      clearErrors('mostPriceRanges.0');
    }
  }, [salesHouseCount, isSalesStatusCond, clearErrors]);

  // 価格の状態を監視
  useEffect(() => {
    if (priceStatus === ProductPriceStatus.PENDING) {
      clearErrors('minPrice');
      setValue('priceRangeType', '');
      setValue('minPrice', '');
      setValue('maxPrice', '');
      setPriceStatusPending(true);
    } else {
      setPriceStatusPending(false);
    }
  }, [priceStatus, clearErrors, setValue]);

  return (
    <Stack spacing={3}>
      <Typography variant="h5">価格</Typography>
      <Stack>
        <FormLabel focused={false} className="required-label">
          価格
        </FormLabel>
        <Controller
          name="priceStatus"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <RadioGroup {...field} row>
                {PRICE_STATUS_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={
                      <Radio
                        disabled={
                          option.value === ProductPriceStatus.PENDING &&
                          isSalesStatusCond
                        }
                      />
                    }
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </div>
          )}
        />
        <Stack direction="row" spacing={1.5}>
          <Stack direction="row" spacing={0.5}>
            <Controller
              name="minPrice"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NumericTextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  disabled={isPriceStatusPending}
                  sx={{ width: 160 }}
                />
              )}
            />
            <Typography pt={1}>万円</Typography>
          </Stack>
          <div>
            <Controller
              name="priceRangeType"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  error={!!error}
                  disabled={isPriceStatusPending}
                  displayEmpty
                  sx={{ width: 80 }}
                >
                  {RANGE_TYPE_OPTIONS.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </div>
          <Stack direction="row" spacing={0.5}>
            <Controller
              name="maxPrice"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NumericTextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  disabled={isPriceStatusPending}
                  sx={{ width: 160 }}
                />
              )}
            />
            <Typography pt={1}>万円</Typography>
          </Stack>
        </Stack>
      </Stack>
      <FormControl fullWidth>
        <FormLabel focused={false}>価格補足</FormLabel>
        <Controller
          name="priceAdditional"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>
          例：モデルハウス価格6000万円、2100年12月31日より契約時までモデルハウスとして使用
        </FormHelperText>
      </FormControl>
      <Stack>
        <FormLabel focused={false} className="condition-required-label">
          最多価格帯
        </FormLabel>
        <Stack direction="row" spacing={1}>
          {Array.from({ length: 4 }, (h, index) => (
            <Stack key={index} direction="row" spacing={0.5}>
              <Controller
                name={`mostPriceRanges.${index}`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <NumericTextField
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    sx={{ width: 160 }}
                  />
                )}
              />
              <Typography noWrap pt={1}>
                万円台
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Controller
          name="mostPriceRangeAdditional"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              placeholder="補足事項があれば入力してください"
              sx={{ mt: 1 }}
            />
          )}
        />
        <FormHelperText>
          ※販売状態が、新規分譲、分譲中かつ販売戸数が１０以上の場合、必須
          <br />
          ※最多価格帯が、５つ以上ある場合、５つめ以降を入力して下さい&nbsp;&nbsp;例：6700万台、6900万台
        </FormHelperText>
      </Stack>
      <FormControl fullWidth>
        <FormLabel focused={false} className="condition-required-label">
          最多価格帯戸数
        </FormLabel>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Controller
            name="mostPriceRangeCount"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumericTextField
                {...field}
                error={!!error}
                helperText={error?.message}
                sx={{ width: 160 }}
              />
            )}
          />
          <Typography>戸</Typography>
        </Stack>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="condition-required-label">
          施設費用・償却費
        </FormLabel>
        <Controller
          name="facilityCost"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>例：ホームセキュリティ利用料：1250円/月</FormHelperText>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="condition-required-label">
          その他諸費用
        </FormLabel>
        <Controller
          name="otherCost"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>例：町内会費：500円/月</FormHelperText>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false}>備考</FormLabel>
        <Controller
          name="priceNote"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
      </FormControl>
    </Stack>
  );
}
