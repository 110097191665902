import { Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  GridColDef,
  GridEventListener,
  GridPaginationModel,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { getMessagesByUserId } from '@app/adapter/notification-service';
import { ListTable, paginationModel } from '@app/components/Shared/ListTable';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { SimpleDialog } from '@app/components/Shared/SimpleDialog';
import { loggedInUserState } from '@app/domain/app';
import { Message, MessageStatuses } from '@app/types/notification';
import { convertNewLineToBr } from '@app/utils/components';
import { isError } from '@app/utils/error';
import { timestampFormatJp, restoreSanitizedString } from '@app/utils/format';

export function Home() {
  const [isLoading, setIsLoading] = useState(false);

  // Table data
  const [rows, setRows] = useState<Message[]>([]);
  const [rowTotal, setRowTotal] = useState<number>(0);
  const [currentPaginationModel, setCurrentPaginationModel] =
    useState<GridPaginationModel>(paginationModel);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: '日付',
      renderCell: (params) => (
        <>{timestampFormatJp(params.row.publications.since)}</>
      ),
      sortable: false,
      width: 160,
    },
    {
      field: 'title',
      flex: 1,
      headerName: 'タイトル',
      renderCell: (params) => (
        <Typography variant="body2" className="text-ellipsis">
          {params.row.title}
        </Typography>
      ),
      sortable: false,
    },
  ];

  const [isShowDialog, setIsShowDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Message>();
  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    setSelectedItem(params.row);
    setIsShowDialog(true);
  };

  const fetchNotifications = async () => {
    if (!loggedInUser?.id) return;
    setIsLoading(true);
    try {
      const { page, pageSize } = currentPaginationModel;
      const { data } = await getMessagesByUserId(loggedInUser.id, {
        filter: {
          status: MessageStatuses.PUBLISHED,
        },
        page,
        pageSize,
      });
      setRows(data.value);
      setRowTotal(data.total);
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPaginationModel]);

  return (
    <>
      <PageTitle title="お知らせ" buttonHidden />
      <Card>
        <ListTable
          columns={columns}
          rows={rows}
          rowCount={rowTotal}
          loading={isLoading}
          paginationModel={currentPaginationModel}
          initialState={{
            pagination: { paginationModel },
          }}
          onPaginationModelChange={setCurrentPaginationModel}
          onRowClick={handleRowClick}
        />
      </Card>
      <SimpleDialog
        open={isShowDialog}
        title={selectedItem?.title}
        content={
          <Box>
            {selectedItem?.content
              ? convertNewLineToBr(restoreSanitizedString(selectedItem.content))
              : ''}
          </Box>
        }
        onClickCancel={() => setIsShowDialog(false)}
      />
    </>
  );
}
