import { Box, Paper, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

export interface AlertProps {
  action?: ReactNode;
  color?: 'error' | 'warning' | 'info' | 'success';
  date?: string;
  value: string;
}
export function Alert({
  action,
  color = 'info',
  date,
  value,
}: AlertProps): ReactElement {
  return (
    <Paper
      variant="outlined"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        p: 1.5,
      }}
    >
      <Typography variant="body2" color={color}>
        {value}
      </Typography>
      {!!action && <Box>{action}</Box>}
    </Paper>
  );
}
