import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Common, EmptyKey } from '@app/types/common';
import { OrderStatusKey } from '@app/types/order';

/**
 * Order search form
 */
export interface OrderSearchFormData {
  createdAtSince: string;
  createdAtUntil: string;
  sort: string;
  status: OrderStatusKey | EmptyKey;
}
export const OrderSearchFormDefaultValues: OrderSearchFormData = {
  createdAtSince: '',
  createdAtUntil: '',
  sort: 'createdAt desc',
  status: Common.EMPTY,
};
export const OrderSearchForm = {
  defaultValues: OrderSearchFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      createdAtSince: z.string(),
      createdAtUntil: z.string(),
      sort: z.string(),
      status: z.string(),
    })
  ),
} as const;
