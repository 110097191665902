import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  DataGrid,
  DataGridProps,
  GridPagination,
  GridPaginationModel,
} from '@mui/x-data-grid';
import { ReactElement } from 'react';
export const pageSizeOptions: number[] = [10, 20, 50];
export const paginationModel: GridPaginationModel = {
  page: 0,
  pageSize: pageSizeOptions[0],
};

export function ListTable(props: DataGridProps): ReactElement {
  return (
    <DataGrid
      checkboxSelection={false}
      columnHeaderHeight={40}
      rowHeight={56}
      paginationMode="server"
      pageSizeOptions={pageSizeOptions}
      autoHeight
      disableColumnMenu
      hideFooterSelectedRowCount
      localeText={{
        noResultsOverlayLabel: 'データがありません',
        noRowsLabel: 'データがありません',
      }}
      initialState={{
        pagination: { paginationModel },
      }}
      slotProps={{
        pagination: {
          labelRowsPerPage: '1ページの表示数',
          showFirstButton: true,
          showLastButton: true,
        },
      }}
      slots={{
        pagination: (props) => (
          <GridPagination
            {...props}
            SelectProps={{
              IconComponent: KeyboardArrowDownIcon,
            }}
          />
        ),
      }}
      {...props}
    />
  );
}
