import { UserRole, UserRoleKey } from '@app/types/organization';
import { User, UserSubRole, UserSubRoleKey, UserTypeId } from '@app/types/user';
import { USER_STATUS_LABELS } from '@app/utils/constants';

export function getStatusLabel(isLocked?: boolean): string {
  if (isLocked === undefined) {
    return '-';
  } else if (isLocked) {
    return USER_STATUS_LABELS.LOCKED;
  }
  return USER_STATUS_LABELS.NORMAL;
}

export const ROLE_OPTIONS: { label: string; value: string }[] = [
  { label: getRoleLabel(UserRole.OWNER), value: UserRole.OWNER },
  {
    label:
      getRoleLabel(UserRole.MEMBER) +
      `（${getSubRoleLabel([UserSubRole.EXHIBITION])}）`,
    value: `${UserRole.MEMBER}-${UserSubRole.EXHIBITION}`,
  },
  {
    label:
      getRoleLabel(UserRole.MEMBER) +
      `（${getSubRoleLabel([UserSubRole.PROPERTY])}）`,
    value: `${UserRole.MEMBER}-${UserSubRole.PROPERTY}`,
  },
  {
    label:
      getRoleLabel(UserRole.MEMBER) +
      `（${getSubRoleLabel([UserSubRole.ORDER])}）`,
    value: `${UserRole.MEMBER}-${UserSubRole.ORDER}`,
  },
];

/**
 * 権限ラベルを取得
 */
export function getRoleLabel(
  role?: UserRoleKey,
  subRole?: UserSubRoleKey[] //TODO:削除予定
): string {
  switch (role) {
    case UserRole.ADMIN:
      return '-';
    case UserRole.OWNER:
      return '管理者';
    case UserRole.MEMBER:
      return 'メンバー';
    case UserRole.GUEST:
      return '-';
    default:
      return '-';
  }
}

/**
 * サブ権限ラベルを取得
 */
export function getSubRoleLabel(
  subRoles?: UserSubRoleKey[],
  separate = ','
): string {
  return (
    subRoles
      ?.map((role) => {
        if (role === UserSubRole.EXHIBITION) {
          return '展示場';
        } else if (role === UserSubRole.PROPERTY) {
          return '分譲';
        } else if (role === UserSubRole.ORDER) {
          return '反響';
        }
        return '';
      })
      .filter((r) => r)
      .join(separate) || ''
  );
}

/**
 * ユーザー情報からロール値を取得する
 */
export function getRoleByUser(
  orgId: string,
  user: User
): UserRoleKey | undefined {
  const org = user.organizations?.find((org) => org.id === orgId);
  return org?.users?.find((u) => u.id === user.id)?.role;
}

/**
 * ユーザー情報からロールのラベルを取得する
 */
export function getRoleLabelByUser(orgId: string, user: User): string {
  const role = getRoleByUser(orgId, user);
  return role ? getRoleLabel(role) : '-';
}

/**
 * 対象ユーザーが削除可能か判定する
 */
export function checkUserDeleteOrLock(user: User, selfId?: string): boolean {
  // ログインユーザー本人は不可
  if (selfId && user.id === selfId) {
    return false;
  }
  // 所属する組織で管理者権限を０件にさせない
  const isCheckOwner = !user.organizations?.some((org) => {
    const owners = org.users?.filter((u) => u.role === UserRole.OWNER);
    const role = owners?.find((o) => o.id === user.id)?.role;
    return role === UserRole.OWNER && Number(owners?.length) <= 1;
  });
  if (!isCheckOwner) {
    return false;
  }
  return true;
}

export function getUserFullName(user?: User, separate = ''): string {
  return [
    user?.customFields?.familyName || '',
    user?.customFields?.firstName || '',
  ].join(separate);
}

export function isVendorUser(typeId: string) {
  return typeId === UserTypeId.SUPPLY || typeId === UserTypeId.SUPPLY_SUB;
}

export function isAdminUser(typeId: string) {
  return typeId === UserTypeId.ADMIN;
}
