import { createContext, Dispatch, useContext } from 'react';

import { OrderBadgeState } from '@app/types/order';

type AppContextType = {
  fetchMessagesAndUnreadCount?: () => Promise<void>;
  fetchOrderStatus: () => Promise<void>;
  orderBadge: OrderBadgeState;
  setOrderBadge?: Dispatch<React.SetStateAction<OrderBadgeState>>;
  unreadCount?: number;
};

export const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }
  return context;
};
