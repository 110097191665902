import { ReviewStats } from '@app/types/review';
import { User } from '@app/types/user';

export interface Organization {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  auditStatus: OrganizationAuditStatusKey;
  branchName?: string;
  certificateImageUri?: string;
  chart?: boolean;
  children?: Organization[];
  childrenCount: number;
  createdAt: string;
  customFields?: OrganizationCustomFields;
  description?: string;
  electronicChart?: boolean;
  electronicChartMaker?: string;
  equipment?: string[];
  equipmentOther?: string;
  iconImageUri?: string;
  id: string;
  name: string;
  numberOfProjects?: string;
  ownerId: string;
  parent?: Organization;
  parentId: string;
  phoneNumber?: string;
  pictureUrl?: string;
  postalCode?: string;
  reviewStats?: ReviewStats;
  size?: string;
  status: OrganizationStatusKey;
  typeId: OrganizationTypeKey;
  updatedAt: string;
  url?: string;
  users?: OrganizationUserRole[];
}

export interface OrganizationCustomFields {
  access?: string;
  address?: string;
  addressLine4?: string;
  buildArea?: string[];
  capital?: string;
  closingDay?: string;
  closingDaySub?: string;
  companyName?: string;
  compatibleConstructionMethod?: string;
  compensate?: string;
  conditionsTransaction?: string;
  conditionsTransactionNote?: string;
  constructionArea?: string;
  constructionResult?: string;
  establishment?: string;
  exhibitionRequestDocQuestions?: OrganizationQuestionnaire[];
  exhibitionVisitorReserveQuestions?: OrganizationQuestionnaire[];
  features?: string[];
  groupName?: string;
  licenseNumber?: string;
  mainCatch?: string;
  mainImageIds?: string[];
  mainImages?: Partial<OrganizationImage>[];
  phoneNumberSub?: string;
  prefectureCity?: string;
  propertyRequestDocQuestions?: OrganizationQuestionnaire[];
  propertyVisitorReserveQuestions?: OrganizationQuestionnaire[];
  scheduleActiveFlg?: boolean;
  scheduleWeekly?: OrganizationScheduleWeekly[];
  size?: string;
  strengths?: OrganizationStrength[];
  structureType?: OrganizationStructureTypeKey;
  subCatch?: string;
  subText?: string;
}

export type OrganizationCreate = Partial<Organization> & {
  customFields?: Omit<OrganizationCustomFields, 'mainImages' | 'strengths'> & {
    mainImageIds?: string[];
    strengths?: Omit<OrganizationStrength, 'type' | 'url'>[];
  };
};

export type OrganizationUpdate = Omit<OrganizationCreate, 'ownerId'>;

export interface OrganizationImage {
  id?: string;
  type?: string;
  url: string;
}

export interface OrganizationStrength extends Partial<OrganizationImage> {
  description: string;
  image?: Partial<OrganizationImage>;
  title: string;
}

export interface OrganizationQuestionnaire {
  formType: QuestionnaireFormTypeKey;
  otherFlg: boolean;
  required: boolean;
  selections: string[];
  subText: string;
  title: string;
}

export interface OrganizationScheduleWeekly {
  activeFlg: boolean;
  timeFrom?: string;
  timeTo?: string;
  week: number;
}

export const OrganizationType = {
  COMPANY: '010',
  PERSONAL: '011',
} as const;
export type OrganizationTypeKey =
  (typeof OrganizationType)[keyof typeof OrganizationType];

export const OrganizationStatus = {
  CANCELLED: 'cancelled',
  FORBIDDEN: 'forbidden',
  NORMAL: 'normal',
  RESTRICTED: 'restricted',
} as const;
export type OrganizationStatusKey =
  (typeof OrganizationStatus)[keyof typeof OrganizationStatus];

export const OrganizationAuditStatus = {
  APPROVED: 'approved',
  CHANGE_REQUESTED: 'change_requested',
  IN_REVIEW: 'in_review',
  REJECTED: 'rejected',
  WAITING_FOR_CHANGE_REVIEW: 'waiting_for_change_review',
  WAITING_FOR_REVIEW: 'waiting_for_review',
} as const;
export type OrganizationAuditStatusKey =
  (typeof OrganizationAuditStatus)[keyof typeof OrganizationAuditStatus];

export const OrganizationStructureType = {
  CHILD: 'child',
  PARENT: 'parent',
} as const;
export type OrganizationStructureTypeKey =
  (typeof OrganizationStructureType)[keyof typeof OrganizationStructureType];

export const QuestionnaireFormType = {
  CHECKBOX: 'checkbox',
  PULLDOWN: 'pulldown',
  RADIO: 'radio',
  TEXT: 'text',
} as const;
export type QuestionnaireFormTypeKey =
  (typeof QuestionnaireFormType)[keyof typeof QuestionnaireFormType];

/**
 * Organization user role
 */
export const UserRole = {
  ADMIN: 'admin',
  GUEST: 'guest',
  MEMBER: 'member',
  OWNER: 'owner',
} as const;
export type UserRoleKey = (typeof UserRole)[keyof typeof UserRole];

export interface OrganizationUserRole {
  id: string;
  role: UserRoleKey;
}
export interface OrganizationUser extends User {
  role: UserRoleKey;
}

/**
 * Attachment
 */
export interface Attachment {
  id: string;
  isPublic: boolean;
  ownerId: string;
  ownerType: AttachmentOwnerTypeKey;
  type: string;
  url: string;
}

export interface AttachmentCreate {
  objectId: string;
  ownerId?: string;
  type: string;
}

export const AttachmentOwnerType = {
  ORGANIZATION: 'organization',
} as const;
export type AttachmentOwnerTypeKey =
  (typeof AttachmentOwnerType)[keyof typeof AttachmentOwnerType];
