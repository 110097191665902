import { z } from 'zod';

/**
 * 必須チェック
 * @param name 項目名
 * @param selectFlag 選択系
 */
export const required = (name?: string, selectFlag = false) => {
  const field = `${name ? `${name}を` : ''}`;
  const input = `${selectFlag ? '選択' : '入力'}`;
  return z.string().min(1, `${field}${input}してください`);
};

/**
 * 必須入力の文字数の長さ（最小・最大）チェック
 * @param name 項目名
 * @param min 最小文字数
 * @param max 最大文字数
 */
export const requiredLengthBetween = (
  name?: string,
  min?: number,
  max?: number
) => {
  return required(name).refine(
    (value) => {
      const len = value.length;
      return (
        (min === undefined || len >= min) && (max === undefined || len <= max)
      );
    },
    {
      message: `${[
        `${min !== undefined ? `${min}文字以上` : ''}`,
        `${max !== undefined ? `${max}文字以内` : ''}`,
      ]
        .filter((i) => !!i)
        .join('、')}で入力してください`,
    }
  );
};

/**
 * テキスト入力必須＆半角数字チェック
 * @param name 項目名
 */
export const requiredInt = (name?: string) => {
  return required(name).regex(/^[0-9]+$/, '半角数字で入力してください');
};

/**
 * 必須入力の最小・最大値チェック
 * @param name 項目名
 * @param min 最小値
 * @param max 最大値
 */
export const requiredIntBetween = (
  name?: string,
  min?: number,
  max?: number
) => {
  return requiredInt(name).refine(
    (value) => {
      const num = parseInt(value, 10);
      return (
        (min === undefined || num >= min) && (max === undefined || num <= max)
      );
    },
    {
      message: `${[
        `${min !== undefined ? `${min}以上` : ''}`,
        `${max !== undefined ? `${max}以下` : ''}`,
      ]
        .filter((i) => !!i)
        .join('、')}で入力してください`,
    }
  );
};

/**
 * 任意入力の半角数字チェック
 */
export const optionalInt = () => {
  return z
    .string()
    .refine(
      (value) => (value === '' ? true : /^[0-9]+$/.test(value)),
      '半角数字で入力してください'
    );
};

/**
 * 任意入力の最小・最大値チェック
 * @param min 最小値
 * @param max 最大値
 */
export const optionalIntBetween = (min?: number, max?: number) => {
  return optionalInt().refine(
    (value) => {
      if (value === '') return true;
      const num = parseInt(value, 10);
      return (
        (min === undefined || num >= min) && (max === undefined || num <= max)
      );
    },
    {
      message: `${[
        `${min !== undefined ? `${min}以上` : ''}`,
        `${max !== undefined ? `${max}以下` : ''}`,
      ]
        .filter((i) => !!i)
        .join('、')}で入力してください`,
    }
  );
};

/**
 * 任意入力の文字数の長さチェック
 * @param length 文字数
 */
export const optionalLength = (length: number) => {
  return z.string().refine(
    (value) => {
      if (value === '') return true;
      const len = value.length;
      return length === len;
    },
    {
      message: `${length}文字で入力してください`,
    }
  );
};

/**
 * 任意入力の半角数字の長さチェック
 * @param length 文字数
 */
export const optionalIntLength = (length: number) => {
  return optionalInt().refine(
    (value) => {
      if (value === '') return true;
      const len = value.length;
      return length === len;
    },
    {
      message: `${length}桁で入力してください`,
    }
  );
};

/**
 * 任意入力の文字数の長さ（最小・最大）チェック
 * @param min 最小文字数
 * @param max 最大文字数
 */
export const optionalLengthBetween = (min?: number, max?: number) => {
  return z.string().refine(
    (value) => {
      if (value === '') return true;
      const len = value.length;
      return (
        (min === undefined || len >= min) && (max === undefined || len <= max)
      );
    },
    {
      message: `${[
        `${min !== undefined ? `${min}文字以上` : ''}`,
        `${max !== undefined ? `${max}文字以内` : ''}`,
      ]
        .filter((i) => !!i)
        .join('、')}で入力してください`,
    }
  );
};

/**
 * 任意入力の半角数字の長さ（最小・最大）チェック
 * @param min 最小文字数
 * @param max 最大文字数
 */
export const optionalIntLengthBetween = (min?: number, max?: number) => {
  return optionalInt().refine(
    (value) => {
      if (value === '') return true;
      const len = value.length;
      return (
        (min === undefined || len >= min) && (max === undefined || len <= max)
      );
    },
    {
      message: `${[
        `${min !== undefined ? `${min}桁以上` : ''}`,
        `${max !== undefined ? `${max}桁以下` : ''}`,
      ]
        .filter((i) => !!i)
        .join('、')}で入力してください`,
    }
  );
};
