import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Divider,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  deleteUser,
  getUsers,
  lockUser,
  unLockUser,
} from '@app/adapter/user-service';
import { SendResetPasswordEmailButton } from '@app/components/Shared/Button/SendResetPasswordEmailButton';
import { LoadingSpinner } from '@app/components/Shared/LoadingSpinner';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { SimpleDialog } from '@app/components/Shared/SimpleDialog';
import { UserNameWithIcon } from '@app/components/User/UserNameWithIcon';
import { UserStatusLabel } from '@app/components/User/UserStatusLabel';
import { organizationSelector } from '@app/domain/organization';
import { useRoleChecker } from '@app/hooks/useRoleChecker';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { User } from '@app/types/user';
import { isError } from '@app/utils/error';
import { convertUtcToJp, dateConvertToFormat } from '@app/utils/format';
import {
  checkUserDeleteOrLock,
  getSubRoleLabel,
  getUserFullName,
  getRoleLabelByUser,
} from '@app/utils/user';

export function Detail() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { loggedInUser, checkReadUser } = useRoleChecker();
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbar = useSetSnackbar();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);

  const [user, setUser] = useState<User>();
  const fetchUser = async () => {
    if (!userId || !organizationState) return;
    setIsUpdating(true);
    try {
      const {
        data: { value },
      } = await getUsers({
        filter: {
          id: userId,
        },
        withOrganization: true,
      });
      const data = value.find((v) => v.id === userId);
      if (!data) {
        throw new Error('メンバーが見つかりません');
      }
      setUser(data);
    } catch (error) {
      setSnackbar(true, 'メンバーが見つかりませんでした', 'error');
      if (isError(error)) {
        console.error(error.message);
      }
      navigate(-1);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleUpdateLock = async () => {
    if (!userId || isUpdating) return;
    setIsUpdating(true);
    try {
      user?.isLocked ? await unLockUser(userId) : await lockUser(userId);
      setSnackbar(true, 'ステータスを更新しました', 'success');
      void fetchUser();
    } catch (error) {
      setSnackbar(true, 'ステータスを更新できませんでした', 'error');
      if (isError(error)) {
        console.error(error.message);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDeleteUser = async () => {
    if (!userId || isUpdating) return;
    setIsUpdating(true);
    try {
      await deleteUser(userId);
      setSnackbar(true, '削除しました', 'success');
      navigate('/users');
    } catch (error) {
      setSnackbar(true, '削除できませんでした', 'error');
      if (isError(error)) {
        console.error(error.message);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    void fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checkReadUser()) {
    return <></>;
  }

  if (!user || !organizationState || !loggedInUser)
    return <LoadingSpinner sx={{ height: '60vh' }} />;
  return (
    <>
      <PageTitle title="メンバー詳細" />
      <Card sx={{ py: 3 }}>
        <Stack spacing={3} divider={<Divider flexItem />}>
          <Stack direction="row" spacing={2} alignItems="center" px={2}>
            <UserNameWithIcon user={user} size="large" />
            <Box flexGrow={1} />
            <Stack direction="row" spacing={1.5}>
              <Button
                variant="contained"
                color="error"
                size="small"
                disabled={!checkUserDeleteOrLock(user, loggedInUser.id)}
                onClick={() => setIsShowDialog(true)}
                sx={{ width: '100px' }}
              >
                <Typography>削除</Typography>
              </Button>
              <SendResetPasswordEmailButton user={user} />
              <MuiLink component={Link} to={`/users/${userId}/edit`}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  sx={{ width: '100px' }}
                >
                  <Typography>編集</Typography>
                </Button>
              </MuiLink>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center" px={2}>
            <Typography variant="body2">ステータス</Typography>
            <UserStatusLabel user={user} />
            <LoadingButton
              color={user.isLocked ? 'primary' : 'error'}
              variant="outlined"
              disabled={!checkUserDeleteOrLock(user, loggedInUser.id)}
              loading={isUpdating}
              onClick={handleUpdateLock}
            >
              <Typography>
                {user?.isLocked ? '利用再開' : '利用停止'}
              </Typography>
            </LoadingButton>
          </Stack>
          <Stack spacing={4} divider={<Divider flexItem />} px={2} py={4}>
            <Stack direction="row">
              <Typography variant="body2" width={160}>
                氏名
              </Typography>
              <Typography variant="body2">{getUserFullName(user)}</Typography>
            </Stack>
            <Stack direction="row">
              <Typography variant="body2" width={160}>
                メールアドレス
              </Typography>
              <Typography variant="body2">{user.email}</Typography>
            </Stack>
            <Stack direction="row">
              <Typography variant="body2" width={160}>
                権限
              </Typography>
              <Typography variant="body2">
                {getRoleLabelByUser(organizationState.id, user) +
                  (user.customFields.subRoles?.length
                    ? `（${getSubRoleLabel(user.customFields.subRoles)}）`
                    : '')}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography variant="body2" width={160}>
                登録日時
              </Typography>
              <Typography variant="body2">
                {dateConvertToFormat(
                  convertUtcToJp(user.createdAt),
                  'yyyy/MM/dd HH:mm'
                )}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Card>
      <SimpleDialog
        open={isShowDialog}
        title="削除"
        content={`${getUserFullName(user)} を削除しますか？`}
        submitButton="削除"
        submitButtonColor="error"
        onClickSubmit={handleDeleteUser}
        onClickCancel={() => setIsShowDialog(false)}
      />
    </>
  );
}
