import { atom, selector } from 'recoil';

import { Organization } from '@app/types/organization';

export const organizations = atom<Array<Organization>>({
  default: [],
  key: 'organizations',
});

export const organization = atom<Organization | null>({
  default: null,
  key: 'organization',
});
export const organizationSelector = selector<Organization | null>({
  get: ({ get }) => {
    return get(organization);
  },
  key: 'organizationSelector',
});
