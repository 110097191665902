import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

export interface ListActionMenuProps {
  id: string;
  menuItems?: { disabled?: boolean; label: string; onClick?: () => void }[];
}

export function ListActionMenu({ id, menuItems = [] }: ListActionMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  return (
    <>
      <IconButton
        aria-controls={isOpen ? `actionMenu-${id}` : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`actionMenu-${id}`}
        anchorEl={anchorEl}
        open={isOpen}
        keepMounted
        onClose={() => setAnchorEl(null)}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            disabled={!!item.disabled}
            onClick={() => {
              setAnchorEl(null);
              item.onClick?.();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
