import { useEffect, useState } from 'react';

interface MarkerProps extends google.maps.MarkerOptions {
  onPositionChanged?: (position: google.maps.LatLngLiteral) => void;
}

export const Marker: React.FC<MarkerProps> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({
        ...options,
        draggable: true,
      });

      google.maps.event.clearListeners(marker, 'dragend');
      google.maps.event.addListener(marker, 'dragend', () => {
        const position = marker.getPosition();
        if (position && options.onPositionChanged) {
          options.onPositionChanged({
            lat: position.lat(),
            lng: position.lng(),
          });
        }
      });
    }
  }, [marker, options]);

  return null;
};
