import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { NumericTextField } from '@app/components/Shared/Inputs/NumericTextField';

export interface PhoneNumberProps {
  field1stName: string;
  field2ndName: string;
  field3rdName: string;
}

export function PhoneNumber({
  field1stName,
  field2ndName,
  field3rdName,
}: PhoneNumberProps): ReactElement {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        divider={<Typography variant="body3">ー</Typography>}
      >
        <Controller
          name={field1stName}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericTextField
              {...field}
              error={!!error}
              placeholder="080"
              maxLength={4}
              sx={{ width: 80 }}
            />
          )}
        />
        <Controller
          name={field2ndName}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericTextField
              {...field}
              error={!!error}
              placeholder="0000"
              maxLength={4}
              sx={{ width: 80 }}
            />
          )}
        />
        <Controller
          name={field3rdName}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericTextField
              {...field}
              error={!!error}
              placeholder="0000"
              maxLength={4}
              sx={{ width: 80 }}
            />
          )}
        />
      </Stack>
      {Array.from(
        new Set([
          get(errors, `${field1stName}.message`, ''),
          get(errors, `${field2ndName}.message`, ''),
          get(errors, `${field3rdName}.message`, ''),
        ])
      )
        .filter((m) => !!m)
        .map((message, index) => (
          <FormHelperText key={index} error>
            {message as string}
          </FormHelperText>
        ))}
    </Box>
  );
}
