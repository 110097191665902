import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { getOrganization } from '@app/adapter/organization-service';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { PRODUCT_CONDITIONS_TRANSACTION_OPTIONS as CONDITIONS_TRANSACTION_OPTIONS } from '@app/utils/constants';
import { isError } from '@app/utils/error';

interface CompanyInfoFormSectionProps {
  organizationId?: string;
}

export function CompanyInfoFormSection({
  organizationId,
}: CompanyInfoFormSectionProps): ReactElement {
  const setSnackbar = useSetSnackbar();
  const [isLoading, setLoading] = useState(false);

  const { clearErrors, control, setValue } = useFormContext();
  const salesHouseCount = useWatch({ control, name: 'salesHouseCount' });

  const setCompanyInfo = async () => {
    if (!organizationId) return;
    try {
      setLoading(true);
      const { data } = await getOrganization(organizationId);
      setValue(
        'conditionsTransaction',
        data.customFields?.conditionsTransaction || ''
      );
      setValue(
        'conditionsTransactionNote',
        data.customFields?.conditionsTransactionNote || ''
      );
      setValue(
        'companyAddress',
        [
          data.customFields?.prefectureCity || '',
          data.addressLine3 || '',
          data.customFields?.addressLine4 || '',
        ].join('')
      );
      setValue('groupName', data.customFields?.groupName || '');
      setValue('licenseNumber', data.customFields?.licenseNumber || '');
      setValue('companyName', data.name || '');
    } catch (error) {
      if (isError(error)) {
        console.error(error.message);
        setSnackbar(true, '情報の参照に失敗しました', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (salesHouseCount < 10) {
      clearErrors('licenseNumber');
    }
  }, [salesHouseCount, clearErrors]);

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={1.5} alignItems="center">
        <Typography variant="h5">会社情報</Typography>
        {!!organizationId && (
          <LoadingButton
            size="small"
            variant="outlined"
            color="secondary"
            loading={isLoading}
            onClick={setCompanyInfo}
            sx={{ height: 32 }}
          >
            登録情報反映
          </LoadingButton>
        )}
      </Stack>
      <Stack>
        <FormLabel focused={false} className="required-label">
          取引態様
        </FormLabel>
        <Controller
          name="conditionsTransaction"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <Select
                {...field}
                error={!!error}
                displayEmpty
                onChange={(e) => {
                  field.onChange(e);
                }}
                sx={{ width: 182 }}
              >
                <MenuItem value="">{'　'}</MenuItem>
                {CONDITIONS_TRANSACTION_OPTIONS.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </div>
          )}
        />
        <Controller
          name="conditionsTransactionNote"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              rows={5}
              error={!!error}
              helperText={error?.message}
              fullWidth
              sx={{ mt: 1 }}
            />
          )}
        />
        <FormHelperText>
          ※ 取引態様が複数ある場合はこちらにフリー入力して下さい
        </FormHelperText>
      </Stack>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          住所
        </FormLabel>
        <Controller
          name="companyAddress"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="condition-required-label">
          所属団体名
        </FormLabel>
        <Controller
          name="groupName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="condition-required-label">
          免許番号
        </FormLabel>
        <Controller
          name="licenseNumber"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>※販売戸数が10戸以上の場合は入力必須</FormHelperText>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          社名
        </FormLabel>
        <Controller
          name="companyName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
      </FormControl>
    </Stack>
  );
}
