import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { DateAndTime } from '@app/components/Shared/Inputs/DateAndTime';
import { LocationsWithPostalCode } from '@app/components/Shared/Inputs/LocationsWithPostalCode';
import { PhoneNumber } from '@app/components/Shared/Inputs/PhoneNumber';

export function BasicInfoFormSection(): ReactElement {
  const { control } = useFormContext();

  return (
    <Stack spacing={3}>
      <Typography variant="h5">住宅展示場情報</Typography>
      <Typography fontWeight="700">所在地</Typography>
      <Stack>
        <FormLabel focused={false} className="required-label">
          住所
        </FormLabel>
        <LocationsWithPostalCode />
      </Stack>
      <Stack>
        <FormLabel focused={false} className="required-label">
          住所末尾
        </FormLabel>
        <Controller
          name="addressLine3"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              placeholder="町名・番地"
            />
          )}
        />
        <Controller
          name="addressLine3HiddenFlag"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControlLabel
              {...field}
              checked={field.value}
              label={<Typography variant="body2">住所末尾を表示</Typography>}
              control={<Checkbox />}
              sx={{ ml: 0, width: 'fit-content' }}
            />
          )}
        />
      </Stack>
      <Typography fontWeight="700">問い合わせ</Typography>
      <Stack>
        <FormLabel focused={false} className="required-label">
          問い合わせ先
        </FormLabel>
        <Controller
          name="contactAddress"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>例）〇〇販売センター</FormHelperText>
      </Stack>
      <Stack>
        <FormLabel focused={false} className="required-label">
          問い合わせ先電話番号
        </FormLabel>
        <PhoneNumber
          field1stName="contactPhoneNumber.0"
          field2ndName="contactPhoneNumber.1"
          field3rdName="contactPhoneNumber.2"
        />
      </Stack>
      <Stack>
        <FormLabel focused={false}>問い合わせ先補足</FormLabel>
        <Controller
          name="contactAdditional"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>
          例）営業時間10:00AM ~ 5:00PM / 定休日：火曜日
        </FormHelperText>
      </Stack>
      <Typography fontWeight="700">営業時間</Typography>
      <Stack>
        <FormLabel focused={false} className="required-label">
          営業開始時刻
        </FormLabel>
        <DateAndTime
          fieldHourName="openingTime.0"
          fieldMinuteName="openingTime.1"
        />
      </Stack>
      <Stack>
        <FormLabel focused={false} className="required-label">
          営業終了時刻
        </FormLabel>
        <DateAndTime
          fieldHourName="closingTime.0"
          fieldMinuteName="closingTime.1"
        />
      </Stack>
      <Stack>
        <FormLabel focused={false} className="required-label">
          定休日
        </FormLabel>
        <Controller
          name="regularHoliday"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
      </Stack>
      <Stack>
        <FormLabel focused={false}>定休日サブテキスト</FormLabel>
        <Controller
          name="regularHolidayAdditional"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>例）モデルハウス個別の定休あり</FormHelperText>
      </Stack>
      <Typography fontWeight="700">アクセス</Typography>
      <Stack>
        <FormLabel focused={false} className="required-label">
          主要交通
        </FormLabel>
        <Controller
          name="access"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
      </Stack>
    </Stack>
  );
}
