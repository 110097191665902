import Typography, { TypographyProps } from '@mui/material/Typography';
import { ReactElement } from 'react';

import { User } from '@app/types/user';
import { getStatusLabel } from '@app/utils/user';

export interface UserStatusLabelProps extends TypographyProps {
  user?: User;
}
export function UserStatusLabel({
  user,
  ...props
}: UserStatusLabelProps): ReactElement {
  return <Typography {...props}>{getStatusLabel(user?.isLocked)}</Typography>;
}
