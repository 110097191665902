import { RemoveCircleOutlined as RemoveCircleOutlinedIcon } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { get } from 'lodash';
import { ReactElement } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { AdditionalButton } from '@app/components/Shared/Button/AdditionalButton';

export interface OverviewFormSectionProps {
  onChangeName?: (value: string) => void;
}
export function OverviewFormSection({
  onChangeName,
}: OverviewFormSectionProps): ReactElement {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'features',
  });

  return (
    <Stack spacing={3}>
      <Typography variant="h5">概要</Typography>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          住宅展示場タイトル
        </FormLabel>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              onChange={(e) => {
                field.onChange(e);
                onChangeName?.(e.target.value);
              }}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false}>展示場の特徴</FormLabel>
        <Stack spacing={1}>
          {fields.map((field, index) => (
            <FormControl key={field.id}>
              <Stack direction="row" alignItems="center">
                <Controller
                  name={`features.${index}.value`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField {...field} error={!!error} fullWidth />
                  )}
                />
                {!!index && (
                  <IconButton color="error" onClick={() => remove(index)}>
                    <RemoveCircleOutlinedIcon />
                  </IconButton>
                )}
              </Stack>
              {Array.from(
                new Set([get(errors, `features.${index}.value.message`, '')])
              )
                .filter((m) => !!m)
                .map((message, index) => (
                  <FormHelperText key={index} error>
                    {message as string}
                  </FormHelperText>
                ))}
            </FormControl>
          ))}
          <AdditionalButton
            label="概要の追加"
            disabled={fields.length >= 5}
            fullWidth
            onClick={() => append({ value: '' })}
          />
        </Stack>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          メインキャッチ
        </FormLabel>
        <Controller
          name="additionalInformation"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          サブキャッチ
        </FormLabel>
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              rows={5}
              error={!!error}
              helperText={error?.message}
              multiline
            />
          )}
        />
        <FormHelperText>
          例）ご家庭のライフスタイルに寄り添う、世界でいちばん幸せな「わが家」をご提案
        </FormHelperText>
      </FormControl>
    </Stack>
  );
}
