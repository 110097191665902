import { Add as AddIcon } from '@mui/icons-material';
import {
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { ReactElement } from 'react';

import { theme } from '@app/theme';

export interface AdditionalButtonProps extends ButtonProps {
  iconSize?: string | number;
  label?: string;
  labelProps?: TypographyProps;
}

export function AdditionalButton({
  iconSize = 24,
  label,
  labelProps,
  ...props
}: AdditionalButtonProps): ReactElement {
  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      startIcon={
        <AddIcon
          sx={{
            fontSize: `${
              typeof iconSize === 'number' ? `${iconSize}px` : iconSize
            } !important`,
          }}
        />
      }
      {...props}
      sx={{
        borderColor: theme.customPalette.gray,
        color:
          props.variant === 'contained' ? 'white' : theme.customPalette.gray8,
        ...props.sx,
      }}
    >
      <Typography
        color={props.variant === 'contained' ? 'white' : 'inherit'}
        {...labelProps}
      >
        {label}
      </Typography>
    </Button>
  );
}
