/**
 * Message
 */
export interface Message {
  category: string;
  content: string;
  createdAt: string;
  customFields?: MessageCustomFields;
  id: string;
  priority: string;
  publications: MessagePublications;
  readStatus?: string;
  senderId: string;
  senderType: string;
  shouldSendMail: boolean;
  title: string;
  topicId: string;
  typeId: string;
  updatedAt: string;
}

export interface MessageCustomFields {
  publicationTime?: string;
  status: MessageStatus;
  target: MessageTarget;
}

export interface MessagePublications {
  since: number;
}

export type MessageCreate = Omit<
  Message,
  'id' | 'createdAt' | 'priority' | 'shouldSendMail' | 'updatedAt'
>;

export type MessageUpdate = {
  content: string;
  customFields: {
    status: string;
  };
  publications: {
    since: number;
  };
  title: string;
};

export const MessageTargets = {
  Common: 'common',
  Demand: 'demand',
  Supply: 'supply',
} as const;
export type MessageTarget =
  (typeof MessageTargets)[keyof typeof MessageTargets];

export const MessageStatuses = {
  CLOSE: 'CLOSE',
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  WAITING: 'WAITING',
} as const;
export type MessageStatus =
  (typeof MessageStatuses)[keyof typeof MessageStatuses];

/**
 * Topic
 */
export interface Topic {
  app: string;
  createdAt: string;
  id: string;
  isPublic: boolean;
  name: string;
  ownerId: string;
  ownerType: string;
  typeId: string;
  updatedAt: string;
}

export type TopicCreate = Omit<Topic, 'id' | 'createdAt' | 'updatedAt'>;

export type TopicUpdate = TopicCreate;

export type SubscriptionCreate = {
  approved: boolean;
  permissions: string[];
  topicId: string;
  type: string;
};

export type MessagePopupData = {
  id?: string;
  since: number;
  target: string;
  title: string;
};
