import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import {
  getUsersByOrgId,
  updateOrganizationRoles,
} from '@app/adapter/organization-service';
import { getUsers, updateUser } from '@app/adapter/user-service';
import { organizationSelector } from '@app/domain/organization';
import {
  UserEditForm,
  UserEditFormData,
  UserEditFormDefaultValues as DefaultValue,
} from '@app/schemas/user';
import { UserRole, UserRoleKey } from '@app/types/organization';
import { User, UserSubRoleKey } from '@app/types/user';
import { isError } from '@app/utils/error';
import { getRoleByUser } from '@app/utils/user';

export const useUserEditForm = (id?: string, organizationId?: string) => {
  const organizationState = useRecoilValue(organizationSelector);
  const userForm = useForm<UserEditFormData>(UserEditForm);
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState<string>(id || '');
  const [user, setUser] = useState<User>();

  const fetchUser = async () => {
    if (!id || !organizationId) return;
    try {
      setIsLoading(true);
      const {
        data: { value },
      } = await getUsers({
        filter: {
          id,
        },
        withOrganization: true,
      });
      const data = value.find((v) => v.id === id);
      if (!data) {
        throw new Error('メンバーが見つかりません');
      }
      setUser(data);
      setUserId(data.id);
      const mainRole =
        (organizationState?.id &&
          data &&
          getRoleByUser(organizationState.id, data)) ||
        DefaultValue.role;
      const role =
        mainRole === UserRole.OWNER
          ? mainRole
          : `${mainRole}-${data.customFields.subRoles}`;

      userForm.reset({
        email: data?.email || DefaultValue.email,
        familyName: data?.customFields?.familyName || DefaultValue.familyName,
        firstName: data?.customFields?.firstName || DefaultValue.firstName,
        role,
      });
    } catch (error) {
      if (isError(error)) {
        throw new Error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const sendUserUpdate = async () => {
    if (!userId || !user || !organizationId) return;
    try {
      //const { data } = await getUser(userId);
      const {
        data: { value },
      } = await getUsersByOrgId(organizationId, {
        expand: 'user',
        pageSize: 100,
      });
      const data = value.find((v) => v.id === userId);
      if (!data) {
        throw new Error('メンバーが見つかりません');
      }

      const formValue = userForm.getValues();
      const subRole = formValue.role.split('-')[1];
      await updateUser(userId, {
        customFields: {
          ...data.customFields,
          familyName: formValue.familyName,
          firstName: formValue.firstName,
          subRoles: subRole ? [subRole as UserSubRoleKey] : [],
        },
        email: formValue.email,
      });

      const role = formValue.role.split('-')[0];
      if (user.role !== role) {
        await updateOrganizationRoles(organizationId, [
          { id: userId, role: role as UserRoleKey },
        ]);
      }
    } catch (error) {
      if (isError(error)) {
        throw new Error(error.message);
      }
    }
  };

  useEffect(() => {
    void fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return {
    isLoading,
    sendUserUpdate,
    user,
    userForm,
  };
};
