import { MenuItem, Select, SelectProps, Typography } from '@mui/material';
import { forwardRef, ForwardedRef, useMemo } from 'react';

import { theme } from '@app/theme';

export interface OptionProps {
  bgcolor?: string;
  color?: string;
  label: string;
  severity?: string;
  value: string;
}

export interface StatusSelectProps extends SelectProps {
  options: OptionProps[];
}

export const StatusSelect = forwardRef(function (
  { options, ...props }: StatusSelectProps,
  ref: ForwardedRef<unknown>
) {
  const color = useMemo(() => {
    const option = options.find((o) => o.value === props.value);
    switch (option?.severity) {
      case 'success':
        return theme.customPalette.green;
      case 'error':
        return option?.severity;
    }
    return option?.color || 'inherit';
  }, [options, props.value]);

  const bgcolor = useMemo(() => {
    const option = options.find((o) => o.value === props.value);
    switch (option?.severity) {
      case 'success':
        return theme.customPalette.success;
      case 'error':
        return theme.palette.error.light;
    }
    return option?.bgcolor || 'inherit';
  }, [options, props.value]);

  return (
    <Select
      ref={ref}
      size="small"
      className="border-none"
      renderValue={(value: unknown) => {
        const option = options.find((o) => o.value === value);
        return (
          <Typography variant="body2" color={color}>
            {option?.label || ''}
          </Typography>
        );
      }}
      {...props}
      sx={{ bgcolor, ...props.sx }}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
});
