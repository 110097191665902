import {
  addDays,
  addYears,
  endOfDay,
  isAfter,
  subDays,
  parseISO,
} from 'date-fns';

import {
  Product,
  ProductImage,
  ProductLocation,
  ProductStatus,
  ProductStatusKey,
  ProductVariant,
  ProductVariantSkuKey,
} from '@app/types/catalog';

export function isUpdatedAtOverdue(product?: Product): boolean {
  if (
    !product?.updatedAt ||
    product?.publication.status !== ProductStatus.ACTIVE
  )
    return false;
  // product.updatedAtをDateオブジェクトに変換
  const updatedAtDate = parseISO(product.updatedAt);
  // 1週間前の日時を取得
  const oneWeekAgo = subDays(new Date(), 7);
  // updatedAtDateが1週間前より前かどうかを判定
  return isAfter(oneWeekAgo, updatedAtDate);
}

export function getVariantsBySku(
  variants: ProductVariant[] | undefined,
  sku?: ProductVariantSkuKey
) {
  return variants
    ? variants
        .filter((v) => v.sku === sku)
        .map((v) => {
          return {
            customFields: v.customFields || {},
            description: v.description,
            id: v.id,
            imageId: v.images?.[0]?.id || '',
            title: v.title,
            url: v.images?.[0]?.url || '',
          };
        })
    : undefined;
}

export function replaceProductImages(product: Product): Product {
  return {
    ...product,
    images: product.imageIds
      ?.map((id: string) =>
        product.images?.find((image: ProductImage) => image.id === id)
      )
      .filter((i) => !!i) as ProductImage[],
  };
}

/**
 * Product ステータスによる掲載開始日を返す
 */
export function getPublicationSince(
  status?: ProductStatusKey,
  currentSince?: string
): string | undefined {
  return status === ProductStatus.ACTIVE && !currentSince
    ? new Date().toISOString()
    : currentSince;
}

/**
 * Product ステータスによる掲載終了日を返す
 */
export function getPublicationUntil(
  status?: ProductStatusKey,
  currentUntil?: string,
  propertyFlg = false
): string | undefined {
  switch (status) {
    case ProductStatus.ACTIVE:
      return propertyFlg
        ? addDays(endOfDay(new Date()), 7).toISOString()
        : addYears(endOfDay(new Date()), 10).toISOString();
    case ProductStatus.DRAFT:
      return currentUntil;
  }
  return currentUntil;
}

/**
 * 都道府県 ID の配列を都道府県名のカンマ区切り文字列に変換する関数
 * @param constructionAreaIds
 * @param prefectures
 * @returns 都道府県名のカンマ区切り文字列または「全国」
 */
export function getConstructionAreaName(
  constructionAreaIds: string[] | undefined,
  prefectures: ProductLocation[],
  separator = ','
): string {
  if (!Array.isArray(constructionAreaIds) || prefectures.length === 0) {
    return '';
  }

  if (constructionAreaIds.length === prefectures.length) {
    const allPrefectureIds = prefectures.map((pref) => pref.id);
    const isAllSelected = allPrefectureIds.every((id) =>
      constructionAreaIds.includes(id)
    );
    if (isAllSelected) return '全国';
  }

  return constructionAreaIds
    .map((id) => prefectures.find((pref) => pref.id === id)?.name || id)
    .join(separator);
}
