import {
  Box,
  Card,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  deleteUser,
  getUsers,
  lockUser,
  unLockUser,
} from '@app/adapter/user-service';
import { AdditionalButton } from '@app/components/Shared/Button/AdditionalButton';
import { ListActionMenu } from '@app/components/Shared/ListActionMenu';
import { ListTable, paginationModel } from '@app/components/Shared/ListTable';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { SearchTextBox } from '@app/components/Shared/SearchTextBox';
import { MemberInviteDialog } from '@app/components/User/MemberInviteDialog';
import { UserNameWithIcon } from '@app/components/User/UserNameWithIcon';
import { UserStatusLabel } from '@app/components/User/UserStatusLabel';
import { organizationSelector } from '@app/domain/organization';
import { useRoleChecker } from '@app/hooks/useRoleChecker';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { UserSearchForm, UserSearchFormData } from '@app/schemas/user';
import { UserRole } from '@app/types/organization';
import { User, UserTypeId } from '@app/types/user';
import {
  SEARCH_STATUS_OPTIONS,
  SEARCH_STATUS_VALUES,
  SORT_OPTIONS,
} from '@app/utils/constants';
import { isError } from '@app/utils/error';
import {
  checkUserDeleteOrLock,
  getRoleByUser,
  getRoleLabel,
  getSubRoleLabel,
} from '@app/utils/user';

export function Index() {
  const navigate = useNavigate();
  const { loggedInUser, checkReadUser } = useRoleChecker();
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbar = useSetSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAddDialog, setIsShowAddDialog] = useState(false);

  const { control } = useForm<UserSearchFormData>(UserSearchForm);
  const formWatch = useWatch({ control });
  const [keyword, setKeyword] = useState('');

  const [currentPaginationModel, setCurrentPaginationModel] =
    useState<GridPaginationModel>(paginationModel);
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '氏名',
      renderCell: (params) => <UserNameWithIcon user={params.row} />,
      sortable: false,
      width: 210,
    },
    {
      field: 'email',
      flex: 1,
      headerName: 'メールアドレス',
      minWidth: 280,
      renderCell: (params) => (
        <Box className="text-ellipsis">{params.row.email}</Box>
      ),
      sortable: false,
    },
    {
      field: 'organizationName',
      headerName: '所属企業',
      renderCell: (params) => (
        <Box className="text-ellipsis">
          {params.row.organizations?.[0]?.parent?.name || '-'}
        </Box>
      ),
      sortable: false,
      width: 280,
    },
    {
      field: 'role',
      headerName: '権限',
      renderCell: (params) => {
        if (organizationState) {
          const mainRole = getRoleByUser(organizationState.id, params.row);
          const role =
            mainRole === UserRole.OWNER
              ? getRoleLabel(mainRole)
              : `${getRoleLabel(mainRole)}（${getSubRoleLabel(
                  params.row.customFields.subRoles
                )}）`;
          return role;
        }
        return '';
      },
      sortable: false,
      width: 200,
    },
    {
      field: 'status',
      headerName: 'ステータス',
      renderCell: (params) => (
        <UserStatusLabel user={params.row} variant="body2" />
      ),
      sortable: false,
      width: 100,
    },
    {
      field: 'actions',
      headerName: '',
      renderCell: (params) => (
        <ListActionMenu
          id={params.row.id}
          menuItems={[
            {
              disabled: !checkUserDeleteOrLock(params.row, loggedInUser?.id),
              label: '削除',
              onClick: () => handleDeleteUser(params.row.id),
            },
            {
              disabled: !checkUserDeleteOrLock(params.row, loggedInUser?.id),
              label: params.row.isLocked ? '利用再開' : '利用停止',
              onClick: () => handleLockUser(params.row),
            },
          ]}
        />
      ),
      sortable: false,
      width: 48,
    },
  ];

  const [rows, setRows] = useState<User[]>([]);
  const [rowTotal, setRowTotal] = useState<number>(0);

  const fetchUsers = useCallback(async () => {
    if (!organizationState) return;
    try {
      setIsLoading(true);
      const response = await getUsers({
        ...currentPaginationModel,
        filter: {
          keyword,
          orgId: organizationState.id,
          typeId: UserTypeId.SUPPLY,
          ...(formWatch.status && {
            isLocked: formWatch.status === SEARCH_STATUS_VALUES.STOP,
          }),
        },
        orderBy: formWatch.sort,
        withOrganization: true,
      });
      setRows(response.data.value);
      setRowTotal(response.data.total);
    } catch (error) {
      if (isError(error)) {
        setSnackbar(true, error.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    currentPaginationModel,
    formWatch.status,
    formWatch.sort,
    keyword,
    setSnackbar,
    organizationState,
  ]);

  const handleDeleteUser = async (id: string) => {
    setIsLoading(true);
    try {
      await deleteUser(id);
      void fetchUsers();
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
      }
    }
  };

  const handleLockUser = async (data: User) => {
    setIsLoading(true);
    try {
      data.isLocked ? await unLockUser(data.id) : await lockUser(data.id);
      void fetchUsers();
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
      }
    }
  };

  const submitMemberInvite = () => {
    setIsShowAddDialog(false);
    void fetchUsers();
  };

  useEffect(() => {
    void fetchUsers();
  }, [fetchUsers]);

  if (!checkReadUser()) {
    return <></>;
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <PageTitle title="メンバー一覧" buttonHidden />
        <AdditionalButton
          label="新規追加"
          color="primary"
          variant="contained"
          iconSize={20}
          labelProps={{ fontWeight: 700, variant: 'body2' }}
          onClick={() => setIsShowAddDialog(true)}
          sx={{ height: 36 }}
        />
      </Stack>
      <Card>
        <Stack direction="row" spacing={1.5} p={1.5}>
          <SearchTextBox value={keyword} onSubmit={setKeyword} />
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="body3">ステータス</Typography>
            <Controller
              name="status"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl>
                  <Select
                    {...field}
                    error={!!error}
                    size="small"
                    displayEmpty
                    sx={{ '.MuiSelect-select': { py: 1.25 }, width: 140 }}
                  >
                    <MenuItem value="">すべて</MenuItem>
                    {SEARCH_STATUS_OPTIONS.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Stack>
          <Box flexGrow={1} />
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="body3">並び順</Typography>
            <Controller
              name="sort"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl>
                  <Select
                    {...field}
                    error={!!error}
                    size="small"
                    displayEmpty
                    sx={{ '.MuiSelect-select': { py: 1.25 }, width: 140 }}
                  >
                    {SORT_OPTIONS.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Stack>
        </Stack>
        <ListTable
          columns={columns}
          rows={rows}
          rowCount={rowTotal}
          loading={isLoading}
          paginationModel={currentPaginationModel}
          className="b-radius-top-less"
          initialState={{
            pagination: { paginationModel },
          }}
          onPaginationModelChange={setCurrentPaginationModel}
          onRowClick={(params) => navigate(`/users/${params.row.id}`)}
        />
      </Card>
      <MemberInviteDialog
        open={isShowAddDialog}
        onClickSubmit={() => submitMemberInvite()}
        onClickCancel={() => setIsShowAddDialog(false)}
      />
    </>
  );
}
