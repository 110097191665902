import { Wrapper } from '@googlemaps/react-wrapper';
import { ReactElement, useEffect, useState } from 'react';

import { BaseMap } from './BaseMap';
import { Marker } from './Marker';

import { useSetSnackbar } from '@app/hooks/useSetSnackbar';

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const GEOCODING_API_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${API_KEY}&address=`;

export interface Position {
  lat: number;
  lng: number;
}

export interface MapProps {
  address?: string;
  onChangePosition?: (position: Position) => void;
  position?: Position;
}

export function Map({
  address,
  onChangePosition,
  position,
}: MapProps): ReactElement {
  const setSnackbar = useSetSnackbar();
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: position?.lat || 35.6895,
    lng: position?.lng || 139.6917,
  });
  const zoom = 14;

  useEffect(() => {
    if (address) {
      const fetchCoordinates = async () => {
        try {
          const response = await fetch(
            `${GEOCODING_API_URL}${encodeURIComponent(address)}`
          );
          const data = await response.json();

          if (data.status === 'OK') {
            const location = data.results[0].geometry.location;
            onChangePosition?.({ lat: location.lat, lng: location.lng });
            setCenter({ lat: location.lat, lng: location.lng });
          } else {
            throw new Error('Geocoding failed:', data.status);
          }
        } catch (error) {
          setSnackbar(true, '住所情報の取得に失敗しました', 'error');
          console.error('Error fetching geocoding data:', error);
        }
      };

      void fetchCoordinates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, setSnackbar]);

  useEffect(() => {
    if (position) {
      setCenter({ lat: position.lat, lng: position.lng });
    }
  }, [position]);

  if (!API_KEY) {
    return <></>;
  }
  return (
    <Wrapper apiKey={API_KEY}>
      <BaseMap
        center={center}
        zoom={zoom}
        style={{ height: '320px', width: '100%' }}
      >
        <Marker
          position={position || null}
          onPositionChanged={onChangePosition}
        />
      </BaseMap>
    </Wrapper>
  );
}
