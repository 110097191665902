import { Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { getProducts } from '@app/adapter/catalog-service';
import { SearchCondition } from '@app/components/Catalog/Exhibition/SearchCondition';
import { ProductCard } from '@app/components/Catalog/ProductCard';
import { ScrollThreshold } from '@app/components/Shared/ScrollThreshold';
import { organizationSelector } from '@app/domain/organization';
import { useProduct } from '@app/hooks/useProduct';
import { useRoleChecker } from '@app/hooks/useRoleChecker';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import {
  ExhibitionSearchForm,
  ExhibitionSearchFormData,
} from '@app/schemas/catalog';
import { Product, ProductCategory, ProductStatusKey } from '@app/types/catalog';
import { isError } from '@app/utils/error';

export function ExhibitionIndex() {
  const navigate = useNavigate();
  const organizationState = useRecoilValue(organizationSelector);
  const { canWriteProductExhibition, checkReadProduct } = useRoleChecker();
  const { updateStatus } = useProduct();
  const setSnackbar = useSetSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const searchForm = useForm<ExhibitionSearchFormData>(ExhibitionSearchForm);

  const [rows, setRows] = useState<Product[]>([]);
  const [rowTotal, setRowTotal] = useState<number>(0);
  const [nextLink, setNextLink] = useState<string>('');
  const fetchProducts = async (next?: string) => {
    if (!organizationState) return;
    setIsLoading(true);
    try {
      const value = searchForm.getValues();
      const { data } = await getProducts(organizationState.id, {
        expand: 'images',
        filter: {
          addressLine1: value.addressLine1,
          addressLine2: value.addressLine2,
          addressLine3: value.addressLine3,
          addressLine4: value.addressLine4,
          alert: value.alert,
          category: ProductCategory.EXHIBITION,
          id: value.id,
          name: value.name,
          postalCode: value.postalCode,
        },
        nextLink: next,
        page: 0,
        pageSize: 15,
      });
      setRows(next ? [...rows, ...data.value] : data.value);
      setRowTotal(data.total);
      setNextLink(data['@nextLink'] || '');
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitSearch = () => {
    setNextLink('');
    void fetchProducts();
  };

  const setRowsItem = (product: Product) => {
    setRows(rows.map((r) => (r.id === product.id ? product : r)));
  };

  useEffect(() => {
    void fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateStatus = async (
    status: ProductStatusKey,
    product: Product
  ) => {
    try {
      const latest = await updateStatus(status, product);
      setSnackbar(true, '更新しました', 'success');
      latest && setRowsItem(latest);
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
      }
      setSnackbar(true, '更新に失敗しました', 'error');
    }
  };

  if (!checkReadProduct(ProductCategory.EXHIBITION)) {
    return <></>;
  }

  return (
    <>
      <Stack spacing={4}>
        <Stack spacing={2.5}>
          <Typography variant="h5">絞り込み</Typography>
          <FormProvider {...searchForm}>
            <form>
              <SearchCondition
                isLoading={isLoading}
                onSubmit={handleSubmitSearch}
              />
            </form>
          </FormProvider>
        </Stack>
        <Stack spacing={2.5}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Typography variant="h5">検索結果</Typography>
            <Typography color="secondary">{rowTotal}件</Typography>
          </Stack>
          <div>
            {!!rows.length && (
              <Grid container spacing={2}>
                {rows.map((row, index) => (
                  <Grid key={index} item xs={4}>
                    <ProductCard
                      product={row}
                      readOnly={!canWriteProductExhibition}
                      onChange={setRowsItem}
                      onChangeStatus={handleUpdateStatus}
                      onClick={(data) =>
                        navigate(`/exhibitions/products/${data.id}/edit`)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
        </Stack>
        <ScrollThreshold
          disabled={!nextLink}
          thresholdReached={() => {
            nextLink && void fetchProducts(nextLink);
          }}
        />
      </Stack>
    </>
  );
}
