import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link as MuiLink,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { setRequestInterceptor } from '@app/adapter/axios';
import {
  getOrganizationDetails,
  getOrganizationRoleByUserId,
} from '@app/adapter/organization-service';
import { getUser } from '@app/adapter/user-service';
import loginMain from '@app/assets/login_main.jpg';
import { Copyright } from '@app/components/Shared/Copyright';
import { Logo } from '@app/components/Shared/Logo';
import {
  afterLoginRouteState,
  loggedInUserState,
  userAuthInfoSelector,
  useClearAuthStateAndStorage,
} from '@app/domain/app';
import { handleLogin } from '@app/domain/network-actions';
import { organization } from '@app/domain/organization';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { LoginForm, LoginFormData } from '@app/schemas/user';
import { storeAccessToken, storeUserId } from '@app/utils/auth';
import { isError } from '@app/utils/error';
import { isVendorUser } from '@app/utils/user';

export function Login() {
  const navigate = useNavigate();
  const setOrganization = useSetRecoilState(organization);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const [afterLoginRoute, setAfterLoginRoute] =
    useRecoilState(afterLoginRouteState);
  const clearAuthStateAndStorage = useClearAuthStateAndStorage();
  const setSnackbar = useSetSnackbar();
  const setUserAuthInfo = useSetRecoilState(userAuthInfoSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<LoginFormData>(LoginForm);

  const handleSubmitLogin = async (formData: LoginFormData) => {
    try {
      setIsLoading(true);
      const data = await handleLogin(formData.email, formData.password);
      const { accessToken, fingerprint } = data;
      setRequestInterceptor({ accessToken, fingerprint });

      const { data: userInfo } = await getUser(data.id);
      // ユーザータイプのチェック
      if (!isVendorUser(userInfo.typeId)) {
        throw new Error('このユーザーはこちらからログインできません');
      }

      const {
        data: { value: orgs },
      } = await getOrganizationDetails(data.id, {
        params: { $top: 1 },
      });
      if (!orgs.length) {
        throw new Error('このユーザーは所属しておりません');
      }

      const { data: roleInfo } = await getOrganizationRoleByUserId(
        orgs[0].id,
        userInfo.id
      );

      setLoggedInUser({ ...userInfo, role: roleInfo.role });
      setUserAuthInfo(data);
      storeAccessToken(data.accessToken);
      storeUserId(data.id);
      setOrganization(orgs[0]);

      if (afterLoginRoute) {
        navigate(afterLoginRoute);
        setAfterLoginRoute(null);
      } else {
        navigate('/');
      }
    } catch (error) {
      clearAuthStateAndStorage();
      let errorMessage = 'ログインに失敗しました';
      if (isError(error)) {
        console.error(error.message);
        if (error.message === 'email or password is wrong') {
          errorMessage = 'メールアドレスまたはパスワードが間違っています';
        }
      }
      setSnackbar(true, errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={6}
          sx={{ display: 'flex', justifyContent: 'center', overflowY: 'auto' }}
        >
          <Stack
            justifyContent="space-between"
            sx={{ height: '100vh', maxWidth: 480, width: '70%' }}
          >
            <Box pt={10} flexGrow={1}>
              <form onSubmit={handleSubmit(handleSubmitLogin)}>
                <Stack spacing={10}>
                  <Logo />
                  <Stack spacing={4}>
                    <Typography variant="h3" pb={1}>
                      ログイン
                    </Typography>
                    <FormControl fullWidth>
                      <FormLabel focused={false}>メールアドレス</FormLabel>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            helperText={error?.message}
                            placeholder="メールアドレスを入力"
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <FormLabel focused={false}>パスワード</FormLabel>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            type={isPasswordVisible ? 'text' : 'password'}
                            error={!!error}
                            helperText={error?.message}
                            placeholder="パスワードを入力"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" sx={{ ml: 0 }}>
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      setIsPasswordVisible(!isPasswordVisible)
                                    }
                                    onMouseDown={(e) => e.preventDefault()}
                                  >
                                    {isPasswordVisible ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              '.MuiInputBase-adornedEnd': { pr: 0 },
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                  <Stack spacing={4} alignItems="center">
                    <MuiLink
                      component={Link}
                      to="/password/reset"
                      variant="body2"
                    >
                      パスワードを忘れた方はこちら
                    </MuiLink>
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={isLoading}
                      disabled={!isValid}
                      fullWidth
                    >
                      ログイン
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            </Box>
            <Copyright />
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            backgroundImage: `url(${loginMain})`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
        />
      </Grid>
    </>
  );
}
