import { AppBar, Toolbar } from '@mui/material';
import { ReactElement } from 'react';

import { menuWidth } from '@app/components/Layout/VendorLayout';

export const bottomMenuHeight = 96;

interface BottomMenuProps {
  content?: ReactElement;
  height?: number;
}

export function BottomMenu({
  content,
  height = bottomMenuHeight,
}: BottomMenuProps): ReactElement {
  return (
    <>
      <Toolbar sx={{ height }} />
      <AppBar
        position="fixed"
        className="bottom-area"
        sx={{ pl: `${menuWidth}px` }}
      >
        <Toolbar sx={{ height }}>{content}</Toolbar>
      </AppBar>
    </>
  );
}
