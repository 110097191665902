import { Global, css } from '@emotion/react';
import {
  HomeOutlined as HomeOutlinedIcon,
  LiveHelpOutlined as LiveHelpOutlinedIcon,
} from '@mui/icons-material';
import {
  Box,
  Drawer,
  Link as MuiLink,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
  Badge,
} from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { getOrdersByOrgId } from '@app/adapter/order-service';
import { Backdrop } from '@app/components/Shared/Backdrop';
import { Header, headerHeight } from '@app/components/Shared/Header';
import { Snackbar } from '@app/components/Shared/Snackbar';
import { organizationSelector } from '@app/domain/organization';
import { AppContext } from '@app/hooks/appContext';
import { useRoleChecker } from '@app/hooks/useRoleChecker';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import {
  Order,
  OrderBadgeState,
  OrderStatus,
  OrderType,
  OrderTypeKey,
} from '@app/types/order';
import { isError } from '@app/utils/error';

export const menuWidth = 256;

export function VendorLayout(): ReactElement {
  const theme = useTheme();
  const location = useLocation();
  const setSnackbar = useSetSnackbar();
  const {
    canReadOrder,
    canReadProductExhibition,
    canReadProductProperty,
    canReadUser,
    canWriteProductExhibition,
    canWriteProductProperty,
  } = useRoleChecker();
  const organizationState = useRecoilValue(organizationSelector);
  const [orderBadge, setOrderBadge] = useState<OrderBadgeState>({
    exhibition: {
      request: 0,
      visitor: 0,
    },
    property: {
      request: 0,
      visitor: 0,
    },
  });

  const menuItems = useMemo(() => {
    const menus: {
      children: {
        badgeCount?: number;
        hidden?: boolean;
        icon?: ReactElement;
        label: string;
        path: string;
        selectedPath?: RegExp;
      }[];
      groupLabel?: string;
    }[] = [
      {
        children: [
          {
            icon: <HomeOutlinedIcon />,
            label: 'ホーム',
            path: '/home',
            selectedPath: /^\/home$|^\/$/,
          },
        ],
      },
      {
        children: [
          /*
          {
            label: '住宅展示場お知らせ',
            path: '/home',
          },
          */
          {
            hidden: !canWriteProductExhibition,
            label: '住宅展示場登録',
            path: '/exhibitions/products/register',
            selectedPath: /^\/exhibitions\/products\/register\/?$/,
          },
          {
            hidden: !canReadProductExhibition,
            label: '住宅展示場一覧',
            path: '/exhibitions/products',
            selectedPath: /^\/exhibitions\/products(\/(?!register).*|\/?)$/,
          },
        ],
        groupLabel: '住宅展示場',
      },
      {
        children: [
          /*
          {
            label: '物件お知らせ',
            path: '/home',
          },
          */
          {
            hidden: !canWriteProductProperty,
            label: '物件登録',
            path: '/properties/products/register',
            selectedPath: /^\/properties\/products\/register\/?$/,
          },
          {
            hidden: !canReadProductProperty,
            label: '物件一覧',
            path: '/properties/products',
            selectedPath: /^\/properties\/products(\/(?!register).*|\/?)$/,
          },
        ],
        groupLabel: '分譲',
      },
      {
        children: [
          {
            badgeCount:
              orderBadge.exhibition.request + orderBadge.exhibition.visitor,
            hidden: !canReadOrder,
            label: '住宅展示場',
            path: '/exhibitions/orders',
            selectedPath: /^\/exhibitions\/orders(\/.*)?$/,
          },
          {
            badgeCount:
              orderBadge.property.request + orderBadge.property.visitor,
            hidden: !canReadOrder,
            label: '分譲',
            path: '/properties/orders',
            selectedPath: /^\/properties\/orders(\/.*)?$/,
          },
        ],
        groupLabel: '反響管理',
      },
      {
        children: [
          {
            hidden: !canReadUser,
            label: 'メンバー一覧',
            path: '/users',
            selectedPath: /^\/users(\/.*)?$/,
          },
        ],
        groupLabel: 'メンバー管理',
      },
    ];

    return menus
      .map((menu) => {
        return {
          ...menu,
          children: menu.children.filter((child) => !child.hidden),
        };
      })
      .filter((menu) => !!menu.children.length);
  }, [
    orderBadge.exhibition.request,
    orderBadge.exhibition.visitor,
    orderBadge.property.request,
    orderBadge.property.visitor,
    canWriteProductExhibition,
    canWriteProductProperty,
    canReadProductExhibition,
    canReadProductProperty,
    canReadOrder,
    canReadUser,
  ]);

  const fetchOrders = useCallback(
    async (orderType: OrderTypeKey) => {
      if (!organizationState) return [];
      const maxPageSize = 50;
      let orders: Order[] = [];
      let nextLink = '';

      try {
        do {
          const { data } = await getOrdersByOrgId(organizationState.id, {
            filter: { orderType, statuses: OrderStatus.PENDING },
            nextLink,
            pageSize: maxPageSize,
          });

          orders = [...orders, ...data.value];
          nextLink = data['@nextLink'] || '';
        } while (nextLink);

        return orders;
      } catch (e) {
        return [];
      }
    },
    [organizationState]
  );

  const fetchOrderStatus = useCallback(async () => {
    if (!canReadOrder) return;

    const requestTypes = [
      OrderType.EXHIBITION_REQUEST_DOC,
      OrderType.EXHIBITION_VISITOR_RESERVE,
      OrderType.PROPERTY_REQUEST_DOC,
      OrderType.PROPERTY_VISITOR_RESERVE,
    ];

    try {
      const [
        exhibitionRequestOrders,
        exhibitionVisitorOrders,
        propertyRequestOrders,
        propertyVisitorOrders,
      ] = await Promise.all(requestTypes.map((type) => fetchOrders(type)));

      const exhibitionRequestCount =
        exhibitionRequestOrders.filter(
          (order) => order.status === OrderStatus.PENDING
        ).length || 0;

      const exhibitionVisitorCount =
        exhibitionVisitorOrders.filter(
          (order) => order.status === OrderStatus.PENDING
        ).length || 0;

      const propertyRequestCount =
        propertyRequestOrders.filter(
          (order) => order.status === OrderStatus.PENDING
        ).length || 0;

      const propertyVisitorCount =
        propertyVisitorOrders.filter(
          (order) => order.status === OrderStatus.PENDING
        ).length || 0;

      setOrderBadge({
        exhibition: {
          request: exhibitionRequestCount,
          visitor: exhibitionVisitorCount,
        },
        property: {
          request: propertyRequestCount,
          visitor: propertyVisitorCount,
        },
      });
    } catch (e) {
      if (isError(e)) {
        setSnackbar(true, e.message);
      }
    }
  }, [fetchOrders, canReadOrder, setSnackbar]);

  useEffect(() => {
    async function execute() {
      await fetchOrderStatus();
    }
    void execute();
    // polling
    const intervalId = setInterval(() => {
      void execute();
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <Global
        styles={css`
          body {
            background-color: ${theme.customPalette.gray3};
          }
        `}
      />
      <Snackbar />
      <Backdrop />
      <Header />
      <Drawer
        variant="permanent"
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: menuWidth,
          },
          flexShrink: 0,
          width: menuWidth,
        }}
      >
        <Toolbar sx={{ height: headerHeight }} />
        <List sx={{ overflow: 'auto' }}>
          {menuItems.map((item, index) => (
            <Box key={index} pt={3}>
              {item.groupLabel && (
                <Typography
                  component="div"
                  variant="body3"
                  sx={{
                    backgroundColor: theme.customPalette.blue2,
                    px: 2,
                    py: 1,
                  }}
                >
                  {item.groupLabel}
                </Typography>
              )}
              {item.children?.map((child, index) => (
                <MuiLink
                  key={index}
                  component={Link}
                  to={child.path}
                  color="inherit"
                  underline="none"
                >
                  <ListItemButton
                    selected={
                      child.selectedPath &&
                      !!location.pathname.match(child.selectedPath)
                    }
                    sx={{ height: 56 }}
                  >
                    {child?.icon && (
                      <ListItemIcon sx={{ color: 'inherit', minWidth: 40 }}>
                        {child.icon}
                      </ListItemIcon>
                    )}
                    {child.badgeCount ? (
                      <Badge
                        badgeContent={child.badgeCount}
                        color="error"
                        max={99}
                        overlap="circular"
                        sx={{
                          '.MuiBadge-badge': {
                            right: 0,
                            transform: 'translate(0)',
                          },
                          width: '100%',
                        }}
                      >
                        <ListItemText primary={child.label} />
                      </Badge>
                    ) : (
                      <ListItemText primary={child.label} />
                    )}
                  </ListItemButton>
                </MuiLink>
              ))}
            </Box>
          ))}
          <Box flexGrow={1} />
          <Box mt={7}>
            <MuiLink component={Link} to="/" color="inherit" underline="none">
              <ListItemButton sx={{ height: 56 }}>
                <ListItemIcon sx={{ color: 'inherit', minWidth: 40 }}>
                  <LiveHelpOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="マニュアル" />
              </ListItemButton>
            </MuiLink>
          </Box>
        </List>
      </Drawer>
      <Box component="main" flexGrow={1}>
        <Toolbar sx={{ height: headerHeight }} />
        <Box p={4}>
          <AppContext.Provider value={{ fetchOrderStatus, orderBadge }}>
            <Outlet />
          </AppContext.Provider>
        </Box>
      </Box>
    </Box>
  );
}
