import { Stack, Typography } from '@mui/material';

export function EmailSent() {
  return (
    <>
      <Stack spacing={3} textAlign="center" my={13} px={4}>
        <Typography variant="h5">
          パスワード再設定手続きのメールをお送りしました
        </Typography>
        <Typography color="secondary">
          ご登録メールアドレス宛にパスワード再発行手続きのメールをお送りしました。
          <br />
          メール本文に記述されているURLをクリックして頂き、パスワード再設定手続きを行ってください。
        </Typography>
      </Stack>
    </>
  );
}
