import { AxiosPromise } from 'axios';
import { format, addDays, endOfMonth, startOfMonth, subDays } from 'date-fns';
import { isEmpty, get } from 'lodash';

import { axiosInstance, CATALOG_SERVICE } from '@app/adapter/axios';
import {
  Attachment,
  AttachmentCreate,
  Attribute,
  Category,
  CategoryStatusKey,
  LocationListResponse,
  LocationTypeKey,
  Product,
  ProductCategory,
  ProductCategoryKey,
  ProductLocation,
  ProductStatus,
  ProductStatusKey,
  ProductUpdate,
  SearchConditionAlert,
  SearchConditionAlertKey,
  ProductCreate,
  ProductPropertyCategoryKey,
  ProductSalesStatus,
  ProductVariant,
  ProductVariantsCreate,
  ProductVariantsDelete,
  ProductVariantsUpdate,
  ProductLocationTypeKey,
} from '@app/types/catalog';
import { GetListDataRequest, Paginated } from '@app/types/common';
import { filterSyntaxGen } from '@app/utils';
import { replaceProductImages } from '@app/utils/catalog';
import { isError } from '@app/utils/error';
import { getObjectId, getUploadedFileUrl } from '@app/utils/fileUpload';
import { convertUtcToJp } from '@app/utils/format';

/**
 * Product
 */
type GetProductsOption = Partial<
  GetListDataRequest & {
    filter: {
      addressLine1?: string;
      addressLine2?: string;
      addressLine3?: string;
      addressLine4?: string;
      alert?: SearchConditionAlertKey;
      category?: ProductCategoryKey;
      id?: string | string[];
      isSalesStatusByStop?: boolean;
      keyword?: string;
      name?: string;
      organizationId?: string | string[];
      postalCode?: string;
      propertyCategories?: ProductPropertyCategoryKey[];
      publicationEnd?: string;
      publicationMonth?: string;
      statuses?: ProductStatusKey[];
      transactionExpirationEnd?: string;
    };
    isAdmin: boolean;
  }
>;

export function getProducts(
  organizationId?: string,
  options?: GetProductsOption
): AxiosPromise<Paginated<Product>> {
  if (options?.nextLink || options?.previousLink) {
    return axiosInstance
      .get(options.nextLink || options.previousLink || '')
      .then((response) => {
        return options?.expand?.includes('images')
          ? {
              ...response,
              data: {
                ...response.data,
                value: response.data.value.map((i: Product) =>
                  replaceProductImages(i)
                ),
              },
            }
          : response;
      })
      .catch((error) => {
        if ('message' in error.response.data) {
          throw new Error(error.response?.data.message);
        } else {
          throw new Error(error.message);
        }
      });
  }

  const pageSize = options?.pageSize || 10;
  const page = options?.page || 0;
  const urlParams = [
    ['$top', pageSize.toString()],
    ['$skip', (page * pageSize).toString()],
  ];

  const filterParam = [];
  if (options?.filter?.id?.length) {
    const ids = Array.isArray(options.filter.id)
      ? options.filter.id
      : [options.filter.id];
    filterParam.push(`id in ${filterSyntaxGen(ids)}`);
  }
  if (options?.filter?.category) {
    filterParam.push(`customFields.category eq '${options.filter.category}'`);
  }
  if (options?.filter?.name) {
    filterParam.push(`name co '${options.filter.name}'`);
  }
  if (options?.filter?.postalCode) {
    filterParam.push(`postalCode eq '${options.filter.postalCode}'`);
  }
  if (options?.filter?.addressLine1) {
    filterParam.push(`addressLine1 eq '${options.filter.addressLine1}'`);
  }
  if (options?.filter?.addressLine2) {
    filterParam.push(`addressLine2 eq '${options.filter.addressLine2}'`);
  }
  if (options?.filter?.addressLine3) {
    filterParam.push(`addressLine3 co '${options.filter.addressLine3}'`);
  }
  if (options?.filter?.addressLine4) {
    filterParam.push(
      `customFields.addressLine4 co '${options.filter.addressLine4}'`
    );
  }
  if (options?.filter?.organizationId?.length) {
    const orgIds = Array.isArray(options.filter.organizationId)
      ? options.filter.organizationId
      : [options.filter.organizationId];
    filterParam.push(`organizationId in ${filterSyntaxGen(orgIds)}`);
  }
  if (options?.filter?.statuses?.length) {
    filterParam.push(
      `publication.status in ${filterSyntaxGen(options.filter.statuses)}`
    );
  }
  // アラート
  if (options?.filter?.alert) {
    const alertFilters = [];
    if (
      options.filter.alert === SearchConditionAlert.ERROR ||
      options.filter.alert === SearchConditionAlert.ERROR_ALERT
    ) {
      filterParam.push(`publication.status eq '${ProductStatus.ACTIVE}'`);
      alertFilters.push(
        `ymdhms(updatedAt) lt '${format(
          subDays(new Date(), 7),
          'yyyy-MM-dd HH:mm:ss'
        )}'`
      );
    }
    if (options.filter.alert === SearchConditionAlert.ERROR_ALERT) {
      alertFilters.push(
        [
          `ymdhms(updatedAt) lt '${format(
            subDays(new Date(), 4),
            'yyyy-MM-dd HH:mm:ss'
          )}'`,
          // 物件のみ取引有効期限も条件に加える
          options?.filter?.category === ProductCategory.PROPERTY
            ? `customFields.transactionExpirationDate lt '${format(
                addDays(new Date(), 7),
                'yyyy-MM-dd HH:mm'
              )}'`
            : '',
        ]
          .filter((i) => !!i)
          .join(' or ')
      );
    }
    if (alertFilters.length) {
      filterParam.push(alertFilters.join(' or '));
    }
  }
  if (options?.filter?.isSalesStatusByStop) {
    filterParam.push(
      `customFields.salesStatus ne '${ProductSalesStatus.SELLING_STOP}'`
    );
    filterParam.push(
      `customFields.salesStatus ne '${ProductSalesStatus.SOLD_OUT}'`
    );
  }
  // 物件種別
  if (options?.filter?.propertyCategories?.length) {
    filterParam.push(
      `customFields.propertyCategory in ${filterSyntaxGen(
        options.filter.propertyCategories
      )}`
    );
  }
  // 掲載月（yyyy/MM）
  if (options?.filter?.publicationMonth) {
    const yearMonth = convertUtcToJp(options.filter.publicationMonth);
    filterParam.push(
      `(${[
        `(${[
          `ymdhms(publication.since) ge '${format(
            startOfMonth(yearMonth),
            'yyyy-MM-dd HH:mm:ss'
          )}'`,
          `ymdhms(publication.since) le '${format(
            endOfMonth(yearMonth),
            'yyyy-MM-dd HH:mm:ss'
          )}'`,
        ].join(' and ')})`,
        `(${[
          `ymdhms(publication.until) ge '${format(
            startOfMonth(yearMonth),
            'yyyy-MM-dd HH:mm:ss'
          )}'`,
          `ymdhms(publication.until) le '${format(
            endOfMonth(yearMonth),
            'yyyy-MM-dd HH:mm:ss'
          )}'`,
        ].join(' and ')})`,
      ].join(' or ')})`
    );
  }
  // 掲載残日数
  if (options?.filter?.publicationEnd) {
    filterParam.push(
      `ymdhms(publication.until) ge '${format(
        new Date(),
        'yyyy-MM-dd HH:mm:ss'
      )}'`
    );
    filterParam.push(
      `ymdhms(publication.until) le '${format(
        addDays(new Date(), Number(options.filter.publicationEnd)),
        'yyyy-MM-dd HH:mm:ss'
      )}'`
    );
  }
  // 取引有効期限残日数
  if (options?.filter?.transactionExpirationEnd) {
    const transactionExpirationEnd = convertUtcToJp();
    filterParam.push(
      `customFields.transactionExpirationDate ge '${format(
        transactionExpirationEnd,
        'yyyy/MM/dd'
      )}'`
    );
    filterParam.push(
      `customFields.transactionExpirationDate le '${format(
        addDays(
          transactionExpirationEnd,
          Number(options.filter.transactionExpirationEnd)
        ),
        'yyyy/MM/dd'
      )}'`
    );
  }
  // キーワード
  if (options?.filter?.keyword) {
    filterParam.push(`name co '${options.filter.keyword}'`);
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', Array.from(new Set(filterParam)).join(' and ')]);
  }

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  urlParams.push(['$orderBy', options?.orderBy || 'createdAt desc']);

  // リクエスト先のパスを設定
  let urlPath = 'products';
  if (options?.isAdmin) {
    urlPath = `admin/${urlPath}`;
  } else if (organizationId) {
    urlPath = `orgs/${organizationId}/${urlPath}`;
  }

  return axiosInstance
    .get(
      `${CATALOG_SERVICE}/${urlPath}?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .then((response) => {
      return options?.expand?.includes('images')
        ? {
            ...response,
            data: {
              ...response.data,
              value: response.data.value.map((i: Product) =>
                replaceProductImages(i)
              ),
            },
          }
        : response;
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getProduct(
  id: string,
  options?: { expand?: string }
): AxiosPromise<Product> {
  const urlParams = [];

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }

  return axiosInstance
    .get(
      `${CATALOG_SERVICE}/products/${id}?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .then((response) => {
      return options?.expand?.includes('images')
        ? {
            ...response,
            data: replaceProductImages(response.data),
          }
        : response;
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function createProduct(
  organizationId: string,
  payload: ProductCreate
): AxiosPromise<Product> {
  return axiosInstance
    .post(`${CATALOG_SERVICE}/orgs/${organizationId}/products`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function createProducts(
  organizationId: string,
  payload: ProductCreate[]
) {
  return axiosInstance
    .post<Product[]>(
      `${CATALOG_SERVICE}/orgs/${organizationId}/products:batchPost`,
      payload
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function updateProduct(
  id: string,
  payload?: ProductUpdate
): AxiosPromise<Product> {
  return axiosInstance
    .patch(`${CATALOG_SERVICE}/products/${id}`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function updateProducts(
  organizationId: string,
  payload: {
    data: ProductUpdate;
    ids: string[];
  }[]
) {
  return axiosInstance
    .patch<Product[]>(
      `${CATALOG_SERVICE}/orgs/${organizationId}/products:batchPatch`,
      payload
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteProduct(id: string) {
  return axiosInstance
    .delete(`${CATALOG_SERVICE}/products/${id}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteProducts(organizationId: string, ids: string[]) {
  return axiosInstance
    .post(`${CATALOG_SERVICE}/orgs/${organizationId}/products:batchDelete`, {
      ids,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function copyProduct(id: string): AxiosPromise<Product> {
  return axiosInstance
    .post(`${CATALOG_SERVICE}/products/${id}:copy`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Product Variant
 */
export function getVariants(
  productId: string,
  options?: {
    expand?: string;
    filter?: {
      title?: string;
    };
    nextLink?: string;
    orderBy?: string;
    page?: number;
    pageSize?: number;
    previousLink?: string;
  }
): AxiosPromise<Paginated<ProductVariant>> {
  const pageSize = options?.pageSize || 100;
  const page = options?.page || 1;
  const urlParams = [
    ['$top', pageSize.toString()],
    ['$skip', ((page - 1) * pageSize).toString()],
  ];

  const filterParam = [];
  if (options?.filter?.title) {
    filterParam.push(`title eq '${options.filter.title}'`);
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  urlParams.push(['$orderBy', options?.orderBy || 'createdAt desc']);

  return axiosInstance
    .get(
      `${CATALOG_SERVICE}/products/${productId}/variants?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function createVariants(
  payload: ProductVariantsCreate[]
): AxiosPromise<unknown> {
  return axiosInstance
    .post(`${CATALOG_SERVICE}/variants:batchPost`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function updateVariants(
  payload: ProductVariantsUpdate[]
): AxiosPromise<unknown> {
  return axiosInstance
    .patch(`${CATALOG_SERVICE}/variants:batchPatch`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteVariants(
  payload: ProductVariantsDelete
): AxiosPromise<unknown> {
  return axiosInstance
    .post(`${CATALOG_SERVICE}/variants:batchDelete`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export async function syncVariantsByProductId(
  productId: string,
  newVariants: ProductVariantsUpdate[]
): Promise<Paginated<ProductVariant>> {
  try {
    const {
      data: { value: currentVariants },
    } = await getVariants(productId);

    const updateData: ProductVariantsUpdate[] = [];
    const createData: ProductVariantsCreate[] = [];
    newVariants.forEach((data) => {
      const variantId = data.ids[0];
      if (currentVariants.some((c) => c.id === variantId)) {
        updateData.push(data);
      } else {
        createData.push({ ...data.data, productId });
      }
    });
    // 一括更新
    if (updateData.length) {
      await updateVariants(updateData);
    }
    // 一括登録
    if (createData.length) {
      await createVariants(createData);
    }

    // 一括削除
    const deleteData: string[] = [];
    currentVariants.forEach((data) => {
      if (newVariants.every((v) => !v.ids.includes(data.id))) {
        deleteData.push(data.id);
      }
    });
    if (deleteData.length) {
      await deleteVariants({ ids: deleteData });
    }

    const { data: latestVariants } = await getVariants(productId);
    return latestVariants;
  } catch (error) {
    if (isError(error)) {
      throw new Error(error.message);
    }
    throw new Error('予期せぬエラーが発生しました');
  }
}

/**
 * Category
 */
export function getCategories(option?: {
  expand?: string;
  filter?: {
    status?: CategoryStatusKey;
  };
  orderBy?: string;
  skip?: number;
  top?: number;
}): AxiosPromise<Paginated<Category>> {
  const urlParams = [['$top', String(option?.top ?? 100)]];

  const filterParams = [];
  if (option?.filter?.status) {
    filterParams.push(`status eq '${option.filter.status}'`);
  }

  if (filterParams.length) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  if (option?.skip) {
    urlParams.push(['$skip', String(option.skip)]);
  }
  if (option?.expand) {
    urlParams.push(['$expand', option.expand]);
  }
  if (option?.orderBy) {
    urlParams.push(['$orderBy', option.orderBy]);
  }
  return axiosInstance
    .get(
      `${CATALOG_SERVICE}/categories?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getCategoryTree(): AxiosPromise<Paginated<Category>> {
  return axiosInstance
    .get(`${CATALOG_SERVICE}/category-tree`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Location
 */
export function getLocation(id: string): AxiosPromise<ProductLocation> {
  return axiosInstance
    .get(`${CATALOG_SERVICE}/locations/${id}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getLocationTree(
  options: GetListDataRequest & {
    '@nextLink'?: string;
    ids?: string[];
    limit?: number;
    type?: ProductLocationTypeKey;
  }
): AxiosPromise<Paginated<ProductLocation>> {
  const expandParams = [];
  const filterParams = [];

  if (!isEmpty(get(options, 'categoryIds', []))) {
    expandParams.push(
      `category($filter=id in ${filterSyntaxGen(
        get(options, 'categoryIds', [])
      )})`
    );
  }
  if (!isEmpty(options.type)) {
    filterParams.push(
      `type in ${filterSyntaxGen([
        get(options, 'type') as ProductLocationTypeKey,
      ])}`
    );
  }
  if (!isEmpty(options.ids)) {
    filterParams.push(`id in ${filterSyntaxGen(get(options, 'ids', []))}`);
  }

  const nextParams = [['$top', String(options?.limit ?? 50)]];

  if (options?.['@nextLink']) {
    nextParams.push(['$nextToken', options?.['@nextLink']]);
  }

  if (filterParams.length > 0) {
    nextParams.push(['$filter', filterParams.join(',')]);
  }
  if (expandParams.length > 0) {
    expandParams.push(['$expand', expandParams.join(',')]);
  }

  return axiosInstance.get(
    `${CATALOG_SERVICE}/location-tree?${new URLSearchParams(
      nextParams
    ).toString()}`
  );
}

export function getLocationList(options?: {
  ids?: string[];
  level?: number;
  nextLink?: string;
  pageSize?: number;
  parentId?: string;
  type?: LocationTypeKey;
}): AxiosPromise<Paginated<ProductLocation>> {
  if (options?.nextLink) {
    return axiosInstance.get(options.nextLink).catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
  }
  const urlParams = [];
  const filterParam = [];

  if (options?.level !== undefined) {
    filterParam.push(`level in [${options.level}]`);
  }
  if (options?.ids?.length) {
    filterParam.push(`parentId in ${filterSyntaxGen(options.ids)}`);
  }
  if (options?.type) {
    filterParam.push(`type eq '${options?.type}'`);
  }
  if (options?.parentId) {
    filterParam.push(`parentId eq '${options?.parentId}'`);
  }

  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }
  urlParams.push([`$top`, (options?.pageSize || 100).toString()]);

  return axiosInstance
    .get(
      `${CATALOG_SERVICE}/locations?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Attribute
 */
export function getAttributes(options?: {
  filter?: {
    categoryIds?: string[];
    delFlg?: number | number[];
    groupName?: string | string[];
    id?: string | string[];
    items?: {
      groupName?: string | string[];
      key?: string | string[];
      value?: string | string[];
    };
    name?: string | string[];
    order?: number | number[];
    type?: Attribute['type'] | Attribute['type'][];
  };
  nextToken?: string;
  order?: 'createdAt' | 'order' | string;
  pageNumber?: number;
  pageSize?: number;
  previousToken?: string;
}): AxiosPromise<Paginated<Attribute>> {
  const urlParams: string[][] = [];
  if (options?.pageSize !== undefined && options?.pageNumber !== undefined) {
    urlParams.push([`$top`, options.pageSize.toString()]);
    urlParams.push([
      `$skip`,
      (options.pageNumber * options.pageSize).toString(),
    ]);
  }
  if (options?.nextToken) {
    urlParams.push(['$nextToken', options.nextToken]);
  }
  if (options?.previousToken) {
    urlParams.push(['$previousToken', options.previousToken]);
  }
  urlParams.push(['$orderBy', options?.order?.toString() ?? 'order']);
  urlParams.push(['$expand', 'category,organization']);

  return axiosInstance
    .get(
      `${CATALOG_SERVICE}/attributes?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getLocationByLevel() {
  const filterParam = `level eq 1`;
  const urlParams = new URLSearchParams();
  urlParams.append('$filter', filterParam);
  urlParams.append('$top', '50');

  return axiosInstance.get<LocationListResponse>(
    `${CATALOG_SERVICE}/locations?${urlParams}`
  );
}

export function getLocationByParentId(parentId: string) {
  const urlParams = new URLSearchParams();
  urlParams.append('$filter', `parentId eq '${parentId}' and level eq 2`);
  urlParams.append('$top', '100');

  return axiosInstance.get<LocationListResponse>(
    `${CATALOG_SERVICE}/locations?${urlParams}`
  );
}

/**
 * Attachment
 */
export function postAttachment(
  orgId: string,
  payload: AttachmentCreate
): AxiosPromise<Attachment> {
  return axiosInstance
    .post(`${CATALOG_SERVICE}/orgs/${orgId}/attachments`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export async function uploadBlob(
  orgId: string,
  file: Blob
): Promise<Attachment> {
  if (!file) {
    throw new Error('blob is not defined');
  }
  const signedUrl = await getUploadSignedUrl(orgId, file);
  const uploadedUrl = await getUploadedFileUrl(file, signedUrl);

  const objectId = getObjectId(uploadedUrl);
  if (!objectId) {
    throw new Error('objectId is not undefined, upload may got error');
  }

  const objectSplits = objectId?.split('.');
  const extension = objectSplits[objectSplits.length - 1];
  const response = await postAttachment(orgId, {
    objectId,
    type: extension,
  });
  return response.data;
}

export function getUploadSignedUrl(orgId: string, blob: Blob): Promise<string> {
  return axiosInstance
    .get(
      `${CATALOG_SERVICE}/orgs/${orgId}/attachment-upload-url?contentLength=${blob.size}&contentType=${blob.type}`
    )
    .then((response) => response.data)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
