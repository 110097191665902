import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  PROPERTY_LEGAL_OTHER_LIMITATION_VALUES,
  PROPERTY_LIMITATION_VALUES,
  PROPERTY_OTHER_LIMITATION_VALUES,
} from '@app/utils/constants';

const LEGAL_OTHER_LIMITATION_TYPE_OPTIONS = Object.entries(
  PROPERTY_LEGAL_OTHER_LIMITATION_VALUES
).map(([value, label]) => {
  return { label, value };
});

export function LimitationFormSection(): ReactElement {
  const limitationValues = [
    Object.keys(PROPERTY_LIMITATION_VALUES).filter(
      (key: string) => key <= '100'
    ),
    Object.keys(PROPERTY_LIMITATION_VALUES).filter(
      (key: string) => key > '100' && key <= '200'
    ),
    Object.keys(PROPERTY_LIMITATION_VALUES).filter(
      (key: string) => key > '200' && key <= '300'
    ),
  ];

  const { control } = useFormContext();
  const formWatch = useWatch({ control });

  return (
    <Stack spacing={3}>
      <Typography variant="h5">制限事項</Typography>
      <Stack>
        <FormLabel focused={false} className="condition-required-label">
          法令上の制限
        </FormLabel>
        <Stack spacing={2} divider={<Divider />}>
          {limitationValues.map((limitations, index) => (
            <FormGroup key={index} row>
              {limitations.map((key: string) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Controller
                      name="legalLimitations"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          value={key}
                          checked={formWatch.legalLimitations.includes(key)}
                          onChange={(e) =>
                            field.onChange(
                              e.target.checked
                                ? [...field.value, key]
                                : [
                                    ...field.value.filter(
                                      (l: string) => l !== key
                                    ),
                                  ]
                            )
                          }
                        />
                      )}
                    />
                  }
                  label={PROPERTY_LIMITATION_VALUES[key]}
                  sx={{ ml: -0.5, mr: 3.5, width: 'fit-content' }}
                />
              ))}
            </FormGroup>
          ))}
        </Stack>
      </Stack>
      <FormControl fullWidth>
        <FormLabel focused={false} className="condition-required-label">
          その他の制限事項
        </FormLabel>
        <FormGroup row>
          {Object.keys(PROPERTY_OTHER_LIMITATION_VALUES).map((key: string) => (
            <FormControlLabel
              key={key}
              control={
                <Controller
                  name="legalOtherLimitations"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      name={key}
                      checked={formWatch.legalOtherLimitations.includes(key)}
                      onChange={(e) =>
                        field.onChange(
                          e.target.checked
                            ? [...field.value, key]
                            : [...field.value.filter((l: string) => l !== key)]
                        )
                      }
                    />
                  )}
                />
              }
              label={PROPERTY_OTHER_LIMITATION_VALUES[key]}
              sx={{ ml: -0.5, mr: 3.5, width: 'fit-content' }}
            />
          ))}
        </FormGroup>
        <Box my={1}>
          <FormHelperText>
            ※崖条例適用につき、建築時・再建築時に制限あれば表記が必要です。
            <br />
            ※自然産ではなく擁壁がある場合も建築制限があれば表記してください。
          </FormHelperText>
          <FormHelperText>
            ※建築基準法42条の通路に接道せず、同43条2項で接道要件を満たす物件は、規定書で掲載可否・表示方法を確認し選択してください。
          </FormHelperText>
        </Box>
        <Controller
          name="legalOtherLimitationType"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box mb={1}>
              <Select
                {...field}
                error={!!error}
                displayEmpty
                renderValue={(value: string) => {
                  if (value)
                    return (
                      LEGAL_OTHER_LIMITATION_TYPE_OPTIONS.find(
                        (o) => o.value === value
                      )?.label || ''
                    );
                  return (
                    <Typography variant="body2" color="secondary">
                      選択してください
                    </Typography>
                  );
                }}
                onChange={(e) => {
                  field.onChange(e);
                }}
              >
                <MenuItem value="">{'　'}</MenuItem>
                {LEGAL_OTHER_LIMITATION_TYPE_OPTIONS.map(
                  ({ value, label }, index) => (
                    <MenuItem key={index} value={value}>
                      {label}
                    </MenuItem>
                  )
                )}
              </Select>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </Box>
          )}
        />
        <Controller
          name="legalOtherLimitationNote"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              placeholder="その他の制限事項がある場合は記入してください。"
            />
          )}
        />
      </FormControl>
    </Stack>
  );
}
