import { LoadingButton } from '@mui/lab';
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { NumericTextField } from '@app/components/Shared/Inputs/NumericTextField';
import { useLocation } from '@app/hooks/useLocation';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { isError } from '@app/utils/error';

export interface LocationsWithPostalCodeProps {
  addressAreaDirection?: 'row' | 'column';
  size?: 'medium' | 'small';
}

export function LocationsWithPostalCode({
  addressAreaDirection = 'column',
  size = 'medium',
}: LocationsWithPostalCodeProps): ReactElement {
  const setSnackbar = useSetSnackbar();
  const { cities, getCities, isCityLoading, prefectures, setPrefectureId } =
    useLocation();
  const [isLoading, setLoading] = useState(false);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const postalCode = useWatch({ control, name: 'postalCode' });
  const prefectureId = useWatch({ control, name: 'prefectureId' });

  const changePrefecture = async (prefecture: string) => {
    const preId = prefectures.find((p) => p.name === prefecture)?.id || '';
    setValue('prefectureId', preId);
    setValue('addressLine2', '');
    setValue('cityId', '');
  };

  const changeCity = (city: string) => {
    const cityId = cities.find((p) => p.name === city)?.id || '';
    setValue('cityId', cityId);
  };

  const handleSearchPostalCode = async () => {
    if (!canSearch) return;
    setLoading(true);
    setValue('addressLine1', '');
    setValue('addressLine2', '');
    setValue('prefectureId', '');
    setValue('cityId', '');

    try {
      const response = await axios.get(
        `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`
      );
      const { address1, address2 } = response.data.results?.[0] || '';
      if (!address1) {
        throw new Error('該当する都道府県が見つかりません');
      }

      // 都道府県情報をセット
      const prefecture = prefectures.find((p) => p.name === address1);
      if (!prefecture) {
        throw new Error('都道府県が一致しませんでした');
      }
      setPrefectureId(prefectureId);
      setValue('prefectureId', prefecture.id);
      setValue('addressLine1', prefecture.name);
      clearErrors('addressLine1');

      // 市区町村情報をセット
      if (!address2) {
        throw new Error('該当する市区町村が見つかりません');
      }
      const cityData = await getCities(prefecture.id);
      const city = cityData.find((p) => p.name === address2);
      if (!city) {
        throw new Error('市区町村が一致しませんでした');
      }
      setValue('cityId', city.id);
      setValue('addressLine2', city.name);
      clearErrors('addressLine2');
    } catch (error) {
      if (isError(error)) {
        setSnackbar(true, error.message, 'error');
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const canSearch = useMemo(() => {
    const code = postalCode.match(/\d+/g)?.join('') || '';
    return code.length === 7;
  }, [postalCode]);

  useEffect(() => {
    setPrefectureId(prefectureId);
    clearErrors('addressLine2');
  }, [prefectureId, setPrefectureId, setValue, clearErrors]);

  return (
    <Stack spacing={1}>
      <Box>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2">〒</Typography>
          <Controller
            name="postalCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumericTextField
                {...field}
                size={size}
                error={!!error}
                placeholder="10000001"
                maxLength={7}
                sx={{ width: 160 }}
              />
            )}
          />
          <LoadingButton
            variant="outlined"
            color="secondary"
            size="small"
            loading={isLoading}
            disabled={!canSearch}
            onClick={() => handleSearchPostalCode()}
            sx={{ height: 32 }}
          >
            <Typography variant="body3">住所を検索</Typography>
          </LoadingButton>
        </Stack>
        {errors.postalCode && (
          <FormHelperText error>
            {errors.postalCode.message as string}
          </FormHelperText>
        )}
      </Box>
      <Stack spacing={1} direction={addressAreaDirection}>
        <Controller
          name="addressLine1"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl>
              <Select
                {...field}
                size={size}
                error={!!error}
                displayEmpty
                renderValue={(value: string) => {
                  if (value) return value;
                  return (
                    <Typography variant="body2" color="secondary">
                      都道府県
                    </Typography>
                  );
                }}
                onChange={(e) => {
                  field.onChange(e);
                  void changePrefecture(e.target.value);
                }}
                sx={{ width: 182 }}
              >
                <MenuItem value="">{'　'}</MenuItem>
                {prefectures.map((prefecture, index) => (
                  <MenuItem key={index} value={prefecture.name}>
                    {prefecture.name}
                  </MenuItem>
                ))}
              </Select>
              {addressAreaDirection === 'column' && errors.addressLine1 && (
                <FormHelperText error>
                  {errors.addressLine1.message as string}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
        <Controller
          name="addressLine2"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl>
              <Select
                {...field}
                value={cities.length ? field.value : ''}
                size={size}
                disabled={!cities.length || isCityLoading}
                error={!!error}
                displayEmpty
                renderValue={(value: string) => {
                  if (value) return value;
                  if (isCityLoading)
                    return (
                      <Box textAlign="center">
                        <CircularProgress size={16} />
                      </Box>
                    );
                  return (
                    <Typography variant="body2" color="secondary">
                      市区町村
                    </Typography>
                  );
                }}
                onChange={(e) => {
                  field.onChange(e);
                  changeCity(e.target.value);
                }}
                sx={{ width: 182 }}
              >
                <MenuItem value="">{'　'}</MenuItem>
                {cities.map((city, index) => (
                  <MenuItem key={index} value={city.name}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
              {addressAreaDirection === 'column' && errors.addressLine2 && (
                <FormHelperText error>
                  {errors.addressLine2.message as string}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Stack>
      {addressAreaDirection === 'row' &&
        Array.from(
          new Set([errors.addressLine1?.message, errors.addressLine2?.message])
        )
          .filter((m) => !!m)
          .map((message, index) => (
            <FormHelperText key={index} error>
              {message as string}
            </FormHelperText>
          ))}
    </Stack>
  );
}
