import { AxiosPromise, GenericAbortSignal } from 'axios';

import { axiosInstance, USER_SERVICE } from '@app/adapter/axios';
import { pageSizeOptions } from '@app/components/Shared/ListTable';
import {
  AdminUserCreateFormData,
  AdminUserEditFormData,
} from '@app/schemas/user';
import { GetListDataRequest, Paginated } from '@app/types/common';
import { UserRoleKey } from '@app/types/organization';
import {
  Attachment,
  TypeIdKey,
  User,
  UserCustomFields,
  UserSubRoleKey,
} from '@app/types/user';
import { filterSyntaxGen } from '@app/utils';
import { getAuthorizationHeader } from '@app/utils/authorization';

/**
 * User
 */
export function getUsers(
  options?: GetListDataRequest & {
    filter?: {
      email?: string;
      id?: string | string[];
      isLocked?: boolean | string;
      keyword?: string;
      orgId?: string | string[];
      typeId?: TypeIdKey;
    };
    isAdmin?: boolean;
    signal?: GenericAbortSignal;
    withOrganization?: boolean;
  }
): AxiosPromise<Paginated<User>> {
  if (options?.nextLink || options?.previousLink) {
    return axiosInstance
      .get(options.nextLink || options.previousLink || '')
      .catch((error) => {
        if ('message' in error.response.data) {
          throw new Error(error.response?.data.message);
        } else {
          throw new Error(error.message);
        }
      });
  }

  const pageSize = options?.pageSize || pageSizeOptions[0];
  const page = options?.page || 0;

  const urlParams = [
    ['$top', pageSize.toString()],
    ['$skip', (page * pageSize).toString()],
  ];

  const filterParam = [];
  if (options?.filter?.id) {
    const ids = Array.isArray(options.filter.id)
      ? options.filter.id
      : [options.filter.id];
    filterParam.push(`id in ${filterSyntaxGen(ids)}`);
  }
  if (options?.filter?.typeId) {
    filterParam.push(`typeId eq '${options.filter.typeId}'`);
  }
  if (options?.filter?.isLocked !== undefined) {
    filterParam.push(`isLocked eq ${options.filter.isLocked}`);
  }
  if (options?.filter?.email) {
    filterParam.push(`email eq '${options.filter.email}'`);
  }
  if (options?.filter?.orgId) {
    const orgIds = Array.isArray(options.filter.orgId)
      ? options.filter.orgId
      : [options.filter.orgId];
    filterParam.push(
      `customFields.organizationIds in ${filterSyntaxGen(orgIds)}`
    );
  }
  if (options?.filter?.keyword) {
    const queryFields = [
      'customFields.familyName',
      'customFields.firstName',
      'email',
    ];
    const query = queryFields
      .map((field) => `${field} co '${options.filter?.keyword}'`)
      .join(' or ');
    filterParam.push(query);
  }

  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  urlParams.push(['$orderBy', options?.orderBy || 'createdAt desc']);

  // リクエスト先のパスを設定
  let urlPath = 'users';
  if (options?.isAdmin) {
    urlPath = `admin/${urlPath}`;
  }
  if (options?.withOrganization) {
    urlPath = 'users-org';
  }

  return axiosInstance
    .get(
      `${USER_SERVICE}/${urlPath}?${new URLSearchParams(urlParams).toString()}`,
      {
        signal: options?.signal,
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getUser(
  id: string,
  options?: {
    expand?: string;
  }
): AxiosPromise<User> {
  const urlParams = [];
  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  return axiosInstance
    .get(
      `${USER_SERVICE}/users/${id}?${new URLSearchParams(urlParams).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function register(
  email: string,
  password: string
): AxiosPromise<{ id: string }> {
  return axiosInstance
    .post(`${USER_SERVICE}/users`, {
      email,
      password,
      typeId: '010',
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function registerAdminUser(
  payload: AdminUserCreateFormData
): AxiosPromise<User> {
  return axiosInstance
    .post(`${USER_SERVICE}/admin/users`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function updateAdminUser(
  userId: string,
  payload: AdminUserEditFormData
): AxiosPromise<User> {
  return axiosInstance
    .patch(`${USER_SERVICE}/users/${userId}`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function updateUser(
  userId: string,
  payload: {
    customFields?: UserCustomFields;
    email?: string;
  }
): AxiosPromise<void> {
  return axiosInstance
    .patch(`${USER_SERVICE}/users/${userId}`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteUser(id: string): AxiosPromise<null> {
  return axiosInstance.delete(`${USER_SERVICE}/users/${id}`).catch((error) => {
    if ('message' in error.response.data) {
      throw new Error(error.response?.data.message);
    } else {
      throw new Error(error.message);
    }
  });
}

export function lockUser(id: string): AxiosPromise<null> {
  return axiosInstance
    .post(`${USER_SERVICE}/users/${id}/lock`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function unLockUser(id: string): AxiosPromise<null> {
  return axiosInstance
    .post(`${USER_SERVICE}/users/${id}/unlock`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function sendVerificationEmail(id: string): AxiosPromise<void> {
  return axiosInstance
    .post(`${USER_SERVICE}/users/${id}:sendVerificationEmail`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function verifyEmail(token: string): AxiosPromise<void> {
  return axiosInstance
    .post(`${USER_SERVICE}/users/verify_email`, undefined, {
      headers: getAuthorizationHeader(token),
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function sendResetPasswordEmail(email: string): AxiosPromise<null> {
  return axiosInstance
    .post(`${USER_SERVICE}/users/send_reset_password_email`, { email })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function resetPassword(
  newPassword: string,
  token: string
): AxiosPromise<null> {
  return axiosInstance
    .post(
      `${USER_SERVICE}/users/reset_password`,
      { password: newPassword },
      {
        headers: getAuthorizationHeader(token),
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function resetPasswordFromSetting(
  userId: string,
  newPassword: string
): AxiosPromise<null> {
  return axiosInstance
    .patch(`${USER_SERVICE}/users/${userId}`, { password: newPassword })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Invitation
 */
export function acceptInvitation(
  invitationId: string,
  opts: { name: string; password: string },
  token: string
) {
  return axiosInstance
    .patch(
      `${USER_SERVICE}/invitations/${invitationId}`,
      {
        ...opts,
        typeId: '001',
      },
      {
        headers: getAuthorizationHeader(token),
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function adminInvite(payload: {
  email: string;
  name: string;
  organizationId: string;
  password: string;
  role: UserRoleKey;
  subRoles?: UserSubRoleKey[];
}): AxiosPromise<{ organizationId: string; userId: string }> {
  return axiosInstance
    .post(`${USER_SERVICE}/users/admin-invite`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Attachment
 */
export function getAttachments(
  id: string,
  options?: { ids?: string[]; limit?: number }
): AxiosPromise<Paginated<Attachment>> {
  const filterParams = [];
  if (options?.ids?.length) {
    filterParams.push(`id in ${filterSyntaxGen(options?.ids)}`);
  }

  const urlParams = [['$top', String(options?.limit ?? 100)]];
  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  return axiosInstance
    .get(
      `${USER_SERVICE}/users/${id}/attachments?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
