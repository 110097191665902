import { Backdrop as MuiBackdrop, CircularProgress } from '@mui/material';
import { ReactElement } from 'react';
import { useRecoilValue } from 'recoil';

import { isLoading } from '@app/domain/app';

export function Backdrop(): ReactElement {
  const isLoadingState = useRecoilValue(isLoading);

  return (
    <MuiBackdrop
      sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 2 }}
      open={isLoadingState}
    >
      <CircularProgress color="inherit" />
    </MuiBackdrop>
  );
}
