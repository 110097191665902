import { CircularProgress, Stack, SxProps, Theme } from '@mui/material';

interface LoadingSpinnerProps {
  sx?: SxProps<Theme>;
}

export function LoadingSpinner({ sx }: LoadingSpinnerProps) {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      zIndex={999}
      sx={{
        backgroundColor: 'inherit',
        ...sx,
      }}
    >
      <CircularProgress />
    </Stack>
  );
}
