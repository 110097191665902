import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef, ForwardedRef, ReactElement } from 'react';

export interface NumericTextFieldProps
  extends Omit<TextFieldProps, 'onChange' | 'value'> {
  max?: number;
  maxLength?: number;
  min?: number;
  minLength?: number;
  onChange?: (value: string) => void;
  value?: string;
}

export const NumericTextField = forwardRef(function (
  {
    max,
    maxLength,
    min,
    minLength,
    value,
    onChange,
    ...textFieldProps
  }: NumericTextFieldProps,
  ref: ForwardedRef<unknown>
): ReactElement {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue: string = event.target.value;
    // 文字数のチェック
    if (maxLength !== undefined && inputValue.length > maxLength) return;
    if (minLength !== undefined && inputValue.length < minLength) return;

    // 値の範囲チェック
    const numericValue =
      textFieldProps.inputMode === 'decimal'
        ? parseFloat(inputValue)
        : parseInt(inputValue, 10);
    if (max !== undefined && numericValue > max) return;
    if (min !== undefined && numericValue < min) return;

    // 半角数字(ドット)のみを許可
    const isResult =
      textFieldProps.inputMode === 'decimal'
        ? /^\d*\.?\d*$/.test(inputValue)
        : /^\d*$/.test(inputValue);
    if (isResult) {
      onChange?.(inputValue);
    }
  };

  return (
    <TextField
      ref={ref as ForwardedRef<HTMLDivElement>}
      value={value}
      onChange={handleChange}
      inputProps={{
        inputMode: 'numeric',
        maxLength,
        minLength,
        pattern: '[0-9]*',
      }}
      {...textFieldProps}
    />
  );
});
