import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { loggedInUserState } from '@app/domain/app';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { ProductCategory, ProductCategoryKey } from '@app/types/catalog';
import { UserRole } from '@app/types/organization';
import { UserSubRole } from '@app/types/user';

export const useRoleChecker = () => {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const loggedInUser = useRecoilValue(loggedInUserState);

  // 権限
  const role = useMemo(() => {
    return loggedInUser?.role || '';
  }, [loggedInUser]);

  // サブ権限
  const subRoles = useMemo(() => {
    return loggedInUser?.customFields?.subRoles || [];
  }, [loggedInUser]);

  // 展示場の閲覧権限チェック
  const canReadProductExhibition = useMemo(() => {
    const allowMemberRoles = [
      UserSubRole.EXHIBITION,
      UserSubRole.PROPERTY,
      UserSubRole.ORDER,
    ];
    return (
      role === UserRole.OWNER ||
      (role === UserRole.MEMBER &&
        subRoles.some((subRole) => allowMemberRoles.includes(subRole)))
    );
  }, [role, subRoles]);

  // 展示場の保存権限チェック
  const canWriteProductExhibition = useMemo(() => {
    const allowMemberRoles: string[] = [UserSubRole.EXHIBITION];
    return (
      role === UserRole.OWNER ||
      (role === UserRole.MEMBER &&
        subRoles.some((subRole) => allowMemberRoles.includes(subRole)))
    );
  }, [role, subRoles]);

  // 物件の閲覧権限チェック
  const canReadProductProperty = useMemo(() => {
    const allowMemberRoles = [
      UserSubRole.EXHIBITION,
      UserSubRole.PROPERTY,
      UserSubRole.ORDER,
    ];
    return (
      role === UserRole.OWNER ||
      (role === UserRole.MEMBER &&
        subRoles.some((subRole) => allowMemberRoles.includes(subRole)))
    );
  }, [role, subRoles]);

  // 物件の保存権限チェック
  const canWriteProductProperty = useMemo(() => {
    const allowMemberRoles: string[] = [UserSubRole.PROPERTY];
    return (
      role === UserRole.OWNER ||
      (role === UserRole.MEMBER &&
        subRoles.some((subRole) => allowMemberRoles.includes(subRole)))
    );
  }, [role, subRoles]);

  // 反響の参照権限チェック
  const canReadOrder = useMemo(() => {
    const allowMemberRoles: string[] = [UserSubRole.ORDER];
    return (
      role === UserRole.OWNER ||
      (role === UserRole.MEMBER &&
        subRoles.some((subRole) => allowMemberRoles.includes(subRole)))
    );
  }, [role, subRoles]);

  // メンバーの参照権限チェック
  const canReadUser = useMemo(() => {
    return role === UserRole.OWNER;
  }, [role]);

  // メンバーの保存権限チェック
  const canWriteUser = useMemo(() => {
    return role === UserRole.OWNER;
  }, [role]);

  /**
   * 権限エラーによる処置
   */
  const accessDenied = () => {
    setSnackbar(true, 'アクセスが拒否されました', 'error');
    navigate('/login');
    return false;
  };

  /**
   * Productの閲覧権限チェック
   */
  const checkReadProduct = (category: ProductCategoryKey) => {
    switch (category) {
      case ProductCategory.EXHIBITION:
        return canReadProductExhibition || accessDenied();
      case ProductCategory.PROPERTY:
        return canReadProductProperty || accessDenied();
      default:
        return false;
    }
  };

  /**
   * Productの書き換え権限チェック
   */
  const checkWriteProduct = (category: ProductCategoryKey) => {
    switch (category) {
      case ProductCategory.EXHIBITION:
        return canWriteProductExhibition || accessDenied();
      case ProductCategory.PROPERTY:
        return canWriteProductProperty || accessDenied();
      default:
        return false;
    }
  };

  /**
   * Orderの閲覧権限チェック
   */
  const checkReadOrder = () => {
    return canReadOrder || accessDenied();
  };

  /**
   * Userの閲覧権限チェック
   */
  const checkReadUser = () => {
    return canReadUser || accessDenied();
  };

  /**
   * Userの書き換え権限チェック
   */
  const checkWriteUser = () => {
    return canWriteUser || accessDenied();
  };

  return {
    canReadOrder,
    canReadProductExhibition,
    canReadProductProperty,
    canReadUser,
    canWriteProductExhibition,
    canWriteProductProperty,
    canWriteUser,
    checkReadOrder,
    checkReadProduct,
    checkReadUser,
    checkWriteProduct,
    checkWriteUser,
    loggedInUser,
    role,
    subRoles,
  };
};
