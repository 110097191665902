import { ProductMonthSeason, ProductRangeType } from '@app/types/catalog';
import { Common } from '@app/types/common';
import { MessageStatuses } from '@app/types/notification';

export const STORAGE_KEYS = {
  /*
    localStorage, sessionStorage を使う場合はここに key を追加
    重複しないように一元管理
  */

  ACCESS_TOKEN: 'accessToken',
  FINGERPRINT: 'fingerprint',
  REFRESH_TOKEN: 'refreshToken',
  USER_ID: 'userId',
};

// 分譲 - 法令上の制限
export const PROPERTY_LIMITATION_VALUES: { [key: string]: string } = {
  '001': '文化財保護法',
  '002': '古都保存法',
  '003': '景観法',
  '004': '密集市街地整備法',
  '005': '航空法',
  '006': '河川法',
  '007': '砂防法',
  '008': '農地法届出要',
  '009': '安全条例',
  '010': '宅地造成工事規制区域',
  '011': '急傾斜地崩壊危険区域',
  '012': '高度地区',
  '101': '高度利用地区',
  '102': '中高層階住居專用地区',
  '103': '高層住居誘導地区',
  '104': '防火地域',
  '105': '準防火地域',
  '106': '風致地区',
  '107': '景観地区',
  '108': '準景観地区',
  '109': '観光団地',
  '110': '歷史風土保存地区',
  '111': '伝統的建造物群保存地区',
  '112': '特定街区',
  '201': '特別用途制限地域',
  '202': '文教地区',
  '203': '都市再生特別地区',
  '204': '特別綠地保全地区',
  '205': '高さ最高限度有',
  '206': '高さ最低限度有',
  '207': '建べい率最低限度有',
  '208': '容積率最低限度有',
  '209': '敷地面積最高限度有',
  '210': '敷地面積最低限度有',
  '211': '建物面積最高限度有',
  '212': '建物面積最低限度有',
};

// 分譲 - その他の制限事項
export const PROPERTY_OTHER_LIMITATION_VALUES: { [key: string]: string } = {
  '001': 'ー部都市計画道路',
  '002': '一部協定通路',
  '003': '日影制限有',
  '004': '隅切り有',
  '005': '接道と段差有',
  '006': '敷地内段差有',
  '007': '壁面後退有',
  '008': '建築協定有',
  '009': '産上につき建築制限有',
  '010': '崖下につき建築制限有',
  '011': '不整形地',
};

// 分譲 - その他の制限事項
export const PROPERTY_LEGAL_OTHER_LIMITATION_VALUES: {
  [key: string]: string;
} = {
  '1': '建築基準法43条2項第1号認定済※建築可能な建物は200平米以内の専用住宅に限られる',
  '2': '建築基準法43条2項第2号許可済',
};

// 分譲 - 建築許可理由
export const PROPERTY_BUILDING_PERMIT_REASON_TYPE_VALUES: {
  [key: string]: string;
} = {
  '1': '開発許可等による分譲地',
  '2': '都市計画法執行令36条1項3号口に該当',
  '3': '調整区域につき建築許可要',
  '4': '調整区域につき建築許可要。建築主の許可要件あり',
};

// 分譲 - イベントアクション
export const PROPERTY_EVENT_ACTION_VALUES: {
  [key: string]: string;
} = {
  '1': '直接現地へご来場ください',
  '2': '事前に必ずお問い合わせください',
  '3': '事前に必ず予約してください',
};

export const ORGANIZATION_STRUCTURE_TYPE = {
  CHILDREN: 'children',
  PARENT: 'parent',
};

export const USER_STATUS_LABELS = {
  LOCKED: '利用停止',
  NORMAL: '利用中',
};

export const MESSAGE_STATUS_MAPPING_LABELS = {
  CLOSE: '掲載終了',
  DRAFT: '下書き',
  PUBLISHED: '掲載中',
  WAITING: '掲載前',
};

export const MESSAGE_TARGET_MAPPING_LABELS = {
  common: '共通 ',
  demand: '会員向け',
  supply: '企業向け',
};
export const SEARCH_STATUS_VALUES = {
  ACTIVE: 'active',
  STOP: 'stop',
};

export const SEARCH_STATUS_OPTIONS = [
  { label: USER_STATUS_LABELS.NORMAL, value: SEARCH_STATUS_VALUES.ACTIVE },
  { label: USER_STATUS_LABELS.LOCKED, value: SEARCH_STATUS_VALUES.STOP },
] as const;

export const NOTIFICATION_SEARCH_STATUS_OPTIONS = [
  {
    label: '公開',
    value: MessageStatuses.PUBLISHED,
  },
  { label: '非公開', value: MessageStatuses.DRAFT },
];

export const SORT_OPTIONS = [
  { label: '新しい順', value: 'createdAt desc' },
  { label: '古い順', value: 'createdAt' },
];

export const RANGE_TYPE_OPTIONS = [
  { label: '〜', value: ProductRangeType.BETWEEN },
  { label: '・', value: ProductRangeType.SEPARATE },
];

export const MONTH_SEASON_OPTIONS = [
  { label: '初旬', value: ProductMonthSeason.BEGIN },
  { label: '上旬', value: ProductMonthSeason.EARLY },
  { label: '中旬', value: ProductMonthSeason.MIDDLE },
  { label: '下旬', value: ProductMonthSeason.LATE },
  { label: '末', value: ProductMonthSeason.END },
  { label: '未選択', value: Common.EMPTY },
];

export const PRODUCT_CONDITIONS_TRANSACTION_OPTIONS = [
  '売主',
  '建物売主',
  '土地売主',
  '土地貸主',
  '土地転貸主',
  '販売提携(代理)',
  '販売提携(媒介)',
  '販売提携(復代理)',
  '仲介(一般媒介)',
  '仲介(専任媒介)',
  '仲介(専属専任)',
  '先物',
  '事業主・売主',
  '事業主・建物売主',
  '事業主・土地売主',
  '事業主・販売提携（代理）',
  '事業主・販売提携（媒介）',
];
