import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { LoadingSpinner } from '@app/components/Shared/LoadingSpinner';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { organizationSelector } from '@app/domain/organization';
import { useRoleChecker } from '@app/hooks/useRoleChecker';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { useUserEditForm } from '@app/hooks/useUserEditForm';
import { isError } from '@app/utils/error';
import { ROLE_OPTIONS } from '@app/utils/user';

export function CreateEdit() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const organizationState = useRecoilValue(organizationSelector);
  const { checkWriteUser } = useRoleChecker();
  const setSnackbar = useSetSnackbar();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { userForm, sendUserUpdate, isLoading } = useUserEditForm(
    userId,
    organizationState?.id
  );
  const {
    control,
    formState: { isValid },
  } = userForm;

  const handleSubmitForm = async () => {
    try {
      setIsUpdating(true);
      await sendUserUpdate();
      setSnackbar(true, '更新しました', 'success');
      navigate(-1);
    } catch (error) {
      setSnackbar(true, '更新に失敗しました', 'error');
      if (isError(error)) {
        console.error(error.message);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  if (!checkWriteUser()) {
    return <></>;
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <PageTitle title="メンバー詳細編集" />
      <Card sx={{ px: 2 }}>
        <Stack direction="row" spacing={1.5} alignItems="center" py={3}>
          <Typography variant="h6" flexGrow={1}>
            メンバー編集
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => navigate(-1)}
            sx={{ width: '100px' }}
          >
            <Typography>キャンセル</Typography>
          </Button>
          <LoadingButton
            variant="contained"
            disabled={!isValid}
            loading={isUpdating}
            onClick={() => handleSubmitForm()}
            sx={{ width: '100px' }}
          >
            <Typography>保存</Typography>
          </LoadingButton>
        </Stack>
        <Stack spacing={5} width={430} py={4}>
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth>
              <FormLabel focused={false} className="required-label">
                姓
              </FormLabel>
              <Controller
                name="familyName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    placeholder="姓を入力"
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel focused={false} className="required-label">
                名
              </FormLabel>
              <Controller
                name="firstName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    placeholder="名を入力"
                  />
                )}
              />
            </FormControl>
          </Stack>
          <FormControl fullWidth>
            <FormLabel focused={false} className="required-label">
              メールアドレス
            </FormLabel>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="メールアドレスを入力"
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel focused={false} className="required-label">
              権限
            </FormLabel>
            <Controller
              name="role"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl sx={{ width: '200px' }}>
                  <Select {...field} error={!!error} displayEmpty>
                    {ROLE_OPTIONS.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </FormControl>
        </Stack>
      </Card>
    </>
  );
}
