import {
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { ReactElement } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { DateAndTime } from '@app/components/Shared/Inputs/DateAndTime';
import { DatePicker } from '@app/components/Shared/Inputs/DatePicker';
import { TextBox } from '@app/components/Shared/Inputs/TextBox';
import { ProductEventTitle, ProductEventDateType } from '@app/types/catalog';
import { Common } from '@app/types/common';
import { PROPERTY_EVENT_ACTION_VALUES } from '@app/utils/constants';

const EVENT_NAME_OPTIONS = [
  { label: '現地見学会', value: ProductEventTitle.LOCAL_TOUR },
  { label: '現地案内会', value: ProductEventTitle.LOCAL_GUIDE },
  { label: '現地販売会', value: ProductEventTitle.LOCAL_SALE },
  { label: 'オープンハウス', value: ProductEventTitle.OPEN_HOUSE },
  { label: 'モデルハウス', value: ProductEventTitle.MODEL_HOUSE },
  { label: 'オープンルーム', value: ProductEventTitle.OPEN_ROOM },
  { label: 'モデルルーム', value: ProductEventTitle.MODEL_ROOM },
  { label: '未選択', value: Common.EMPTY },
];
const EVENT_DATE_TYPE_OPTIONS = [
  { label: '毎週土日祝', value: ProductEventDateType.EVERY_HOLIDAY },
  { label: '毎週土日', value: ProductEventDateType.EVERY_WEEKEND },
  { label: '日時指定', value: ProductEventDateType.FIXED_DATE },
  { label: '期間限定', value: ProductEventDateType.BETWEEN },
  { label: '公開中', value: ProductEventDateType.IN_PROGRESS },
  { label: '未選択', value: Common.EMPTY },
];
const EVENT_ACTION_VALUES_OPTIONS = [
  ...Object.entries(PROPERTY_EVENT_ACTION_VALUES).map(([value, label]) => {
    return { label, value };
  }),
  { label: '未選択', value: Common.EMPTY },
];

export function EventInfoFormSection(): ReactElement {
  const { control, setValue } = useFormContext();
  const eventDateType = useWatch({ control, name: 'eventDateType' });

  return (
    <Stack spacing={3}>
      <Typography variant="h5">イベント情報</Typography>
      <FormControl fullWidth>
        <FormLabel focused={false}>タイトル</FormLabel>
        <Controller
          name="eventName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <RadioGroup {...field} row>
                {EVENT_NAME_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </div>
          )}
        />
      </FormControl>
      <Stack>
        <FormLabel focused={false}>日程</FormLabel>
        <Controller
          name="eventDateType"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <RadioGroup
                {...field}
                row
                onChange={(e) => {
                  field.onChange(e);
                  setValue('eventDateSince', '');
                  setValue('eventDateUntil', '');
                  setValue('eventDateList', [
                    ...Array.from({ length: 10 }, (h) => ''),
                  ]);
                }}
              >
                {EVENT_DATE_TYPE_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </div>
          )}
        />
        <Collapse in={eventDateType === ProductEventDateType.BETWEEN}>
          <Stack
            direction="row"
            spacing={1}
            divider={
              <Typography variant="body3" pt={1.5}>
                〜
              </Typography>
            }
            mt={1}
          >
            <Controller
              name="eventDateSince"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <DatePicker
                    {...field}
                    inputRef={field.ref}
                    error={!!error}
                    actions={['clear']}
                    readOnly
                  />
                  {error && (
                    <FormHelperText error>
                      {error.message as string}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
            <Controller
              name="eventDateUntil"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <DatePicker
                    {...field}
                    inputRef={field.ref}
                    error={!!error}
                    actions={['clear']}
                    readOnly
                  />
                  {error && (
                    <FormHelperText error>
                      {error.message as string}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
          </Stack>
        </Collapse>
        <Collapse in={eventDateType === ProductEventDateType.FIXED_DATE}>
          <Stack direction="row" width={356} mt={1} sx={{ flexWrap: 'wrap' }}>
            {Array.from({ length: 10 }, (h, index) => (
              <Controller
                key={index}
                name={`eventDateList.${index}`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    inputRef={field.ref}
                    error={!!error}
                    actions={['clear']}
                    readOnly
                    sx={{ mb: 1, mr: 2 }}
                  />
                )}
              />
            ))}
          </Stack>
        </Collapse>
      </Stack>
      <Stack>
        <FormLabel focused={false}>イベント開催時間</FormLabel>
        <Stack direction="row" spacing={1} alignItems="center">
          <DateAndTime
            fieldHourName="eventTimeSince.0"
            fieldMinuteName="eventTimeSince.1"
            hideErrorMessage
          />
          <Typography px={2}>〜</Typography>
          <DateAndTime
            fieldHourName="eventTimeUntil.0"
            fieldMinuteName="eventTimeUntil.1"
            hideErrorMessage
          />
          <div>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                setValue('eventTimeSince', ['', '']);
                setValue('eventTimeUntil', ['', '']);
              }}
            >
              <Typography variant="body3">クリア</Typography>
            </Button>
          </div>
        </Stack>
      </Stack>
      <FormControl fullWidth>
        <FormLabel focused={false}>アクション</FormLabel>
        <Controller
          name="eventAction"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <RadioGroup {...field} row>
                {EVENT_ACTION_VALUES_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </div>
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false}>イベント備考</FormLabel>
        <Controller
          name="eventContent"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextBox
              {...field}
              rows={5}
              error={!!error}
              helperText={error?.message}
              placeholder="入力してください"
              fullWidth
              multiline
            />
          )}
        />
      </FormControl>
    </Stack>
  );
}
