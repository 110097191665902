import { PersonOutlineOutlined as PersonOutlineOutlinedIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Link as MuiLink,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Logo } from '@app/components/Shared/Logo';
import {
  loggedInUserState,
  useClearAuthStateAndStorage,
} from '@app/domain/app';

export const headerHeight = 72;

export function Header(): ReactElement {
  const navigate = useNavigate();
  const clearAuthStateAndStorage = useClearAuthStateAndStorage();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleLogout = () => {
    clearAuthStateAndStorage();
    navigate('/login');
  };

  return (
    <>
      <AppBar
        position="fixed"
        className="header-area"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={{ height: headerHeight }}>
          <MuiLink component={Link} to="/" variant="body2">
            <Logo />
          </MuiLink>
          <Box flexGrow={1} />
          {loggedInUser?.id && (
            <>
              <Button
                variant="text"
                color="secondary"
                startIcon={
                  <PersonOutlineOutlinedIcon
                    sx={{ fontSize: '24px !important' }}
                  />
                }
                aria-controls={isOpen ? `headerMenu` : undefined}
                aria-haspopup="true"
                aria-expanded={isOpen ? 'true' : undefined}
                onClick={(event) => {
                  event.stopPropagation();
                  setAnchorEl(event.currentTarget);
                }}
              >
                <Typography>マイアカウント</Typography>
              </Button>
              <Menu
                id="headerMenu"
                anchorEl={anchorEl}
                open={isOpen}
                keepMounted
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    handleLogout();
                  }}
                >
                  ログアウト
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}
