import SearchIcon from '@mui/icons-material/Search';
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  SxProps,
  Theme,
} from '@mui/material';
import React, { useRef, useState } from 'react';

interface SearchTextBoxProps {
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  placeholder?: string;
  sx?: SxProps<Theme>;
  value: string;
  width?: string | number;
}
export function SearchTextBox({
  onChange,
  onSubmit,
  placeholder = 'キーワードで絞り込み',
  sx,
  value,
  width = 240,
}: SearchTextBoxProps) {
  const [keyword, setKeyword] = useState<string>(value);
  const isImeOnRef = useRef<boolean>(false); // 日本語入力中の変換確定Enterを抑制
  const filterKeywordInputRef = useRef<HTMLInputElement>(null);
  const handleKeyDownFilterKeyword = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (
      filterKeywordInputRef.current &&
      !isImeOnRef.current &&
      event.key === 'Enter'
    ) {
      onSubmit && onSubmit(filterKeywordInputRef.current.value);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    onChange && onChange(event.target.value);
  };

  return (
    <div>
      <FormControl variant="outlined" sx={sx}>
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start" sx={{ mr: 0 }}>
              <SearchIcon sx={{ ml: 1.5 }} />
            </InputAdornment>
          }
          value={keyword}
          size="small"
          placeholder={placeholder}
          inputRef={filterKeywordInputRef}
          onCompositionStart={() => (isImeOnRef.current = true)}
          onCompositionEnd={() => (isImeOnRef.current = false)}
          onChange={handleChange}
          onKeyDown={handleKeyDownFilterKeyword}
          sx={{ '.MuiInputBase-input': { py: 1.25 }, pl: 0, width }}
        />
      </FormControl>
    </div>
  );
}
