import { Alert, Snackbar as MuiSnackbar } from '@mui/material';
import { ReactElement, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import {
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
} from '@app/domain/app';

export function Snackbar(): ReactElement {
  const [shouldOpenSnackbar, setShouldSnackbarOpen] =
    useRecoilState(snackbarOpenState);
  const [snackbarText, setSnackbarText] = useRecoilState(snackbarTextState);
  const [snackbarSeverity, setSnackbarSeverity] = useRecoilState(
    snackbarSeverityState
  );
  useEffect(() => {
    if (shouldOpenSnackbar === false) {
      setSnackbarText('');
      setSnackbarSeverity('error');
    }
  }, [setSnackbarSeverity, setSnackbarText, shouldOpenSnackbar]);

  return (
    <MuiSnackbar
      open={shouldOpenSnackbar}
      autoHideDuration={4000}
      onClose={() => {
        setShouldSnackbarOpen(false);
      }}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      sx={{ mt: 8 }}
    >
      <Alert
        severity={snackbarSeverity}
        onClose={() => {
          setShouldSnackbarOpen(false);
        }}
        sx={{ width: '100%' }}
      >
        {snackbarText}
      </Alert>
    </MuiSnackbar>
  );
}
