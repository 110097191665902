import { LoadingButton } from '@mui/lab';
import {
  Alert,
  FormControl,
  FormLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { sendResetPasswordEmail } from '@app/adapter/user-service';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import {
  PasswordResetEmailForm,
  PasswordResetEmailFormData,
} from '@app/schemas/user';
import { isError } from '@app/utils/error';

export function EmailForm(): ReactElement {
  const theme = useTheme();
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [mainErrorMessage, setMainErrorMessage] = useState<string>();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<PasswordResetEmailFormData>(PasswordResetEmailForm);

  const handleSubmitForm = async (formData: PasswordResetEmailFormData) => {
    setIsLoading(true);
    setMainErrorMessage('');
    try {
      await sendResetPasswordEmail(formData.email);
      navigate('/password/reset/email-sent');
    } catch (error) {
      if (isError(error)) {
        if (error.message === 'email not found') {
          setMainErrorMessage(
            '入力されたメールアドレスの会員情報が見つかりません。間違ったメールアドレスを入力されていないかご確認ください。'
          );
          return;
        }
        console.error(error.message);
        setSnackbar(true, '処理できませんでした', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Stack spacing={3} alignItems="center" my={13} px={4}>
        <Typography variant="h5">パスワード再設定手続き</Typography>
        <Typography color="secondary">
          会員登録時にご登録して頂いたメールアドレスを入力してください。パスワード再発行手続きのメールをお送りします。
        </Typography>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Stack spacing={3} alignItems="center" width={480}>
            {mainErrorMessage && (
              <Alert
                severity="error"
                icon={false}
                sx={{ border: `1px solid ${theme.palette.error.main}` }}
              >
                {mainErrorMessage}
              </Alert>
            )}
            <Paper variant="outlined" sx={{ p: 3, width: 1 }}>
              <Stack spacing={4}>
                <FormControl fullWidth>
                  <FormLabel focused={false}>メールアドレス</FormLabel>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message}
                        placeholder="email@sample.com"
                      />
                    )}
                  />
                </FormControl>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isLoading}
                  disabled={!isValid}
                  fullWidth
                >
                  送信
                </LoadingButton>
              </Stack>
            </Paper>
          </Stack>
        </form>
      </Stack>
    </>
  );
}
