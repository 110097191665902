import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { Stack, Typography, Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
interface PageTitleProps {
  buttonHidden?: boolean;
  onClick?: () => void;
  title: string;
}
export function PageTitle({
  buttonHidden = false,
  title,
  onClick,
}: PageTitleProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Stack direction="row" alignItems="center" spacing={1} mb={3}>
      {!buttonHidden && (
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => onClick || navigate(-1)}
          sx={{
            backgroundColor: 'white',
            border: `1px solid ${theme.customPalette.gray5}`,
            height: 40,
            minWidth: 40,
          }}
        >
          <ArrowBackIosNewOutlinedIcon
            sx={{ color: theme.customPalette.gray8 }}
          />
        </Button>
      )}
      <Typography variant="h5">{title}</Typography>
    </Stack>
  );
}
