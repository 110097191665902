import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { LocationsWithPostalCode } from '@app/components/Shared/Inputs/LocationsWithPostalCode';
import { ExhibitionSearchFormDefaultValues as DefaultValues } from '@app/schemas/catalog';
import { theme } from '@app/theme';
import { SearchConditionAlert } from '@app/types/catalog';

const SEARCH_ALERT_OPTIONS = [
  { label: '掲載不可エラーのみ', value: SearchConditionAlert.ERROR },
  {
    label: '掲載不可エラー＆アラート',
    value: SearchConditionAlert.ERROR_ALERT,
  },
  { label: '指定なし', value: SearchConditionAlert.ALL },
];

export interface SearchConditionProps {
  isLoading?: boolean;
  onSubmit?: () => void;
}
export function SearchCondition({
  isLoading,
  onSubmit,
}: SearchConditionProps): ReactElement {
  const [isShowSearchDetail, setIsShowSearchDetail] = useState(false);

  const { control, reset } = useFormContext();

  return (
    <Paper elevation={0}>
      <Box p={2}>
        <Paper variant="outlined">
          <Stack
            direction="row"
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
            sx={{ backgroundColor: theme.customPalette.blue2 }}
          >
            <Typography variant="body2" textAlign="center" minWidth={152}>
              検索条件
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
              flexGrow={1}
              p={1}
              sx={{ backgroundColor: 'white' }}
            >
              <Typography variant="body2">登録物件すべて</Typography>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsShowSearchDetail(!isShowSearchDetail);
                }}
                sx={{ height: 32, width: 115 }}
              >
                検索条件詳細表示
              </Button>
            </Stack>
          </Stack>
        </Paper>
        <Collapse in={isShowSearchDetail}>
          <Paper variant="outlined" sx={{ mt: 2 }}>
            <Stack
              divider={<Divider />}
              sx={{ backgroundColor: theme.customPalette.blue2 }}
            >
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography
                  component="div"
                  variant="body2"
                  textAlign="center"
                  minWidth={152}
                >
                  エラー
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  flexGrow={1}
                  px={1.5}
                  py={0.5}
                  sx={{ backgroundColor: 'white' }}
                >
                  <Controller
                    name="alert"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <RadioGroup {...field} row>
                        {SEARCH_ALERT_OPTIONS.map((option, index) => (
                          <FormControlLabel
                            key={index}
                            value={option.value}
                            control={<Radio />}
                            label={
                              <Typography variant="body2">
                                {option.label}
                              </Typography>
                            }
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography
                  component="div"
                  variant="body2"
                  textAlign="center"
                  minWidth={152}
                >
                  住宅展示場コード
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  flexGrow={1}
                  px={1.5}
                  py={0.5}
                  sx={{ backgroundColor: 'white' }}
                >
                  <Controller
                    name="id"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        error={!!error}
                        placeholder="物件コードを入力"
                        sx={{ flexGrow: 1 }}
                      />
                    )}
                  />
                </Stack>
                <Typography
                  component="div"
                  variant="body2"
                  textAlign="center"
                  minWidth={152}
                >
                  住宅展示場名
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  flexGrow={1}
                  px={1.5}
                  py={0.5}
                  sx={{ backgroundColor: 'white' }}
                >
                  <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        error={!!error}
                        placeholder="物件名を入力"
                        sx={{ flexGrow: 1 }}
                      />
                    )}
                  />
                  <Typography variant="body2">を含む</Typography>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography
                  component="div"
                  variant="body2"
                  textAlign="center"
                  minWidth={152}
                >
                  住所
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="end"
                  flexGrow={1}
                  px={1.5}
                  py={0.5}
                  sx={{ backgroundColor: 'white' }}
                >
                  <LocationsWithPostalCode
                    addressAreaDirection="row"
                    size="small"
                  />
                  <Controller
                    name="addressLine3"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        error={!!error}
                        placeholder="町名・番地"
                        sx={{ flexGrow: 1 }}
                      />
                    )}
                  />
                  <Controller
                    name="addressLine4"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        error={!!error}
                        placeholder="建物名・部屋番号"
                        sx={{ flexGrow: 1 }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        </Collapse>
      </Box>
      <Stack
        spacing={2}
        alignItems="center"
        p={2}
        sx={{ backgroundColor: theme.customPalette.blue2 }}
      >
        <LoadingButton
          color="primary"
          variant="contained"
          loading={isLoading}
          onClick={() => onSubmit?.()}
          sx={{ width: 160 }}
        >
          検索結果を見る
        </LoadingButton>
        <Typography
          variant="body2"
          color={theme.customPalette.black}
          onClick={() => reset(DefaultValues)}
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          すべての条件をクリア
        </Typography>
      </Stack>
    </Paper>
  );
}
