import {
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { DatePicker } from '@app/components/Shared/Inputs/DatePicker';
import { Product, ProductStatus } from '@app/types/catalog';
import { convertUtcToJp, dateConvertToFormat } from '@app/utils/format';

export interface AdditionalInfoFormSectionProps {
  product?: Product;
}
export function AdditionalInfoFormSection({
  product,
}: AdditionalInfoFormSectionProps): ReactElement {
  const { control } = useFormContext();

  return (
    <Stack spacing={3}>
      <Typography variant="h5">情報提供日・更新日</Typography>
      <FormControl fullWidth>
        <FormLabel focused={false}>情報提供日</FormLabel>
        <Typography variant="body2">
          {product?.publication.status === ProductStatus.ACTIVE
            ? dateConvertToFormat(
                convertUtcToJp(product.updatedAt),
                'yyyy/MM/dd HH:mm:ss'
              )
            : '公開時の日時が自動で反映されます。'}
        </Typography>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false}>次回更新日</FormLabel>
        <Typography variant="body2">情報提供より8日以内</Typography>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          取引有効期限
        </FormLabel>
        <Controller
          name="transactionExpirationDate"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <DatePicker
                {...field}
                inputRef={field.ref}
                error={!!error}
                actions={['clear']}
                readOnly
              />
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </div>
          )}
        />
      </FormControl>
    </Stack>
  );
}
