import { Badge, Divider, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';

import { OrderList } from '@app/components/Order/OrderList';
import { useAppContext } from '@app/hooks/appContext';
import { useRoleChecker } from '@app/hooks/useRoleChecker';
import { OrderType } from '@app/types/order';

const TABS = [
  {
    label: '来場予約',
    value: OrderType.PROPERTY_VISITOR_RESERVE,
  },
  {
    label: '資料請求',
    value: OrderType.PROPERTY_REQUEST_DOC,
  },
] as const;

export function PropertyIndex() {
  const { checkReadOrder } = useRoleChecker();
  const [tab, setTab] = useState<string>(TABS[0].value);
  const { orderBadge } = useAppContext();

  if (!checkReadOrder()) {
    return <></>;
  }

  return (
    <>
      <Tabs
        value={tab}
        onChange={(e, value) => setTab(value)}
        sx={{
          '.MuiButtonBase-root': {
            overflow: 'unset !important',
          },
          '.MuiTabs-fixed': {
            overflow: 'unset !important',
          },
          overflow: 'unset !important',
        }}
      >
        {TABS.map(({ label, value }, index) => {
          const badgeContent =
            value === OrderType.PROPERTY_REQUEST_DOC
              ? orderBadge.property.request
              : orderBadge.property.visitor;
          return (
            <Tab
              key={index}
              label={
                badgeContent ? (
                  <Badge
                    badgeContent={badgeContent}
                    color="error"
                    max={99}
                    overlap="circular"
                    sx={{
                      '.MuiBadge-badge': {
                        transform: 'scale(1) translate(120%, -90%)',
                      },
                    }}
                  >
                    <Typography variant="h5">{label}</Typography>
                  </Badge>
                ) : (
                  <Typography variant="h5">{label}</Typography>
                )
              }
              value={value}
            />
          );
        })}
      </Tabs>
      <Divider sx={{ mb: 4.5 }} />
      <OrderList
        title={TABS.find((t) => t.value === tab)?.label}
        orderType={tab}
      />
    </>
  );
}
