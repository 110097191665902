import { User } from '@app/types/user';

export interface Order {
  cancelReason: CancelReasonKey | null;
  canceledAt: string;
  cart?: string;
  checkout?: string;
  closedAt: string;
  createdAt: string;
  currency: string;
  customFields?: OrderCustomFields;
  customer: OrderCustomer;
  gender: string;
  id: string;
  lineItems: OrderLineItem[];
  name: string;
  organization: string;
  status: OrderStatusKey;
  subTotalPrice: number;
  totalPrice: number;
  totalTax: number;
  updatedAt: string;
}

export interface OrderCustomFields {
  addressLine4?: string;
  addressLine5?: string;
  customForms?: OrderCustomForm[];
  desiredReservationDate?: string[];
  orderType?: OrderTypeKey;
  postalCode?: string;
  prefecture?: string;
}

export interface OrderLineItem {
  price: OrderLineItemPrice;
  product: string;
  productName: string;
  quantity: number;
  sku: string;
  variant: string;
  variantTitle: string;
}

export interface OrderLineItemPrice {
  amount: number;
  taxable: boolean;
}

export interface OrderCustomer {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  age: string;
  department: string;
  email: string;
  name: string;
  nameKana: string;
  phone: string;
  prefecture: string;
  preferredContactMethod: string;
  preferredTimeToContact: string;
  rate: string;
  user: User;
}

export interface OrderCustomForm {
  answer: string;
  question: string;
}

export interface OrderUpdate {
  cancelReason?: CancelReasonKey | null;
  canceledAt?: string;
  customFields?: OrderCustomFields;
  status?: OrderStatusKey;
}

export interface OrderStats {
  count: number;
  organization: string;
  productId: string;
}

export interface OrderBadgeState {
  exhibition: {
    request: number;
    visitor: number;
  };
  property: {
    request: number;
    visitor: number;
  };
}

export const OrderStatus = {
  ACCEPTED: 'ACCEPTED',
  CANCELED: 'CANCELED',
  CLOSED: 'CLOSED',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
} as const;
export type OrderStatusKey = (typeof OrderStatus)[keyof typeof OrderStatus];

export const CancelReason = {
  CUSTOMER: 'customer',
  DECLINED: 'declined',
  INVENTORY: 'inventory',
  OTHER: 'other',
} as const;
export type CancelReasonKey = (typeof CancelReason)[keyof typeof CancelReason];

export const OrderType = {
  EXHIBITION_REQUEST_DOC: 'exhibitionRequestDoc', //住宅展示場の資料請求
  EXHIBITION_VISITOR_RESERVE: 'exhibitionVisitorReserve', //住宅展示場の来場予約
  PROPERTY_REQUEST_DOC: 'propertyRequestDoc', //分譲の資料請求
  PROPERTY_VISITOR_RESERVE: 'propertyVisitorReserve', //分譲の資料請求
} as const;
export type OrderTypeKey = (typeof OrderType)[keyof typeof OrderType];
