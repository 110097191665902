import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ReactElement } from 'react';

interface SimpleDialogProps {
  cancelButton?: string;
  content?: string | ReactElement;
  fullWidth?: boolean;
  loading?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onClickCancel?: () => void;
  onClickSubmit?: () => void;
  open: boolean;
  submitButton?: string;
  submitButtonColor?: ButtonProps['color'];
  title?: string | ReactElement;
}

export function SimpleDialog({
  cancelButton,
  content,
  loading,
  onClickCancel,
  onClickSubmit,
  open,
  submitButton,
  submitButtonColor = 'primary',
  title,
  fullWidth = false,
  maxWidth = 'sm',
}: SimpleDialogProps): ReactElement {
  const handleClose = (): void => {
    onClickCancel && onClickCancel();
  };

  const handleSubmit = (): void => {
    onClickSubmit && onClickSubmit();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <Stack spacing={3} p={2}>
        {title && (
          <Stack
            direction="row"
            alignItems="start"
            justifyContent="space-between"
          >
            <Typography fontWeight={700} className="ow-break-word">
              {title}
            </Typography>
            <IconButton
              size="small"
              color="secondary"
              onClick={handleClose}
              sx={{ ml: 1, p: 0 }}
            >
              <HighlightOffOutlinedIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </Stack>
        )}
        {content && <Box className="ow-break-word">{content}</Box>}
        {(cancelButton || submitButton) && (
          <Stack direction="row" spacing={1} justifyContent="end">
            {cancelButton && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={handleClose}
              >
                <Typography variant="body3">{cancelButton}</Typography>
              </Button>
            )}
            {submitButton && (
              <LoadingButton
                variant="contained"
                color={submitButtonColor}
                size="small"
                loading={loading}
                onClick={handleSubmit}
              >
                <Typography variant="body3">{submitButton}</Typography>
              </LoadingButton>
            )}
          </Stack>
        )}
      </Stack>
    </Dialog>
  );
}
