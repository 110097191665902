import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import { formatBearerToken } from '@app/utils/authorization';

export const {
  REACT_APP_AUTH_SERVICE: AUTH_SERVICE,
  REACT_APP_USER_SERVICE: USER_SERVICE,
  REACT_APP_ORGANIZATION_SERVICE: ORGANIZATION_SERVICE,
  REACT_APP_ORDER_SERVICE: ORDER_SERVICE,
  REACT_APP_CATALOG_SERVICE: CATALOG_SERVICE,
  REACT_APP_NOTIFICATION_SERVICE: NOTIFICATION_SERVICE,
  REACT_APP_CHAT_SERVICE: CHAT_SERVICE,
  REACT_APP_REVIEW_SERVICE: REVIEW_SERVICE,
} = process.env;

export const axiosInstance = axios.create({
  responseType: 'json',
});

//
// set headers of accessToken & fingerprint
//
export const setRequestInterceptor: (params: {
  accessToken: string;
  fingerprint: string;
}) => void = ({ accessToken, fingerprint }) => {
  axiosInstance.interceptors.request.clear();
  axiosInstance.interceptors.request.use(
    // onFulfilled
    (config: InternalAxiosRequestConfig) => {
      const { headers } = config;
      headers.set('x-nb-fingerprint', fingerprint);
      headers.set('Authorization', formatBearerToken(accessToken));
      return config;
    },
    // onRejected
    (error) => {
      console.warn('setRequestInterceptor', error);
      throw error;
    }
  );
};

//
// Token有効期限切れなど共通処理
//
export const setResponseInterceptor: (onUnauthorized: () => void) => void = (
  onUnauthorized
) => {
  axiosInstance.interceptors.response.clear();
  axiosInstance.interceptors.response.use(
    // onFulfilled
    (response: AxiosResponse) => response,

    // onRejected
    (error) => {
      if (error instanceof AxiosError) {
        const statusCode = error.response?.status;
        if (statusCode === 401) {
          // TODO refresh token
          onUnauthorized();
        }
      }
      throw error;
    }
  );
};
