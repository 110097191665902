import { Backdrop, CircularProgress } from '@mui/material';
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { DefaultLayout } from '@app/components/Layout/DefaultLayout';
import { SimpleLayout } from '@app/components/Layout/SimpleLayout';
import { VendorLayout } from '@app/components/Layout/VendorLayout';
import { Home } from '@app/views/Home';
import { Login } from '@app/views/Login';
import { NotFound } from '@app/views/NotFound';
import { ExhibitionIndex as OrderExhibitionIndex } from '@app/views/Orders/ExhibitionIndex';
import { PropertyIndex as OrderPropertyIndex } from '@app/views/Orders/PropertyIndex';
import { OrganizationUserRoute } from '@app/views/OrganizationUserRoute';
import { Complete as PasswordResetComplete } from '@app/views/PasswordReset/Complete';
import { EmailForm as PasswordResetEmailForm } from '@app/views/PasswordReset/EmailForm';
import { EmailSent as PasswordResetEmailSent } from '@app/views/PasswordReset/EmailSent';
import { PasswordForm as PasswordResetForm } from '@app/views/PasswordReset/PasswordForm';
import { Preview, PreviewCategory } from '@app/views/Preview';
import { ExhibitionCreateEdit as ProductExhibitionCreateEdit } from '@app/views/Products/ExhibitionCreateEdit';
import { ExhibitionIndex as ProductExhibitionIndex } from '@app/views/Products/ExhibitionIndex';
import { PropertyCreateEdit as ProductPropertyCreateEdit } from '@app/views/Products/PropertyCreateEdit';
import { PropertyIndex as ProductPropertyIndex } from '@app/views/Products/PropertyIndex';
import { CreateEdit as UserCreateEdit } from '@app/views/Users/CreateEdit';
import { Detail as UserDetail } from '@app/views/Users/Detail';
import { Index as Users } from '@app/views/Users/Index';

export function App() {
  return (
    <RecoilRoot>
      <Suspense
        fallback={
          <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      >
        <Router>
          <Routes>
            <Route path="/">
              {/* Anonymous users */}
              <Route element={<SimpleLayout />}>
                <Route path="/login" element={<Login />} />
              </Route>
              <Route element={<DefaultLayout />}>
                <Route path="/password">
                  <Route path="reset">
                    <Route index element={<PasswordResetEmailForm />} />
                    <Route
                      path="email-sent"
                      element={<PasswordResetEmailSent />}
                    />
                    <Route path="verify" element={<PasswordResetForm />} />
                    <Route
                      path="complete"
                      element={<PasswordResetComplete />}
                    />
                  </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
              </Route>
              {/* Confirmed users who already have their organization */}
              <Route
                element={
                  //TODO: REFACTOR SEPARATE LOGIC FROM ROUTING
                  <OrganizationUserRoute>
                    <VendorLayout />
                  </OrganizationUserRoute>
                }
              >
                <Route index element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/exhibitions">
                  <Route path="products">
                    <Route index element={<ProductExhibitionIndex />} />
                    <Route
                      path="register"
                      element={<ProductExhibitionCreateEdit />}
                    />
                    <Route path=":productId">
                      <Route
                        path="edit"
                        element={<ProductExhibitionCreateEdit />}
                      />
                    </Route>
                  </Route>
                  <Route path="orders">
                    <Route index element={<OrderExhibitionIndex />} />
                  </Route>
                </Route>
                <Route path="/properties">
                  <Route path="products">
                    <Route index element={<ProductPropertyIndex />} />
                    <Route
                      path="register"
                      element={<ProductPropertyCreateEdit />}
                    />
                    <Route path=":productId">
                      <Route
                        path="edit"
                        element={<ProductPropertyCreateEdit />}
                      />
                    </Route>
                  </Route>
                  <Route path="orders">
                    <Route index element={<OrderPropertyIndex />} />
                  </Route>
                </Route>
                <Route path="/users">
                  <Route index element={<Users />} />
                  <Route path=":userId">
                    <Route index element={<UserDetail />} />
                    <Route path="edit" element={<UserCreateEdit />} />
                  </Route>
                </Route>
              </Route>
              {/* プレビュー画面 */}
              <Route
                path="/preview"
                element={
                  <OrganizationUserRoute>
                    <SimpleLayout />
                  </OrganizationUserRoute>
                }
              >
                <Route path="properties">
                  <Route path=":id">
                    <Route
                      index
                      element={
                        <Preview category={PreviewCategory.PROPERTIES} />
                      }
                    />
                  </Route>
                </Route>
                <Route path="exhibitions">
                  <Route path=":id">
                    <Route
                      index
                      element={
                        <Preview category={PreviewCategory.EXHIBITIONS} />
                      }
                    />
                  </Route>
                </Route>
                <Route path="organizations">
                  <Route path=":id">
                    <Route
                      index
                      element={
                        <Preview category={PreviewCategory.ORGANIZATION} />
                      }
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </Router>
      </Suspense>
    </RecoilRoot>
  );
}
