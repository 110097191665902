import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { resetPassword } from '@app/adapter/user-service';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { PasswordResetForm, PasswordResetFormData } from '@app/schemas/user';
import { isError } from '@app/utils/error';

export function PasswordForm(): ReactElement {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<PasswordResetFormData>(PasswordResetForm);

  const handleSubmitForm = async (formData: PasswordResetFormData) => {
    setIsLoading(true);
    try {
      const token =
        new URLSearchParams(globalThis.location.search).get('token') || '';
      await resetPassword(formData.password, token);
      navigate('/password/reset/complete');
    } catch (error) {
      if (isError(error)) {
        setSnackbar(true, 'パスワードの登録に失敗しました', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Stack spacing={3} alignItems="center" my={13} px={4}>
        <Typography variant="h5">パスワード再設定</Typography>
        <Typography color="secondary">
          新しいパスワードを入力して下さい
        </Typography>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Paper variant="outlined" sx={{ p: 3, width: 1 }}>
            <Stack spacing={4} width={480}>
              <FormControl fullWidth>
                <FormLabel focused={false}>新しいパスワード</FormLabel>
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="password"
                      error={!!error}
                      helperText={error?.message}
                      placeholder="パスワードを入力"
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <FormLabel focused={false}>新しいパスワード（確認）</FormLabel>
                <Controller
                  name="passwordConfirm"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="password"
                      error={!!error}
                      helperText={error?.message}
                      placeholder="同じパスワードを入力"
                    />
                  )}
                />
              </FormControl>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                loading={isLoading}
                disabled={!isValid}
                fullWidth
              >
                送信
              </LoadingButton>
            </Stack>
          </Paper>
        </form>
      </Stack>
    </>
  );
}
