import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { Backdrop } from '@app/components/Shared/Backdrop';
import { Snackbar } from '@app/components/Shared/Snackbar';

export function SimpleLayout(): ReactElement {
  return (
    <>
      <Snackbar />
      <Backdrop />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
    </>
  );
}
