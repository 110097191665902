import { Box, Toolbar } from '@mui/material';
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { Backdrop } from '@app/components/Shared/Backdrop';
import { Header, headerHeight } from '@app/components/Shared/Header';
import { Snackbar } from '@app/components/Shared/Snackbar';

export function DefaultLayout(): ReactElement {
  return (
    <>
      <Snackbar />
      <Backdrop />
      <Box>
        <Header />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Toolbar sx={{ height: headerHeight }} />
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
