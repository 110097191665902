import { AxiosPromise } from 'axios';

import { axiosInstance, NOTIFICATION_SERVICE } from '@app/adapter/axios';
import { pageSizeOptions } from '@app/components/Shared/ListTable';
import { GetListDataRequest, Paginated } from '@app/types/common';
import {
  Message,
  MessageCreate,
  MessageStatuses,
  MessageUpdate,
  SubscriptionCreate,
  Topic,
  TopicCreate,
  TopicUpdate,
} from '@app/types/notification';

/**
 * Topic
 */
export function getTopics(
  options?: GetListDataRequest & {
    filter?: {
      name?: string;
    };
  }
): AxiosPromise<Paginated<Topic>> {
  if (options?.nextLink || options?.previousLink) {
    return axiosInstance
      .get(options.nextLink || options.previousLink || '')
      .catch((error) => {
        if ('message' in error.response.data) {
          throw new Error(error.response?.data.message);
        } else {
          throw new Error(error.message);
        }
      });
  }

  const pageSize = options?.pageSize || 10;
  const page = options?.page || 1;
  const urlParams = [
    ['$top', pageSize.toString()],
    ['$skip', ((page - 1) * pageSize).toString()],
  ];

  const filterParam = [];
  if (options?.filter?.name) {
    filterParam.push(`name co '${options.filter.name}'`);
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  urlParams.push(['$orderBy', options?.orderBy || 'createdAt desc']);

  return axiosInstance
    .get(
      `${NOTIFICATION_SERVICE}/topics?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function createTopic(data: TopicCreate): AxiosPromise<Topic> {
  return axiosInstance
    .post(`${NOTIFICATION_SERVICE}/topics`, data)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function createSubscription(
  data: SubscriptionCreate
): AxiosPromise<Topic> {
  return axiosInstance
    .post(`${NOTIFICATION_SERVICE}/group-subscriptions `, data)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function updateTopic(
  id: string,
  data: TopicUpdate
): AxiosPromise<Topic> {
  return axiosInstance
    .patch(`${NOTIFICATION_SERVICE}/topics/${id}`, data)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Subscription
 */

/**
 * Message
 */
export function getMessages(
  options?: GetListDataRequest & {
    filter?: {
      searchKeyword?: string;
      status?: string;
    };
  }
): AxiosPromise<Paginated<Message>> {
  if (options?.nextLink || options?.previousLink) {
    return axiosInstance
      .get(options.nextLink || options.previousLink || '')
      .catch((error) => {
        if ('message' in error.response.data) {
          throw new Error(error.response?.data.message);
        } else {
          throw new Error(error.message);
        }
      });
  }

  const pageSize = options?.pageSize || pageSizeOptions[0];
  const page = options?.page as number;
  const urlParams = [
    ['$top', pageSize.toString()],
    ['$skip', (page * pageSize).toString()],
  ];

  const filterParam = [];
  if (options?.filter?.status) {
    filterParam.push(`customFields.status eq '${options.filter.status}'`);
  }
  urlParams.push(['$orderBy', options?.orderBy || 'createdAt desc']);

  if (options?.filter?.searchKeyword) {
    const queryFields = ['id', 'title'];
    const query = queryFields
      .map((field) => `${field} co '${options.filter?.searchKeyword}'`)
      .join(' or ');
    filterParam.push(query);
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  urlParams.push(['$orderBy', options?.orderBy || 'createdAt desc']);

  return axiosInstance
    .get(
      `${NOTIFICATION_SERVICE}/messages?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getMessagesByUserId(
  userId: string,
  options?: GetListDataRequest & {
    filter?: {
      searchKeyword?: string;
      status?: string;
    };
  }
): AxiosPromise<Paginated<Message>> {
  if (options?.nextLink || options?.previousLink) {
    return axiosInstance
      .get(options.nextLink || options.previousLink || '')
      .catch((error) => {
        if ('message' in error.response.data) {
          throw new Error(error.response?.data.message);
        } else {
          throw new Error(error.message);
        }
      });
  }

  const pageSize = options?.pageSize || pageSizeOptions[0];
  const page = options?.page as number;
  const urlParams = [
    ['$top', pageSize.toString()],
    ['$skip', (page * pageSize).toString()],
  ];

  const filterParam = [];
  if (options?.filter?.status) {
    const statusCondition =
      options.filter.status === MessageStatuses.PUBLISHED
        ? `customFields.status eq '${MessageStatuses.PUBLISHED}'`
        : `customFields.status ne '${MessageStatuses.PUBLISHED}'`;
    filterParam.push(statusCondition);
  }
  if (options?.filter?.searchKeyword) {
    const queryFields = ['id', 'title'];
    const query = queryFields
      .map((field) => `${field} co '${options.filter?.searchKeyword}'`)
      .join(' or ');
    filterParam.push(query);
  }

  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  urlParams.push(['$orderBy', options?.orderBy || 'createdAt desc']);

  return axiosInstance
    .get(
      `${NOTIFICATION_SERVICE}/users/${userId}/messages?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getMessage(id: string): AxiosPromise<Message> {
  return axiosInstance
    .get(`${NOTIFICATION_SERVICE}/messages/${id}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function createMessage(data: MessageCreate): AxiosPromise<Message> {
  return axiosInstance
    .post(`${NOTIFICATION_SERVICE}/messages`, data)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function updateMessage(
  id: string,
  data: MessageUpdate
): AxiosPromise<Message> {
  return axiosInstance
    .patch(`${NOTIFICATION_SERVICE}/messages/${id}`, data)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteMessage(id: string): AxiosPromise<void> {
  return axiosInstance
    .delete(`${NOTIFICATION_SERVICE}/messages/${id}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getPublicMessages(
  options?: GetListDataRequest & {
    filter?: {
      name?: string;
    };
  }
): AxiosPromise<Paginated<Message>> {
  if (options?.nextLink || options?.previousLink) {
    return axiosInstance
      .get(options.nextLink || options.previousLink || '')
      .catch((error) => {
        if ('message' in error.response.data) {
          throw new Error(error.response?.data.message);
        } else {
          throw new Error(error.message);
        }
      });
  }

  const pageSize = options?.pageSize || 10;
  const page = options?.page || 1;
  const urlParams = [
    ['$top', pageSize.toString()],
    ['$skip', ((page - 1) * pageSize).toString()],
  ];

  const filterParam = [];
  if (options?.filter?.name) {
    filterParam.push(`name co '${options.filter.name}'`);
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  urlParams.push(['$orderBy', options?.orderBy || 'createdAt desc']);

  return axiosInstance
    .get(
      `${NOTIFICATION_SERVICE}/public/messages?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
