import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { LocationsWithPostalCode } from '@app/components/Shared/Inputs/LocationsWithPostalCode';
import { PropertySearchFormDefaultValues as DefaultValues } from '@app/schemas/catalog';
import { theme } from '@app/theme';
import {
  SearchConditionAlert,
  ProductPropertyCategory,
} from '@app/types/catalog';

const SEARCH_ALERT_OPTIONS = [
  { label: '掲載不可エラーのみ', value: SearchConditionAlert.ERROR },
  {
    label: '掲載不可エラー＆アラート',
    value: SearchConditionAlert.ERROR_ALERT,
  },
  { label: '指定なし', value: SearchConditionAlert.ALL },
];
const SEARCH_PUBLICATION_END_OPTIONS = [
  { label: '当日', value: '0' },
  { label: '残り１日以内', value: '1' },
  { label: '残り２日以内', value: '2' },
  { label: '指定なし', value: '' },
];
const SEARCH_TRANSACTION_EXPIRATION_END_OPTIONS = [
  { label: '当日', value: '0' },
  { label: '残り１日以内', value: '1' },
  { label: '残り２日以内', value: '2' },
  { label: '指定なし', value: '' },
];
const SEARCH_CATEGORY_OPTIONS = [
  { label: '戸建', value: ProductPropertyCategory.DETACHED_HOUSE },
];

export interface SearchConditionProps {
  isLoading?: boolean;
  onSubmit?: () => void;
}
export function SearchCondition({
  isLoading,
  onSubmit,
}: SearchConditionProps): ReactElement {
  const [isShowSearchDetail, setIsShowSearchDetail] = useState(false);

  const { control, getValues, reset, watch } = useFormContext();

  return (
    <Paper elevation={0}>
      <Box p={2}>
        <Paper variant="outlined">
          <Stack
            direction="row"
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
            sx={{ backgroundColor: theme.customPalette.blue2 }}
          >
            <Typography variant="body2" textAlign="center" minWidth={152}>
              検索条件
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              flexGrow={1}
              p={1}
              sx={{ backgroundColor: 'white' }}
            >
              <Typography variant="body2" flexGrow={1}>
                登録物件すべて
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={(e) => setIsShowSearchDetail(!isShowSearchDetail)}
                sx={{ height: 32, width: 115 }}
              >
                検索条件詳細表示
              </Button>
              <Controller
                name="isSalesStatusByStop"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    checked={field.value}
                    label={
                      <Typography variant="body2">
                        売止/成約を表示しない
                      </Typography>
                    }
                    control={<Checkbox size="small" />}
                    sx={{ ml: 0, width: 'fit-content' }}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Paper>
        <Collapse in={isShowSearchDetail}>
          <Paper variant="outlined" sx={{ mt: 2 }}>
            <Stack
              divider={<Divider />}
              sx={{ backgroundColor: theme.customPalette.blue2 }}
            >
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography
                  component="div"
                  variant="body2"
                  textAlign="center"
                  minWidth={152}
                >
                  エラー
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  flexGrow={1}
                  px={1.5}
                  py={0.5}
                  sx={{ backgroundColor: 'white' }}
                >
                  <Controller
                    name="alert"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field} row>
                        {SEARCH_ALERT_OPTIONS.map((option, index) => (
                          <FormControlLabel
                            key={index}
                            value={option.value}
                            control={<Radio />}
                            label={
                              <Typography variant="body2">
                                {option.label}
                              </Typography>
                            }
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography
                  component="div"
                  variant="body2"
                  textAlign="center"
                  minWidth={152}
                >
                  掲載残日数
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  flexGrow={1}
                  px={1.5}
                  py={0.5}
                  sx={{ backgroundColor: 'white' }}
                >
                  <Controller
                    name="publicationEnd"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field} row>
                        {SEARCH_PUBLICATION_END_OPTIONS.map((option, index) => (
                          <FormControlLabel
                            key={index}
                            value={option.value}
                            control={<Radio />}
                            label={
                              <Typography variant="body2">
                                {option.label}
                              </Typography>
                            }
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography
                  component="div"
                  variant="body2"
                  textAlign="center"
                  minWidth={152}
                >
                  取引有効期限残日数
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  flexGrow={1}
                  px={1.5}
                  py={0.5}
                  sx={{ backgroundColor: 'white' }}
                >
                  <Controller
                    name="transactionExpirationEnd"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field} row>
                        {SEARCH_TRANSACTION_EXPIRATION_END_OPTIONS.map(
                          (option, index) => (
                            <FormControlLabel
                              key={index}
                              value={option.value}
                              control={<Radio />}
                              label={
                                <Typography variant="body2">
                                  {option.label}
                                </Typography>
                              }
                            />
                          )
                        )}
                      </RadioGroup>
                    )}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography
                  component="div"
                  variant="body2"
                  textAlign="center"
                  minWidth={152}
                >
                  物件種別
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  flexGrow={1}
                  px={0.5}
                  py={0.5}
                  sx={{ backgroundColor: 'white' }}
                >
                  {SEARCH_CATEGORY_OPTIONS.map((option, index) => (
                    <Controller
                      key={index}
                      name="categories"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          checked={watch('categories')?.includes(option.value)}
                          label={
                            <Typography variant="body2">
                              {option.label}
                            </Typography>
                          }
                          control={<Checkbox size="small" />}
                          onChange={() => {
                            const value = getValues('categories');
                            field.onChange(
                              value.includes(option.value)
                                ? value.filter(
                                    (i: string) => i !== option.value
                                  )
                                : [...value, option.value]
                            );
                          }}
                          sx={{ ml: 0, width: 'fit-content' }}
                        />
                      )}
                    />
                  ))}
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography
                  component="div"
                  variant="body2"
                  textAlign="center"
                  minWidth={152}
                >
                  物件名
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  flexGrow={1}
                  px={1.5}
                  py={0.5}
                  sx={{ backgroundColor: 'white' }}
                >
                  <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        error={!!error}
                        placeholder="物件名を入力"
                        sx={{ flexGrow: 1 }}
                      />
                    )}
                  />
                  <Typography variant="body2">を含む</Typography>
                </Stack>
                <Typography
                  component="div"
                  variant="body2"
                  textAlign="center"
                  minWidth={152}
                >
                  物件コード
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  flexGrow={1}
                  px={1.5}
                  py={0.5}
                  sx={{ backgroundColor: 'white' }}
                >
                  <Controller
                    name="id"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        error={!!error}
                        placeholder="物件コードを入力"
                        sx={{ flexGrow: 1 }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography
                  component="div"
                  variant="body2"
                  textAlign="center"
                  minWidth={152}
                >
                  住所
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="end"
                  flexGrow={1}
                  px={1.5}
                  py={0.5}
                  sx={{ backgroundColor: 'white' }}
                >
                  <LocationsWithPostalCode
                    addressAreaDirection="row"
                    size="small"
                  />
                  <Controller
                    name="addressLine3"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        error={!!error}
                        placeholder="町名・番地"
                        sx={{ flexGrow: 1 }}
                      />
                    )}
                  />
                  <Controller
                    name="addressLine4"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        error={!!error}
                        placeholder="建物名・部屋番号"
                        sx={{ flexGrow: 1 }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        </Collapse>
      </Box>
      <Stack
        spacing={2}
        alignItems="center"
        p={2}
        sx={{ backgroundColor: theme.customPalette.blue2 }}
      >
        <LoadingButton
          color="primary"
          variant="contained"
          loading={isLoading}
          onClick={() => onSubmit?.()}
          sx={{ width: 160 }}
        >
          検索結果を見る
        </LoadingButton>
        <Typography
          variant="body2"
          color={theme.customPalette.black}
          onClick={() => reset(DefaultValues)}
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          すべての条件をクリア
        </Typography>
      </Stack>
    </Paper>
  );
}
