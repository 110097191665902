import {
  FormHelperText,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { forwardRef, ForwardedRef, ReactElement, useMemo } from 'react';

import { theme } from '@app/theme';

export interface TextBoxProps extends Omit<TextFieldProps, 'value'> {
  inputLength?: {
    max?: number;
  };
  value?: string;
}

export const TextBox = forwardRef(function (
  { inputLength, value, error, helperText, ...props }: TextBoxProps,
  ref: ForwardedRef<unknown>
): ReactElement {
  // 入力文字数
  const valueLength = useMemo(() => value?.length || 0, [value]);
  // 文字数カウント値のフォントカラー
  const countColor = useMemo(() => {
    return valueLength <= Number(inputLength?.max)
      ? 'inherit'
      : theme.palette.error.main;
  }, [valueLength, inputLength]);

  return (
    <div>
      <TextField
        ref={ref as ForwardedRef<HTMLDivElement>}
        value={value}
        error={error}
        {...props}
      />
      <Stack direction="row">
        {!!helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
        {!!inputLength && (
          <>
            <div style={{ flexGrow: 1 }} />
            <FormHelperText>
              <span style={{ color: countColor }}>{valueLength}</span>/
              {inputLength.max}文字
            </FormHelperText>
          </>
        )}
      </Stack>
    </div>
  );
});
