import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { ReactElement, useState } from 'react';

import { sendResetPasswordEmail } from '@app/adapter/user-service';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { User } from '@app/types/user';
import { isError } from '@app/utils/error';

export interface SendResetPasswordEmailButtonProps {
  user?: User;
}

export function SendResetPasswordEmailButton({
  user,
}: SendResetPasswordEmailButtonProps): ReactElement {
  const [isSendingResetPassword, setIsSendingResetPassword] =
    useState<boolean>(false);
  const setSnackbar = useSetSnackbar();
  const sendResetEmail = async () => {
    if (!user?.email) return;
    try {
      setIsSendingResetPassword(true);
      await sendResetPasswordEmail(user.email);
      setSnackbar(
        true,
        `ご登録メールアドレス宛にパスワード設定手続きのメールをお送りしました。`,
        'success'
      );
    } catch (error) {
      if (isError(error)) {
        setSnackbar(
          true,
          `パスワード設定手続きのメール送信に失敗しました。
          お手数ですが、もう一度お試しください。またはサポートまでご連絡ください。`,
          'error'
        );
      }
    } finally {
      setIsSendingResetPassword(false);
    }
  };
  return (
    <LoadingButton
      variant="outlined"
      color="secondary"
      size="small"
      onClick={sendResetEmail}
      sx={{ minWidth: '130px' }}
      loading={isSendingResetPassword}
    >
      <Typography>パスワード設定</Typography>
    </LoadingButton>
  );
}
