export interface Pagination<T> {
  '@nextLink': string;
  count: number;
  items: T;
  total: number;
}
export interface Paginated<T> {
  '@nextLink'?: string;
  '@previousLink'?: string;
  count: number;
  total: number;
  value: T[];
}

export interface GetListDataRequest {
  expand?: string;
  nextLink?: string;
  orderBy?: string;
  page?: number;
  pageSize?: number;
  previousLink?: string;
}

export const Common = {
  EMPTY: '',
} as const;
export type EmptyKey = typeof Common.EMPTY;

export const DateType = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
} as const;
export type DateTypeKey = (typeof DateType)[keyof typeof DateType];
