import { PersonOutline as PersonOutlineIcon } from '@mui/icons-material';
import { Avatar, Stack, SxProps, Theme, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { ReactElement } from 'react';

import { User } from '@app/types/user';
import { getUserFullName } from '@app/utils/user';

export interface UserNameWithIconProps {
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps<Theme>;
  user?: User;
}
export function UserNameWithIcon({
  user,
  size = 'medium',
  sx,
}: UserNameWithIconProps): ReactElement {
  let fontVariant: Variant = 'body2';
  let iconSize = 32;
  if (size === 'large') {
    fontVariant = 'h6';
    iconSize = 48;
  }
  return (
    <Stack direction="row" spacing={2.5} alignItems="center" sx={{ ...sx }}>
      <Avatar sx={{ height: iconSize, width: iconSize }}>
        <PersonOutlineIcon
          fontSize={size}
          sx={size === 'large' ? { fontSize: '2rem' } : {}}
        />
      </Avatar>
      <Typography variant={fontVariant}>{getUserFullName(user)}</Typography>
    </Stack>
  );
}
