import { Organization, UserRoleKey } from '@app/types/organization';
import { ReviewStats } from '@app/types/review';

/**
 * User
 */
export interface User {
  accessToken: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  avatar?: string;
  createdAt: string;
  customFields: UserCustomFields;
  email: string;
  emailVerified: boolean;
  id: string;
  isLocked: boolean;
  name: string;
  organizations?: Organization[];
  phoneNumber?: string;
  provider?: string;
  providerId?: string;
  reviewStats?: ReviewStats;
  role?: UserRoleKey;
  typeId: string;
  updatedAt: string;
}

export interface UserCustomFields {
  familyName?: string;
  firstName?: string;
  subRole?: UserSubRoleKey[]; //TODO:削除予定
  subRoles?: UserSubRoleKey[];
}

export type UserUpdate = Partial<User>;

export const UserTypeId = {
  ADMIN: '100',
  DEMAND: '000',
  SUPPLY: '010',
  SUPPLY_SUB: '001',
};
export type TypeIdKey = (typeof UserTypeId)[keyof typeof UserTypeId];

export const UserSubRole = {
  EXHIBITION: 'exhibition',
  ORDER: 'order',
  PROPERTY: 'property',
} as const;
export type UserSubRoleKey = (typeof UserSubRole)[keyof typeof UserSubRole];

/**
 * Attachment
 */
export interface Attachment {
  id?: string;
  ownerId?: string;
  ownerType?: string;
  type?: string;
  url?: string;
}
