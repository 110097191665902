import { AxiosPromise, AxiosRequestConfig, CanceledError } from 'axios';

import { axiosInstance, ORGANIZATION_SERVICE } from '@app/adapter/axios';
import { GetListDataRequest, Paginated } from '@app/types/common';
import {
  Attachment,
  AttachmentCreate,
  Organization,
  OrganizationCreate,
  OrganizationUpdate,
  OrganizationUser,
  UserRoleKey,
} from '@app/types/organization';
import { User } from '@app/types/user';
import { filterSyntaxGen } from '@app/utils';
import { getObjectId, getUploadedFileUrl } from '@app/utils/fileUpload';

/**
 * Organization
 */
export function getOrganizations(
  options?: GetListDataRequest & {
    filter?: {
      depth?: number;
      id?: string | string[];
      isGetAll?: boolean;
      keyword?: string;
      parentId?: string;
      status?: string;
      structureType?: string;
    };
  }
): AxiosPromise<Paginated<Organization>> {
  if (options?.nextLink || options?.previousLink) {
    return axiosInstance
      .get(options.nextLink || options.previousLink || '')
      .catch((error) => {
        if ('message' in error.response.data) {
          throw new Error(error.response?.data.message);
        } else {
          throw new Error(error.message);
        }
      });
  }

  const pageSize = options?.pageSize || 10;
  const page = options?.page || 0;
  const urlParams = [
    ['$top', pageSize.toString()],
    ['$skip', (page * pageSize).toString()],
  ];

  const filterParam = [];
  if (options?.filter?.id?.length) {
    const ids = Array.isArray(options.filter.id)
      ? options.filter.id
      : [options.filter.id];
    filterParam.push(`id in ${filterSyntaxGen(ids)}`);
  }
  if (options?.filter?.keyword) {
    filterParam.push(
      [
        'name',
        'addressLine3',
        'customFields.addressLine4',
        'customFields.prefectureCity',
        'customFields.address',
      ]
        .map((field) => `${field} co '${options.filter?.keyword}'`)
        .join(' or ')
    );
  }
  if (options?.filter?.parentId) {
    filterParam.push(`parentId eq '${options.filter.parentId}'`);
  }
  if (options?.filter?.status) {
    filterParam.push(`status eq '${options.filter.status}'`);
  }
  if (options?.filter?.structureType) {
    filterParam.push(
      `customFields.structureType eq '${options.filter.structureType}'`
    );
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  urlParams.push(['$orderBy', options?.orderBy || 'createdAt desc']);

  return axiosInstance
    .get(
      `${ORGANIZATION_SERVICE}/organizations?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getOrganization(
  id: string,
  options?: {
    expand?: string;
  }
): AxiosPromise<Organization> {
  const urlParams = [];
  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  return axiosInstance
    .get(
      `${ORGANIZATION_SERVICE}/organizations/${id}?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function createOrganization(
  organization: OrganizationCreate
): AxiosPromise<Organization> {
  return axiosInstance
    .post(`${ORGANIZATION_SERVICE}/organizations`, organization)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function updateOrganization(
  id: string,
  payload: OrganizationUpdate
): AxiosPromise<Organization> {
  // 画像情報は参照用のためを除去する
  delete payload.customFields?.mainImages;
  payload.customFields?.strengths?.map((s) => {
    delete s.image;
    return s;
  });

  return axiosInstance
    .patch(`${ORGANIZATION_SERVICE}/organizations/${id}`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteOrganization(id: string) {
  return axiosInstance
    .delete(`${ORGANIZATION_SERVICE}/organizations/${id}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getOrganizationDetails(
  userId: string,
  config?: AxiosRequestConfig
): AxiosPromise<Paginated<Organization>> {
  return axiosInstance
    .get(`${ORGANIZATION_SERVICE}/users/${userId}/organizations`, config)
    .catch((error) => {
      if (error instanceof CanceledError) {
        throw error;
      } else if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * 指定の組織に所属するユーザーリストを取得する
 */
export function getUsersByOrgId(
  orgId: string,
  options?: GetListDataRequest & {
    filter?: {
      isDeleted?: boolean;
      keyword?: string;
      status?: string;
    };
  }
): AxiosPromise<Paginated<User>> {
  if (options?.nextLink || options?.previousLink) {
    return axiosInstance
      .get(options.nextLink || options.previousLink || '')
      .catch((error) => {
        if ('message' in error.response.data) {
          throw new Error(error.response?.data.message);
        } else {
          throw new Error(error.message);
        }
      });
  }

  const pageSize = options?.pageSize || 10;
  const page = options?.page || 1;
  const urlParams = [
    ['$top', pageSize.toString()],
    ['$skip', ((page - 1) * pageSize).toString()],
  ];

  const filterParams = [];
  if (options?.filter?.isDeleted !== undefined) {
    filterParams.push(`delFlg eq ${Number(options.filter.isDeleted)}`);
  }
  if (options?.filter?.status === 'active') {
    filterParams.push(`isLocked ne true`);
  } else if (options?.filter?.status === 'stop') {
    filterParams.push(`isLocked eq true`);
  }
  if (options?.filter?.keyword) {
    const keywordFilters = [
      `customFields.familyName co '${options.filter.keyword}'`,
      `customFields.firstName co '${options.filter.keyword}'`,
    ];
    filterParams.push(`(${keywordFilters.join(' or ')})`);
  }
  if (filterParams.length) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  if (options?.orderBy) {
    urlParams.push(['$orderBy', options.orderBy]);
  }
  return axiosInstance
    .get(
      `${ORGANIZATION_SERVICE}/organizations/${orgId}/users?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if (error.response && 'message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getOrganizationUploadSignedUrl(
  orgId: string,
  type: string,
  size: number
): Promise<string> {
  return axiosInstance
    .get(
      `${ORGANIZATION_SERVICE}/organizations/${orgId}/upload_url?contentLength=${size}&contentType=${type}`
    )
    .then((response) => response.data);
}

export function postOrganizationAttachment(
  orgId: string,
  attachment: { objectId: string; type: string }
): AxiosPromise<{ id: string; url: string }> {
  return axiosInstance.post(
    `${ORGANIZATION_SERVICE}/organizations/${orgId}/attachments`,
    attachment
  );
}

export function getOrganizationRoleByUserId(
  orgId: string,
  userId: string
): AxiosPromise<OrganizationUser> {
  return axiosInstance
    .get(`${ORGANIZATION_SERVICE}/organizations/${orgId}/users/${userId}/role`)
    .catch((error) => {
      if (error instanceof CanceledError) {
        throw error;
      } else if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function updateOrganizationRoles(
  orgId: string,
  payload: [{ id: string; role: UserRoleKey }]
): AxiosPromise<OrganizationUser> {
  return axiosInstance
    .patch(`${ORGANIZATION_SERVICE}/organizations/${orgId}/users`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteUserRoleOnOrganization(
  orgId: string,
  userId: string
): AxiosPromise<OrganizationUser> {
  return axiosInstance
    .delete(`${ORGANIZATION_SERVICE}/organizations/${orgId}/users/${userId}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Attachment
 */
export function postAttachment(
  orgId: string,
  payload: AttachmentCreate
): AxiosPromise<Attachment> {
  return axiosInstance
    .post(`${ORGANIZATION_SERVICE}/organizations/${orgId}/attachments`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export async function uploadBlob(
  orgId: string,
  file: Blob
): Promise<Attachment> {
  if (!file) {
    throw new Error('blob is not defined');
  }
  const signedUrl = await getUploadSignedUrl(orgId, file);
  const uploadedUrl = await getUploadedFileUrl(file, signedUrl);

  const objectId = getObjectId(uploadedUrl);
  if (!objectId) {
    throw new Error('objectId is not undefined, upload may got error');
  }

  const objectSplits = objectId?.split('.');
  const extension = objectSplits[objectSplits.length - 1];
  const response = await postAttachment(orgId, {
    objectId,
    type: extension,
  });
  return response.data;
}

export function getUploadSignedUrl(orgId: string, blob: Blob): Promise<string> {
  return axiosInstance
    .get(
      `${ORGANIZATION_SERVICE}/organizations/${orgId}/upload_url?contentLength=${blob.size}&contentType=${blob.type}`
    )
    .then((response) => response.data)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
