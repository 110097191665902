import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { adminInvite, sendResetPasswordEmail } from '@app/adapter/user-service';
import { organizationSelector } from '@app/domain/organization';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { UserInviteForm, UserInviteFormData } from '@app/schemas/user';
import { UserRoleKey } from '@app/types/organization';
import { UserSubRoleKey } from '@app/types/user';
import { generateRandomPassword } from '@app/utils';
import { isError } from '@app/utils/error';
import { ROLE_OPTIONS } from '@app/utils/user';

const TABS = [{ label: 'メールアドレスで招待', value: 'emailInvite' }] as const;

interface MemberInviteDialogProps {
  onClickCancel?: () => void;
  onClickSubmit?: () => void;
  open: boolean;
}

export function MemberInviteDialog({
  onClickCancel,
  onClickSubmit,
  open,
}: MemberInviteDialogProps): ReactElement {
  const theme = useTheme();
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbar = useSetSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [tabSelected, setTabSelected] = useState<string>(TABS[0].value);

  const {
    control,
    formState: { errors, isValid },
    getValues,
    reset,
    watch,
  } = useForm<UserInviteFormData>(UserInviteForm);

  const { fields } = useFieldArray({
    control,
    name: 'users',
  });

  const handleClose = (): void => {
    onClickCancel && onClickCancel();
    reset();
  };

  const handleSubmitForm = async () => {
    if (!organizationState) return;
    setIsLoading(true);
    try {
      const value = getValues();
      const defaultPassword = generateRandomPassword();
      await Promise.all(
        value.users
          .filter((u) => u.email !== '')
          .map(async (user) => {
            const roleSub = user.role.split('-')[1];
            await adminInvite({
              email: user.email,
              name: user.email,
              organizationId: organizationState?.id,
              password: defaultPassword,
              role: user.role.split('-')[0] as UserRoleKey,
              subRoles: roleSub ? [roleSub as UserSubRoleKey] : [],
            });
            return sendResetPasswordEmail(user.email);
          })
      );
      onClickSubmit?.();
      setSnackbar(
        true,
        `ご登録メールアドレス宛にパスワード再発行手続きのメールをお送りしました。`,
        'success'
      );
    } catch (error) {
      if (isError(error)) {
        setSnackbar(true, 'メールアドレス招待に失敗しました', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <Stack
        direction="row"
        alignItems="start"
        justifyContent="space-between"
        p={2}
      >
        <Typography fontWeight={700} className="ow-break-word">
          メンバー追加
        </Typography>
        <IconButton
          size="small"
          color="secondary"
          onClick={handleClose}
          sx={{ ml: 1, p: 0 }}
        >
          <HighlightOffOutlinedIcon sx={{ fontSize: '1.5rem' }} />
        </IconButton>
      </Stack>
      <Stack divider={<Divider />}>
        <Tabs value={tabSelected} sx={{ px: 2 }}>
          {TABS.map(({ label, value }, index) => (
            <Tab
              key={index}
              label={label}
              value={value}
              onClick={() => setTabSelected(value)}
            />
          ))}
        </Tabs>
        <Stack spacing={2} p={3}>
          {fields.map((field, index) => (
            <Stack direction="row" spacing={1} key={field.id}>
              <Controller
                name={`users.${index}.email`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!errors.users?.[index]?.email}
                    helperText={errors.users?.[index]?.email?.message}
                    placeholder="メールアドレスを入力"
                  />
                )}
              />
              <Controller
                name={`users.${index}.role`}
                control={control}
                render={({ field }) => (
                  <FormControl sx={{ minWidth: 170 }}>
                    <Select
                      {...field}
                      displayEmpty
                      fullWidth
                      error={
                        watch(`users.${index}.email`) !== '' &&
                        !errors.users?.[index]?.email &&
                        !field.value
                      }
                      sx={{ backgroundColor: theme.customPalette.gray3 }}
                    >
                      <MenuItem value="">権限を選択する</MenuItem>
                      {ROLE_OPTIONS.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
          ))}
        </Stack>
        <Stack direction="row" justifyContent="end" py={2} px={3}>
          <LoadingButton
            variant="contained"
            size="small"
            disabled={!isValid}
            loading={isLoading}
            onClick={handleSubmitForm}
          >
            <Typography variant="body3">追加する</Typography>
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  );
}
