import {
  Button,
  Card,
  Divider,
  Fade,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { MainVisualFormSection } from '@app/components/Catalog/MainVisualFormSection';
import { AdditionalInfoFormSection } from '@app/components/Catalog/Property/AdditionalInfoFormSection';
import { AroundInfoFormSection } from '@app/components/Catalog/Property/AroundInfoFormSection';
import { BasicInfoFormSection } from '@app/components/Catalog/Property/BasicInfoFormSection';
import { BuildingInfoFormSection } from '@app/components/Catalog/Property/BuildingInfoFormSection';
import { CompanyInfoFormSection } from '@app/components/Catalog/Property/CompanyInfoFormSection';
import { EquipmentFormSection } from '@app/components/Catalog/Property/EquipmentFormSection';
import { EventInfoFormSection } from '@app/components/Catalog/Property/EventInfoFormSection';
import { ExteriorFormSection } from '@app/components/Catalog/Property/ExteriorFormSection';
import { FloorPlanFormSection } from '@app/components/Catalog/Property/FloorPlanFormSection';
import { InteriorFormSection } from '@app/components/Catalog/Property/InteriorFormSection';
import { LandInfoFormSection } from '@app/components/Catalog/Property/LandInfoFormSection';
import { LimitationFormSection } from '@app/components/Catalog/Property/LimitationFormSection';
import { OverviewFormSection } from '@app/components/Catalog/Property/OverviewFormSection';
import { PlotMapFormSection } from '@app/components/Catalog/Property/PlotMapFormSection';
import { PriceFormSection } from '@app/components/Catalog/Property/PriceFormSection';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { isLoading } from '@app/domain/app';
import { organizationSelector } from '@app/domain/organization';
import { useProduct } from '@app/hooks/useProduct';
import { useProductPropertyForm } from '@app/hooks/useProductPropertyForm';
import { useRoleChecker } from '@app/hooks/useRoleChecker';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { ProductCategory, ProductStatus } from '@app/types/catalog';
import { isError } from '@app/utils/error';

const TABS = [
  { label: '基本情報', value: 'basic' },
  { label: '間取り', value: 'floorPlan' },
  { label: '内外観', value: 'interiorAndExterior' },
  { label: '設備', value: 'equipment' },
  { label: '周辺環境', value: 'around' },
] as const;

export function PropertyCreateEdit() {
  const organizationState = useRecoilValue(organizationSelector);
  const { checkWriteProduct } = useRoleChecker();
  if (!organizationState?.id) {
    throw new Error('所属情報が見つかりません');
  }

  const navigate = useNavigate();
  const { productId } = useParams();
  const setSnackbar = useSetSnackbar();
  const setLoadingState = useSetRecoilState(isLoading);
  const [tab, setTab] = useState<string>(TABS[0].value);
  const [isDraftValid, setDraftValid] = useState(false);
  const { fetchProduct, product } = useProduct(productId);
  const {
    setFormValues,
    setOrganizationId,
    sendFormValues,
    triggerFormAll,
    overviewForm,
    basicInfoForm,
    companyInfoForm,
    eventInfoForm,
    landInfoForm,
    limitationForm,
    buildingInfoForm,
    priceForm,
    mainVisualForm,
    aroundForm,
    equipmentForm,
    exteriorForm,
    floorPlanForm,
    interiorForm,
    plotMapForm,
    isValid,
  } = useProductPropertyForm();

  useEffect(() => {
    const execute = async () => {
      if (!productId) return;

      setLoadingState(true);
      const data = await fetchProduct(productId);
      if (data) {
        setDraftValid(!!data.name);
        setFormValues(data);
        void triggerFormAll();
      }
      setLoadingState(false);
    };
    setOrganizationId(organizationState.id);
    void execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const handlePreview = async () => {
    const latest = await handleDraftSubmit();
    if (latest?.id) {
      const url = `/preview/properties/${latest.id}`;
      window.open(url, '_blank', 'noopener noreferrer');
    }
  };

  const handleDraftSubmit = async () => {
    try {
      setLoadingState(true);
      basicInfoForm.setValue('publication.status', ProductStatus.DRAFT);
      const latest = await sendFormValues(product?.id);
      setSnackbar(true, '下書き保存しました', 'success');
      void triggerFormAll();
      return latest ? await fetchProduct(latest.id) : undefined;
    } catch (error) {
      setSnackbar(
        true,
        '下書き保存に失敗しました。再度保存をお試しください',
        'error'
      );
      if (isError(error)) {
        console.error(error.message);
      }
    } finally {
      setLoadingState(false);
    }
  };

  const handleSubmitForm = async () => {
    try {
      setLoadingState(true);
      basicInfoForm.setValue('publication.status', ProductStatus.ACTIVE);
      await sendFormValues(product?.id);
      setSnackbar(true, '保存しました', 'success');
      navigate('/properties/products');
    } catch (error) {
      setSnackbar(
        true,
        '保存に失敗しました。再度保存をお試しください',
        'error'
      );
      if (isError(error)) {
        console.error(error.message);
      }
    } finally {
      setLoadingState(false);
    }
  };

  const overviewFormSection = useMemo(() => {
    return (
      <FormProvider {...overviewForm}>
        <OverviewFormSection
          onChangeName={(value) => setDraftValid(value !== '')}
          onChangeSalesStatus={(value) => {
            basicInfoForm.setValue('salesStatus', value);
            priceForm.setValue('salesStatus', value);
          }}
        />
      </FormProvider>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewForm, overviewForm.formState]);
  const mainVisualFormSection = useMemo(() => {
    return (
      <FormProvider {...mainVisualForm}>
        <MainVisualFormSection max={12} />
      </FormProvider>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainVisualForm, mainVisualForm.formState]);
  const basicInfoFormSection = useMemo(() => {
    return (
      <FormProvider {...basicInfoForm}>
        <BasicInfoFormSection />
      </FormProvider>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicInfoForm, basicInfoForm.formState]);
  const priceFormSection = useMemo(() => {
    return (
      <FormProvider {...priceForm}>
        <PriceFormSection />
      </FormProvider>
    );
  }, [priceForm]);
  const landInfoFormSection = useMemo(() => {
    return (
      <FormProvider {...landInfoForm}>
        <LandInfoFormSection />
      </FormProvider>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landInfoForm, landInfoForm.formState]);
  const buildingInfoFormSection = useMemo(() => {
    return (
      <FormProvider {...buildingInfoForm}>
        <BuildingInfoFormSection
          onChangeSalesHouseCount={(value) => {
            basicInfoForm.setValue('salesHouseCount', value);
            companyInfoForm.setValue('salesHouseCount', value);
            priceForm.setValue('salesHouseCount', value);
          }}
        />
      </FormProvider>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingInfoForm, buildingInfoForm.formState]);
  const companyInfoFormSection = useMemo(() => {
    return (
      <FormProvider {...companyInfoForm}>
        <CompanyInfoFormSection organizationId={organizationState.id} />
      </FormProvider>
    );
  }, [companyInfoForm, organizationState]);
  const limitationFormSection = useMemo(() => {
    return (
      <FormProvider {...limitationForm}>
        <LimitationFormSection />
      </FormProvider>
    );
  }, [limitationForm]);
  const eventInfoFormSection = useMemo(() => {
    return (
      <FormProvider {...eventInfoForm}>
        <EventInfoFormSection />
      </FormProvider>
    );
  }, [eventInfoForm]);
  const additionalInfoFormSection = useMemo(() => {
    return (
      <FormProvider {...buildingInfoForm}>
        <AdditionalInfoFormSection product={product} />
      </FormProvider>
    );
  }, [buildingInfoForm, product]);

  if (!checkWriteProduct(ProductCategory.PROPERTY)) {
    return <></>;
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <PageTitle title={`物件${productId ? '編集' : '登録'}`} buttonHidden />
        <div>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            disabled={!isDraftValid}
            onClick={handlePreview}
          >
            <Typography>下書き保存してプレビュー</Typography>
          </Button>
        </div>
      </Stack>
      <Tabs value={tab} onChange={(e, value) => setTab(value)}>
        {TABS.map(({ label, value }, index) => (
          <Tab
            key={index}
            label={label}
            value={value}
            sx={{ fontSize: '1rem' }}
          />
        ))}
      </Tabs>
      <Divider sx={{ mb: 2.5 }} />
      <div>
        <form>
          <Fade in={tab === 'basic'}>
            <Card sx={{ p: 4 }} hidden={tab !== 'basic'}>
              {productId && (
                <Typography color="secondary" sx={{ mb: 2 }}>
                  ID:{productId}
                </Typography>
              )}
              <Stack spacing={4} divider={<Divider />}>
                {overviewFormSection}
                {mainVisualFormSection}
                {basicInfoFormSection}
                {priceFormSection}
                {landInfoFormSection}
                {buildingInfoFormSection}
                {companyInfoFormSection}
                {limitationFormSection}
                {eventInfoFormSection}
                {additionalInfoFormSection}
              </Stack>
            </Card>
          </Fade>
          <Fade in={tab === 'floorPlan'}>
            <Card sx={{ p: 4 }} hidden={tab !== 'floorPlan'}>
              <Stack spacing={4} divider={<Divider />}>
                <FormProvider {...plotMapForm}>
                  <PlotMapFormSection />
                </FormProvider>
                <FormProvider {...floorPlanForm}>
                  <FloorPlanFormSection />
                </FormProvider>
              </Stack>
            </Card>
          </Fade>
          <Fade in={tab === 'interiorAndExterior'}>
            <Card sx={{ p: 4 }} hidden={tab !== 'interiorAndExterior'}>
              <Stack spacing={4} divider={<Divider />}>
                <FormProvider {...exteriorForm}>
                  <ExteriorFormSection />
                </FormProvider>
                <FormProvider {...interiorForm}>
                  <InteriorFormSection />
                </FormProvider>
              </Stack>
            </Card>
          </Fade>
          <Fade in={tab === 'equipment'}>
            <Card sx={{ p: 4 }} hidden={tab !== 'equipment'}>
              <FormProvider {...equipmentForm}>
                <EquipmentFormSection />
              </FormProvider>
            </Card>
          </Fade>
          <Fade in={tab === 'around'}>
            <Card sx={{ p: 4 }} hidden={tab !== 'around'}>
              <FormProvider {...aroundForm}>
                <AroundInfoFormSection />
              </FormProvider>
            </Card>
          </Fade>
        </form>
      </div>
      <BottomMenu
        content={
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            width={1}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(-1)}
              sx={{ width: 106 }}
            >
              キャンセル
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              disabled={!isDraftValid}
              onClick={handleDraftSubmit}
              sx={{ width: 75 }}
            >
              下書き
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={!isValid}
              onClick={handleSubmitForm}
              sx={{ width: 140 }}
            >
              保存する(公開)
            </Button>
          </Stack>
        }
      ></BottomMenu>
    </>
  );
}
