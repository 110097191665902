import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { getLocationList } from '@app/adapter/catalog-service';
import { locationsByPrefectureSelector } from '@app/domain/catalog';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { LocationType, ProductLocation } from '@app/types/catalog';
import { isError } from '@app/utils/error';

export const useLocation = () => {
  const setSnackbar = useSetSnackbar();
  const prefectures = useRecoilValue(locationsByPrefectureSelector);
  const [cities, setCities] = useState<ProductLocation[]>([]);
  const [prefectureId, setPrefectureId] = useState<string>();
  const [isCityLoading, setCityLoading] = useState(false);

  const getCities = useCallback(
    async (prefectureId: string): Promise<ProductLocation[]> => {
      if (!prefectureId) return [];

      try {
        const { data } = await getLocationList({
          parentId: prefectureId,
          type: LocationType.CITY,
        });
        if (data['@nextLink']) {
          const { data: addData } = await getLocationList({
            nextLink: data['@nextLink'],
          });
          const values = [...data.value, ...addData.value];
          return values;
        }
        return data.value;
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
        throw error;
      }
    },
    []
  );

  const fetchLocationCities = useCallback(
    async (prefecture: string) => {
      setCities([]);
      const prefectureId =
        prefectures.find((p) => p.name === prefecture)?.id || '';
      if (!prefectureId) return;

      setCityLoading(true);
      try {
        const data = await getCities(prefectureId);
        setCities(data);
      } catch (error) {
        setSnackbar(true, '市区町村の取得に失敗しました', 'error');
        if (isError(error)) {
          console.error(error.message);
        }
        return [];
      } finally {
        setCityLoading(false);
      }
    },
    [prefectures, setSnackbar, getCities]
  );

  useEffect(() => {
    const prefecture =
      prefectures.find((p) => p.id === prefectureId)?.name || '';
    void fetchLocationCities(prefecture);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefectureId]);

  return {
    cities,
    fetchLocationCities,
    getCities,
    isCityLoading,
    prefectureId,
    prefectures,
    setPrefectureId,
  };
};
