import axios from 'axios';

export async function getUploadedFileUrl(
  blob: Blob,
  signedUrl: string
): Promise<string> {
  // Google Store にPUTして、ファイルをアップロードする
  const uploadResponse = await axios.put(signedUrl, blob, {
    headers: {
      'Content-Type': blob.type,
    },
  });
  if (!uploadResponse.config.url) {
    throw new Error("Can't get url from response");
  }
  return uploadResponse.config.url;
}

export function getObjectId(url: string): string {
  return new URL(url).pathname.split('?')[0].replace(/^\/[^/]+\//, '');
}

export function getResizeImageURL(
  file: Blob | string,
  maxWidth?: number,
  maxHeight?: number,
  trimmingRatio?: number
): Promise<string> {
  const promise = new Promise<string>((resolve, reject) => {
    if (!file) resolve('');
    try {
      const processBlob = (blob: Blob) => {
        const reader = new FileReader();
        reader.onload = async () => {
          const imgData = reader.result;
          const orgImage = new Image();
          orgImage.onload = function () {
            const orgWidth = orgImage.naturalWidth;
            const orgHeight = orgImage.naturalHeight;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const image = new Image();

            // オリジナルが最大サイズより大きければ最大サイズを設定
            const maxWidth2 = maxWidth ?? orgWidth;
            const maxHeight2 = maxHeight ?? orgHeight;
            const dwBuf = orgWidth <= maxWidth2 ? orgWidth : maxWidth2;
            const dhBuf = orgHeight <= maxHeight2 ? orgHeight : maxHeight2;
            const dw =
              !trimmingRatio || dwBuf <= dhBuf * trimmingRatio
                ? dwBuf
                : dhBuf * trimmingRatio;
            const dh =
              !trimmingRatio || dhBuf <= dwBuf / trimmingRatio
                ? dhBuf
                : dwBuf / trimmingRatio;

            // オリジナルが最大サイズより大きければ縦または横をトリミング
            const sw =
              orgWidth < (orgHeight / dh) * dw
                ? orgWidth
                : (orgHeight / dh) * dw;
            const sh =
              orgHeight < (orgWidth / dw) * dh
                ? orgHeight
                : (orgWidth / dw) * dh;

            // 中央が残るようにトリミング
            const sx = (orgWidth - sw) / 2;
            const sy = (orgHeight - sh) / 2;
            canvas.width = dw;
            canvas.height = dh;
            image.onload = () => {
              ctx?.drawImage(image, sx, sy, sw, sh, 0, 0, dw, dh);
              resolve(canvas.toDataURL(blob.type || 'image/png', 0.7));
            };
            image.src = imgData as string;
          };
          orgImage.src = imgData as string;
        };
        reader.onerror = () => reject(new Error('resizeImageURL:error'));
        reader.onabort = () => reject(new Error('resizeImageURL:abort'));
        reader.readAsDataURL(blob);
      };
      if (typeof file === 'string') {
        void fetch(file)
          .then((response) => response.blob())
          .then((blob) => {
            processBlob(blob);
          })
          .catch((error) => reject(error));
      } else {
        processBlob(file);
      }
    } catch (error) {
      reject(error);
    }
  });
  return promise;
}
