import logo from '@app/assets/logo.png';
import logoWide from '@app/assets/logo_wide.png';

export const ImageTypes = {
  NORMAL: 'normal',
  WIDE: 'wide',
} as const;
export type ImageTypesKey = (typeof ImageTypes)[keyof typeof ImageTypes];

interface LogoProps {
  height?: string;
  imageType?: ImageTypesKey;
  width?: string;
}

export function Logo({
  height = '60',
  imageType = ImageTypes.NORMAL,
  width = '145',
}: LogoProps) {
  const src =
    imageType === ImageTypes.NORMAL
      ? logo
      : imageType === ImageTypes.WIDE
      ? logoWide
      : '';
  return <img src={src} alt="Logo" width={width} height={height} />;
}
