import { useCallback, useMemo, useState } from 'react';

export function useOnScreen<Element extends HTMLElement>(): [
  boolean,
  React.RefCallback<Element>
] {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    [setIsIntersecting]
  );

  const currentElement = useCallback(
    (ele: Element | null) => {
      if (ele) {
        observer.observe(ele);
      } else {
        observer.disconnect();
        setIsIntersecting(false);
      }
    },
    [observer, setIsIntersecting]
  );

  return [isIntersecting, currentElement];
}
