import { Cancel as CancelIcon } from '@mui/icons-material';
import {
  FormHelperText,
  FormLabel,
  IconButton,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
} from '@mui/material';
import { sortBy } from 'lodash';
import { ReactElement, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { ImageUpload } from '@app/components/Shared/Inputs/ImageUpload';
import { getResizeImageURL } from '@app/utils/fileUpload';

/**
 * メインビジュアルを選択するフォーム
 * ※Product, Organization の登録フォームで共用使用しています
 */
export interface MainVisualFormSectionProps {
  max?: number;
}
export function MainVisualFormSection({
  max = 10,
}: MainVisualFormSectionProps): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    clearErrors,
    control,
    setError,
    formState: { errors },
    watch,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'mainVisuals',
  });

  const handleFileUpload = async (urls: string[]) => {
    setIsLoading(true);
    const images = await Promise.all(
      Array.from(urls).map(async (url, index) => {
        // 件数チェック
        if (fields.length + index + 1 > max) {
          return;
        }
        return {
          key: index,
          url: await getResizeImageURL(url, undefined, undefined, 3 / 2),
        };
      })
    );
    sortBy(
      images.filter((i) => !!i),
      'key'
    ).forEach((image) => append({ url: image?.url }));
    clearErrors('mainVisuals');
    setIsLoading(false);
  };

  return (
    <Stack spacing={3}>
      <Typography variant="h5">メインビジュアル</Typography>
      <FormLabel focused={false} className="required-label">
        <Typography variant="body3">3枚必須・最大{max}枚</Typography>
      </FormLabel>
      {!!fields.length && (
        <ImageList cols={3} gap={24} sx={{ overflowY: 'inherit' }}>
          {fields.map((field, index) => (
            <ImageListItem key={index}>
              <img src={watch(`mainVisuals.${index}.url`)} alt="sample" />
              <IconButton
                size="small"
                className="image-remove"
                onClick={() => {
                  remove(index);
                  clearErrors('mainVisuals');
                }}
              >
                <CancelIcon sx={{ fontSize: '1.25rem' }} />
              </IconButton>
            </ImageListItem>
          ))}
        </ImageList>
      )}
      <ImageUpload
        loading={isLoading}
        disabled={fields.length >= max}
        multipleMax={max}
        multiple
        onFileUpload={handleFileUpload}
        onError={(e) => setError('mainVisuals', { message: e.message })}
        sx={{ flex: 1 }}
      />
      {errors.mainVisuals && (
        <FormHelperText error>
          {errors.mainVisuals.message as string}
        </FormHelperText>
      )}
    </Stack>
  );
}
